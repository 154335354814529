import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { OptinInternalCreator } from '@components/OptinCreator';
import { Restrict } from '@core/Restrict';
import { LoggedInLayout } from '@core/LoggedInLayout';

export const OptInInternalPage: FC = () => (
  <Restrict isRedirect>
    <LoggedInLayout>
      <Box p={3}>
        <OptinInternalCreator />
      </Box>
    </LoggedInLayout>
  </Restrict>
);
