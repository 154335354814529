import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyAuthConfig, amplifyApiConfig } from '@config/system';
import App from './App';
import reportWebVitals from './reportWebVitals';

Amplify.configure({
  Auth: amplifyAuthConfig,
  API: {
    ...amplifyApiConfig,
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
