import { FC, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { SingleCodeInputProps } from '../types';

const allowedKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Tab',
  'v',
  'V',
];

export const SingleCodeInput: FC<SingleCodeInputProps> = ({
  onFocus,
  onChange,
  onPaste,
  moveCaretNext,
  moveCaretPrev,
  value,
  index,
  focusedIndex,
  error,
}) => {
  const {
    palette: {
      grey,
      error: { main: errorColor },
    },
  } = useTheme();
  const [ref, setRef] = useState<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref && index === focusedIndex) {
      ref.focus();
      ref.select();
    }
  }, [index, focusedIndex, ref]);

  return (
    <input
      data-testid={`email-code-input-${index}`}
      style={{
        textAlign: 'center',
        boxSizing: 'border-box',
        height: 64,
        width: 50,
        border: `1px solid ${error ? errorColor : grey[300]}`,
        borderRadius: 8,
        padding: 14,
        fontSize: 32,
      }}
      ref={setRef}
      type="text"
      value={value}
      onFocus={onFocus}
      onPaste={(e) => {
        e.preventDefault();
        const text = (e.clipboardData.getData('Text') || '')
          .split('')
          .filter((char) => /[0-9]/.test(char))
          .join('');

        onPaste(text, index);
      }}
      onKeyDown={(e) => {
        if (!allowedKeys.includes(e.key)) {
          e.preventDefault();
        }
        if (e.key === 'Backspace') {
          moveCaretPrev();
          onChange('');
        }
      }}
      onChange={({ target: { value: val } }) => {
        if (!['v', 'V'].includes(val[0])) {
          onChange(val[0]);
          moveCaretNext();
        }
      }}
    />
  );
};
