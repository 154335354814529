import { FC } from 'react';
import { ConfirmSignUpForm } from '@components/ConfirmSignUpForm';
import { Navigate, useParams } from 'react-router-dom';
import { DefaultLayout } from '@core/DefaultLayout';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';

export const ConfirmSignUpPage: FC = () => {
  const { email } = useParams();
  const password = useSelector((store: Store) => store.login.tempPassword);

  if (email) {
    return (
      <DefaultLayout>
        <ConfirmSignUpForm email={email} password={password} />
      </DefaultLayout>
    );
  }

  return <Navigate to="/sign-in" />;
};
