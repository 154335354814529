import { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitialDataAction } from '@redux/login/login.asyncActions';
import { Store } from '@typings/Store';
import { Loader } from '@core/Loader';

export const Initializer: FC = ({ children }) => {
  const isLoading = useSelector((store: Store) => store.login.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInitialDataAction());
  }, [dispatch]);

  return isLoading ? <Loader open /> : (children as ReactElement);
};
