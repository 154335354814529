import { FC, useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Props } from './types';

export const RetoolContainmentTest: FC<Props> = ({
  onDetected,
  label,
  text,
  width,
  height,
  lineHeight,
  fontSize,
  fontWeight,
}) => {
  const [error, setError] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (ref) {
      setTimeout(() => {
        const result = ref.scrollHeight !== ref.clientHeight;
        setError(result);
        onDetected(!result);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return (
    <Grid container spacing={2} direction="row" color={error ? 'red' : 'black'}>
      {label && <Grid item>{label}</Grid>}
      <Grid item>
        <Box
          ref={setRef}
          fontFamily="Inter"
          boxSizing="content-box"
          overflow="auto"
          width={`${width}px`}
          height={`${height + 10}px`}
          lineHeight={
            typeof lineHeight === 'number' ? `${lineHeight}px` : lineHeight
          }
          fontSize={`${fontSize}px`}
          fontWeight={fontWeight}
          border={`2px solid ${error ? 'red' : 'black'}`}
        >
          {text}
        </Box>
      </Grid>
    </Grid>
  );
};
