/* eslint max-len: 0 */
import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { DefaultLayout } from '@core/DefaultLayout';
import Button from '@mui/material/Button';
import { CONTACT_MAIL } from '@config/constants';
import classes from './MaintenancePage.module.scss';

export const MaintenancePage: FC = () => (
  <DefaultLayout>
    <Box textAlign="center">
      <svg
        width="316"
        height="197"
        viewBox="0 0 316 197"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1531_42824)">
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
            <path
              d="M316 187.21C316 192.498 245.266 196.781 158 196.781C70.7344 196.781 0 192.498 0 187.21C0 181.921 70.7344 177.638 158 177.638C245.266 177.638 316 181.921 316 187.21Z"
              fill="#3E4954"
            />
          </g>
          <path
            d="M50.9261 25.7251H36.5625V181.114H50.9261V25.7251Z"
            fill="#3E4954"
          />
          <path
            d="M54.7076 25.323C60.4496 19.581 60.4496 10.2715 54.7076 4.52958C48.9657 -1.21235 39.6562 -1.21235 33.9143 4.52958C28.1723 10.2715 28.1723 19.581 33.9143 25.323C39.6562 31.0649 48.9657 31.0649 54.7076 25.323Z"
            fill="#3E4954"
          />
          <path
            d="M44.3037 24.6693C49.6835 24.6693 54.0448 20.3081 54.0448 14.9282C54.0448 9.54828 49.6835 5.18701 44.3037 5.18701C38.9238 5.18701 34.5625 9.54828 34.5625 14.9282C34.5625 20.3081 38.9238 24.6693 44.3037 24.6693Z"
            fill="#DF1C71"
          />
          <path
            d="M54.843 174.584H31.3389V187.642H54.843V174.584Z"
            fill="#3E4954"
          />
          <g style={{ mixBlendMode: 'soft-light' }}>
            <path
              d="M39.3428 15.1477L45.7019 8.78857"
              stroke="#F2F2F2"
              strokeWidth="1.30579"
              strokeMiterlimit="10"
            />
            <path
              d="M41.6289 17.4334L47.9881 11.0742"
              stroke="#F2F2F2"
              strokeWidth="1.30579"
              strokeMiterlimit="10"
            />
            <path
              d="M43.9014 19.7171L50.2736 13.3579"
              stroke="#F2F2F2"
              strokeWidth="1.30579"
              strokeMiterlimit="10"
            />
          </g>
          <path
            d="M198.478 25.7222H184.114V181.111H198.478V25.7222Z"
            fill="#3E4954"
          />
          <path
            d="M191.941 29.6413C200.059 29.4486 206.484 22.7114 206.291 14.5933C206.098 6.47531 199.361 0.0505876 191.243 0.243317C183.125 0.436047 176.7 7.17325 176.893 15.2913C177.086 23.4093 183.823 29.834 191.941 29.6413Z"
            fill="#3E4954"
          />
          <path
            d="M191.598 24.6644C196.977 24.6644 201.339 20.3032 201.339 14.9233C201.339 9.54339 196.977 5.18213 191.598 5.18213C186.218 5.18213 181.856 9.54339 181.856 14.9233C181.856 20.3032 186.218 24.6644 191.598 24.6644Z"
            fill="#DF1C71"
          />
          <path
            d="M202.398 174.582H178.894V187.64H202.398V174.582Z"
            fill="#3E4954"
          />
          <g style={{ mixBlendMode: 'soft-light' }}>
            <path
              d="M186.621 15.1463L192.993 8.78711"
              stroke="#F2F2F2"
              strokeWidth="1.30579"
              strokeMiterlimit="10"
            />
            <path
              d="M188.907 17.4319L195.279 11.0728"
              stroke="#F2F2F2"
              strokeWidth="1.30579"
              strokeMiterlimit="10"
            />
            <path
              d="M191.19 19.7156L197.55 13.3564"
              stroke="#F2F2F2"
              strokeWidth="1.30579"
              strokeMiterlimit="10"
            />
          </g>
          <path
            d="M211.538 37.4751H14.3643V76.6487H211.538V37.4751Z"
            fill="#F2F2F2"
          />
          <path
            d="M64.1538 37.4751L24.9803 76.6487H14.3643V66.2807L43.1568 37.4751H64.1538Z"
            fill="#3E4954"
          />
          <path
            d="M121.242 37.4751L82.0689 76.6487H61.085L100.245 37.4751H121.242Z"
            fill="#3E4954"
          />
          <path
            d="M178.332 37.4751L139.159 76.6487H118.175L157.335 37.4751H178.332Z"
            fill="#3E4954"
          />
          <path
            d="M211.538 40.3618V61.3588L196.247 76.6496H175.25L211.538 40.3618Z"
            fill="#3E4954"
          />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
            <path
              d="M211.538 67.5073H14.3643V76.6478H211.538V67.5073Z"
              fill="#3E4954"
            />
          </g>
          <path
            d="M255.449 94.8804H268.037L273.952 118.385L276.903 130.124L280.194 143.194L283.132 154.946L286.422 168.004L288.394 175.839H297.835V183.687L226.017 183.674V175.839H235.079L237.05 168.004L240.328 154.946L243.292 143.194L246.57 130.137L249.534 118.385L252.498 106.619L255.449 94.8804Z"
            fill="#F2F2F2"
          />
          <path
            d="M255.447 94.8804H268.035L270.999 106.632L252.496 106.619L255.447 94.8804Z"
            fill="#3E4954"
          />
          <path
            d="M249.533 118.385H273.952L276.903 130.124L246.569 130.137L249.533 118.385Z"
            fill="#3E4954"
          />
          <path
            d="M243.293 143.194H280.195L283.133 154.946H240.329L243.293 143.194Z"
            fill="#3E4954"
          />
          <path
            d="M237.05 168.003H286.422L288.394 175.838H297.835V183.685L226.017 183.672V175.838H235.079L237.05 168.003Z"
            fill="#3E4954"
          />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.5">
            <path
              d="M283.969 160.941L246.493 130.438L243.32 143.051L283.969 160.941Z"
              fill="#3E4954"
            />
          </g>
          <path
            d="M301.492 173.146L297.026 184.924L272.948 182.13L260.921 180.733L247.537 179.179L235.511 177.769L222.127 176.215L214.109 175.288L210.766 184.115L203.428 181.333L228.877 114.177L236.203 116.945L232.991 125.42L239.624 130.042L250.671 137.733L260.608 144.667L271.655 152.358L281.605 159.292L291.555 166.225L301.492 173.146Z"
            fill="#F2F2F2"
          />
          <path
            d="M301.491 173.145L297.026 184.923L284.986 183.526L291.554 166.224L301.491 173.145Z"
            fill="#3E4954"
          />
          <path
            d="M281.605 159.292L272.947 182.13L260.921 180.733L271.654 152.358L281.605 159.292Z"
            fill="#3E4954"
          />
          <path
            d="M260.608 144.664L247.537 179.176L235.511 177.766L250.671 137.73L260.608 144.664Z"
            fill="#3E4954"
          />
          <path
            d="M239.624 130.042L222.127 176.215L214.109 175.288L210.766 184.115L203.428 181.333L228.877 114.177L236.203 116.945L232.991 125.42L239.624 130.042Z"
            fill="#3E4954"
          />
          <path
            d="M211.381 106.41H14.207V145.584H211.381V106.41Z"
            fill="#F2F2F2"
          />
          <path
            d="M63.9966 106.41L24.8231 145.584H14.207V135.216L42.9996 106.41H63.9966Z"
            fill="#3E4954"
          />
          <path
            d="M121.085 106.41L81.9117 145.584H60.9277L100.088 106.41H121.085Z"
            fill="#3E4954"
          />
          <path
            d="M178.175 106.41L139.002 145.584H118.018L157.178 106.41H178.175Z"
            fill="#3E4954"
          />
          <path
            d="M211.381 109.296V130.293L196.09 145.584H175.093L211.381 109.296Z"
            fill="#3E4954"
          />
          <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
            <path
              d="M211.381 136.442H14.207V145.583H211.381V136.442Z"
              fill="#3E4954"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1531_42824">
            <rect
              width="316"
              height="196.56"
              fill="white"
              transform="translate(0 0.220215)"
            />
          </clipPath>
        </defs>
      </svg>

      <Box className={classes.title}>Page under maintenance</Box>
      <Box className={classes.subtitle}>
        We apologize for the inconvenience. This page is currently under
        maintenance.
        <br />
        We’ll return shortly!
      </Box>

      <a href={`mailto:${CONTACT_MAIL}`} style={{ textDecoration: 'none' }}>
        <Button sx={{ width: '166px' }} size="medium" variant="contained">
          Contact us
        </Button>
      </a>
    </Box>
  </DefaultLayout>
);
