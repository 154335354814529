import { FC, useEffect, useRef, useState } from 'react';
import { ListItemText, Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import { useMainChartPromotionFiltersStyle } from './MainChartPromotionFilters.style';
import promotionIcon from './assets/promotion.svg';
import { DropdownInput } from '../DropdownInput';
import { usePromotionOptions } from './MainChartPromotionFilters.hooks';
import { MainChartPromotionFiltersProps } from './types';
import { PillText } from '../PillText';

export const MainChartPromotionFilters: FC<MainChartPromotionFiltersProps> = ({
  isExport,
  allLabel,
  openCounter,
  value,
  onChange,
  formValues,
  singleSelect,
  fullWidth,
}) => {
  const classes = useMainChartPromotionFiltersStyle();
  const anchorElUser = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(Boolean(openCounter));
  const options = usePromotionOptions(onChange, formValues, isExport);
  const closeDropdown = () => setOpen(false);

  useEffect(() => setOpen(Boolean(openCounter)), [openCounter]);

  return (
    <>
      <DropdownInput
        fullWidth={fullWidth}
        ref={anchorElUser}
        disabled={options.length < 2}
        open={open}
        icon={promotionIcon}
        label={
          value.length ? (
            <PillText values={value.map(({ promotionKey }) => promotionKey)} />
          ) : (
            allLabel
          )
        }
        onClick={() => setOpen(true)}
      />

      <Menu
        classes={{ paper: classes.menuPaper }}
        onClose={closeDropdown}
        open={open}
        anchorEl={anchorElUser.current}
        keepMounted
      >
        {!singleSelect && (
          <Box
            mt={1}
            mb={1}
            mx={2}
            color={COLOR_PRIMARY}
            whiteSpace="nowrap"
            display="flex"
            justifyContent="space-between"
            fontSize="12px"
            fontWeight="500"
            minWidth="200px"
            top={0}
            position="sticky"
            zIndex={1}
          >
            <span className="pointer" onClick={() => onChange(options)}>
              Select all ({options.length})
            </span>
            <span className="pointer" onClick={() => onChange([])}>
              clear
            </span>
          </Box>
        )}

        {options.map((promotion) => (
          <MenuItem
            key={`${promotion.promotionKey}${promotion.clientKey}`}
            onClick={() => {
              const newValues = value.some(
                (item) => item.promotionKey === promotion.promotionKey
              )
                ? value.filter(
                    (item) => item.promotionKey !== promotion.promotionKey
                  )
                : [...value, promotion];

              onChange(singleSelect ? [promotion] : newValues);

              if (singleSelect) {
                closeDropdown();
              }
            }}
          >
            {!singleSelect && (
              <Checkbox
                sx={{ margin: '-7px 0 -7px -9px' }}
                checked={value.some(
                  (item) => item.promotionKey === promotion.promotionKey
                )}
              />
            )}

            <ListItemText
              primary={
                <Typography variant="body2">
                  {promotion.promotionKey}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
