import { FC, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { CompleteInviteForm } from '@components/CompleteInviteForm';
import { DefaultLayout } from '@core/DefaultLayout';
import Box from '@mui/material/Box';
import { SignInForm } from '@components/SignInForm';
import { removeDefaultMessage } from '@core/Messenger/Messenger.service';

export const SignInPage: FC = () => {
  const [userPasswordRequired, setUserPasswordRequired] =
    useState<CognitoUser | null>();

  removeDefaultMessage();

  return (
    <DefaultLayout>
      {userPasswordRequired ? (
        <CompleteInviteForm user={userPasswordRequired} />
      ) : (
        <Box data-testid="sign-in-tab-content">
          <SignInForm onPasswordRequired={setUserPasswordRequired} />
        </Box>
      )}
    </DefaultLayout>
  );
};
