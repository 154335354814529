import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import arrowIcon from './assets/arrow.svg';

export const ForgotPasswordConfirmation: FC<{
  onBack: () => void;
  email: string;
}> = ({ email, onBack }) => {
  const { t } = useTranslation();
  const [sendingCode, setSendingCode] = useState(false);

  return (
    <Box
      width={440}
      mx="auto"
      textAlign="center"
      sx={{ fontSize: 18, fontWeight: 400 }}
    >
      <Box
        onClick={onBack}
        sx={{
          fontSize: 14,
          fontWeight: 700,
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          mb: 4,
          lineHeight: 1,
        }}
      >
        <img src={arrowIcon} alt="" style={{ marginRight: 7, width: 18 }} />
        {t('common.back')}
      </Box>

      <Box sx={{ fontWeight: 700 }} mb={1}>
        {t('auth.forgotPasswordConfirmationTitle')}
      </Box>
      <Box mb={1}>{t('auth.forgotPasswordConfirmationLine1')}</Box>
      <Box mb={4}>{t('auth.forgotPasswordConfirmationLine2')}</Box>

      <Typography variant="body2">
        {`${t('auth.noMainInfo')} `}
        <Box
          className="pointer"
          component="span"
          color={sendingCode ? 'grey.400' : 'primary.main'}
          onClick={async () => {
            if (!sendingCode) {
              try {
                setSendingCode(true);
                await Auth.forgotPassword(email);
              } finally {
                setSendingCode(false);
              }
            }
          }}
          data-testid="resend-code-btn"
        >
          {t('auth.reSendCode')}
        </Box>
      </Typography>
    </Box>
  );
};
