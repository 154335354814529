import React, { FC, useEffect, useState } from 'react';
import { Message } from '@core/Messenger/types';
import { Alert, Collapse } from '@mui/material';
import { registerObserver, removeObserver } from './Messenger.service';

let timerID: unknown;
const debounceTime = 5000;

export const Messenger: FC = () => {
  const [message, setMessage] = useState<Message | null>(null);
  const [open, setOpen] = useState(false);

  const setTimer = () => {
    timerID = setTimeout(() => setOpen(false), debounceTime) as unknown;
  };

  useEffect(() => {
    const setter = (input: Message) => {
      if (input.variant !== 'error') {
        setMessage(input);
        setOpen(true);

        setTimer();
      }
    };

    registerObserver(setter);
    return () => removeObserver(setter);
  }, []);

  return (
    <Collapse
      in={open}
      onTransitionEnd={() => {
        if (!open) {
          setMessage(null);
        }
      }}
    >
      <Alert
        onMouseEnter={() => clearInterval(timerID as number)}
        onMouseLeave={setTimer}
        variant="filled"
        onClose={() => setOpen(false)}
        severity={message?.variant}
      >
        {message?.message}
      </Alert>
    </Collapse>
  );
};
