import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import {
  Header,
  Highlight,
  Paragraph,
  Table,
  Underline,
} from './UTMExplanation.components';

const CAMPAIGN_COLOR = '#bae9fb';
const SOURCE_COLOR = '#bafbc5';
const MEDIUM_COLOR = '#f4fbba';

export const UTMExplanation: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <Button variant="outlined" size="small" onClick={toggleOpen}>
        {t('optinGen.whatIsUTM')}
      </Button>

      <Dialog maxWidth="md" fullWidth open={open}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{t('optinGen.whatIsUTM')}</span>

          <IconButton
            size="small"
            onClick={toggleOpen}
            data-testid="close-icon"
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box mb={6} mx={2}>
            <Typography variant="body2">
              <Header>{t('optinGen.whatIsUTMLine1')}</Header>
              <Paragraph>{t('optinGen.whatIsUTMLine2')}</Paragraph>
              <Header>{t('optinGen.whatIsUTMLine3')}</Header>
              <Box component="span" m={1} pl={3} display="block">
                <li>‘utm_source‘</li>
                <li>‘utm_medium‘</li>
                <li>‘utm_campaign‘</li>
                <li>‘utm_term‘</li>
                <li>‘utm_content‘</li>
              </Box>
              <Paragraph>{t('optinGen.whatIsUTMLine4')}</Paragraph>
              <Header>Example:</Header>
              <Paragraph>
                {`${t('optinGen.whatIsUTMLine5')} `}
                <Highlight>https://awsome-company.com/promotion</Highlight>
                {` ${t('optinGen.whatIsUTMLine6')}`}
              </Paragraph>
              <Paragraph>{t('optinGen.whatIsUTMLine7')}</Paragraph>
              <Underline>Facebook UTMs</Underline>
              <Table
                data={[
                  ['‘utm_medium’', '‘utm_source’', '‘utm_campaign’'],
                  [
                    <>
                      &quot;
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        social_media
                      </Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={SOURCE_COLOR}>facebook</Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={MEDIUM_COLOR}>post1</Highlight>&quot;
                    </>,
                  ],
                  [
                    <>
                      &quot;
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        social_media
                      </Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={SOURCE_COLOR}>facebook</Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;<Highlight bgColor={MEDIUM_COLOR}>post2</Highlight>
                      &quot;
                    </>,
                  ],
                ]}
              />
              <Paragraph>{t('optinGen.whatIsUTMLine8')}</Paragraph>
              <Underline>{t('optinGen.whatIsUTMLine9')}</Underline>
              <Box component="span" m={1} ml={2} display="block">
                <Paragraph>
                  <Highlight>
                    https://awsome-company.com/promotion?utm_medium=
                    <Highlight bgColor={CAMPAIGN_COLOR}>social_media</Highlight>
                    &utm_source=
                    <Highlight bgColor={SOURCE_COLOR}>facebook</Highlight>
                    &utm_campaign=
                    <Highlight bgColor={MEDIUM_COLOR}>post1</Highlight>
                  </Highlight>
                </Paragraph>
                <Paragraph>
                  <Highlight>
                    https://awsome-company.com/promotion?utm_medium=
                    <Highlight bgColor={CAMPAIGN_COLOR}>social_media</Highlight>
                    &utm_source=
                    <Highlight bgColor={SOURCE_COLOR}>facebook</Highlight>
                    &utm_campaign=
                    <Highlight bgColor={MEDIUM_COLOR}>post1</Highlight>
                  </Highlight>
                </Paragraph>
              </Box>
              <Underline>Twitter UTMs</Underline>
              <Table
                data={[
                  ['‘utm_medium’', '‘utm_source’', '‘utm_campaign’'],
                  [
                    <>
                      &quot;
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        social_media
                      </Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={SOURCE_COLOR}>twitter</Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;<Highlight bgColor={MEDIUM_COLOR}>tweet1</Highlight>
                      &quot;
                    </>,
                  ],
                  [
                    <>
                      &quot;
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        social_media
                      </Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={SOURCE_COLOR}>twitter</Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;<Highlight bgColor={MEDIUM_COLOR}>tweet2</Highlight>
                      &quot;
                    </>,
                  ],
                ]}
              />
              <Paragraph>{t('optinGen.whatIsUTMLine10')}</Paragraph>
              <Underline>{t('optinGen.whatIsUTMLine11')}</Underline>
              <Box component="span" m={1} ml={2} display="block">
                <Paragraph>
                  <Highlight>
                    https://awsome-company.com/promotion?utm_medium=
                    <Highlight bgColor={CAMPAIGN_COLOR}>social_media</Highlight>
                    &utm_source=
                    <Highlight bgColor={SOURCE_COLOR}>twetter</Highlight>
                    &utm_campaign=
                    <Highlight bgColor={MEDIUM_COLOR}>tweet1</Highlight>
                  </Highlight>
                </Paragraph>
                <Paragraph>
                  <Highlight>
                    https://awsome-company.com/promotion?utm_campaign=
                    <Highlight bgColor={CAMPAIGN_COLOR}>social_media</Highlight>
                    &utm_source=
                    <Highlight bgColor={SOURCE_COLOR}>twetter</Highlight>
                    &utm_medium=
                    <Highlight bgColor={MEDIUM_COLOR}>tweet2</Highlight>
                  </Highlight>
                </Paragraph>
              </Box>

              <Underline>Influencers UTMs</Underline>
              <Table
                data={[
                  ['Influencer', '‘utm_medium’', '‘utm_source’'],
                  [
                    <span style={{ fontWeight: 600 }}>Influencer 1</span>,
                    <>
                      &quot;
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        influencers
                      </Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={SOURCE_COLOR}>
                        influencer_one
                      </Highlight>
                      &quot;
                    </>,
                  ],
                  [
                    <span style={{ fontWeight: 600 }}>Influencer 2</span>,
                    <>
                      &quot;
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        influencers
                      </Highlight>
                      &quot;
                    </>,
                    <>
                      &quot;
                      <Highlight bgColor={SOURCE_COLOR}>
                        influencer_two
                      </Highlight>
                      &quot;
                    </>,
                  ],
                ]}
              />
              <Underline>Influencer links</Underline>

              <Box component="span" m={1} ml={2} display="block">
                <Paragraph>Influencer 1</Paragraph>
                <Box component="span" ml={2} display="block">
                  <Paragraph>
                    <Highlight>
                      https://awsome-company.com/promotion?utm_medium=
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        influencers
                      </Highlight>
                      &utm_source=
                      <Highlight bgColor={SOURCE_COLOR}>
                        influencer_one
                      </Highlight>
                    </Highlight>
                  </Paragraph>
                </Box>
                <Paragraph>Influencer 2</Paragraph>
                <Box component="span" ml={2} display="block">
                  <Paragraph>
                    <Highlight>
                      https://awsome-company.com/promotion?utm_medium=
                      <Highlight bgColor={CAMPAIGN_COLOR}>
                        influencers
                      </Highlight>
                      &utm_source=
                      <Highlight bgColor={SOURCE_COLOR}>
                        influencer_one
                      </Highlight>
                    </Highlight>
                  </Paragraph>
                </Box>
              </Box>
              <Header>{t('optinGen.whatIsUTMLine12')}</Header>
              <Paragraph>{t('optinGen.whatIsUTMLine13')}</Paragraph>
              <ul>
                <li>
                  {t('optinGen.whatIsUTMLine14')}
                  <ul>
                    <li>{t('optinGen.whatIsUTMLine15')}</li>
                    <li>{t('optinGen.whatIsUTMLine16')}</li>
                    <li>{t('optinGen.whatIsUTMLine17')}</li>
                  </ul>
                </li>
                <li>
                  {t('optinGen.whatIsUTMLine18')}

                  <ul>
                    <li>{t('optinGen.whatIsUTMLine19')}</li>
                  </ul>
                </li>
              </ul>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
