import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RetoolContainmentTest } from '../RetoolContainmentTest';
import { ViewProps } from '../types';

export const Registration: FC<ViewProps> = ({ onDetected }) => {
  const [searchParams] = useSearchParams();

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={3} />
        <Grid item xs={9}>
          Desktop
        </Grid>
        <Grid item xs={3}>
          Additional text 1
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('registrationAdditionalText1')}
            width={554}
            height={30}
            lineHeight={24}
            fontWeight={400}
            fontSize={20}
          />
        </Grid>
        <Grid item xs={3}>
          Additional text 2
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('registrationAdditionalText2')}
            width={554}
            height={30}
            lineHeight={24}
            fontWeight={400}
            fontSize={20}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          Mobile
        </Grid>
        <Grid item xs={3}>
          Additional text 1
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('registrationAdditionalText1')}
            width={400}
            height={16}
            lineHeight="120%"
            fontWeight={400}
            fontSize={12}
          />
        </Grid>
        <Grid item xs={3}>
          Additional text 2
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('registrationAdditionalText2')}
            width={400}
            height={16}
            lineHeight="120%"
            fontWeight={400}
            fontSize={12}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
