import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import { FormHelperText } from '@mui/material';
import { EmailCodeInputProps } from './types';
import { SingleCodeInput } from './SingleCodeInput/SingleCodeInput';

export const EmailCodeInput: FC<EmailCodeInputProps> = ({
  onChange,
  errorText,
  charAmount,
}) => {
  const [inputValues, setInputValues] = useState<string[]>(
    Array(charAmount).join('.').split('.')
  );
  const [activeIndex, setActiveIndex] = useState<number>();

  return (
    <Box sx={{ textAlign: 'center', display: 'inline-block' }}>
      <Box display="inline-flex" flexDirection="row" mb="4px">
        {inputValues.map((val, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} mr={index + 1 === charAmount ? 0 : 1}>
            <SingleCodeInput
              error={Boolean(errorText)}
              value={val}
              onFocus={() => setActiveIndex(index)}
              onPaste={(newVal, startIndex) => {
                let newIndex = startIndex;
                for (let i = 0; i < newVal.length; i++) {
                  newIndex = startIndex + i;
                  inputValues[newIndex] = newVal[i];

                  if (newIndex + 1 === charAmount) {
                    break;
                  }
                }

                setInputValues([...inputValues]);
                onChange(inputValues.join(''));
                setActiveIndex(
                  newIndex === charAmount - 1 ? newIndex : newIndex + 1
                );
              }}
              moveCaretPrev={() => {
                const prevIndex = index - 1;
                if (prevIndex > -1) {
                  setActiveIndex(prevIndex);
                }
              }}
              moveCaretNext={() => {
                const nextIndex = index + 1;
                if (nextIndex !== charAmount) {
                  setActiveIndex(nextIndex);
                }
              }}
              onChange={(newVal) => {
                inputValues[index] = newVal;
                setInputValues([...inputValues]);
                onChange(inputValues.join(''));
              }}
              index={index}
              focusedIndex={activeIndex}
            />
          </Box>
        ))}
      </Box>

      {errorText && <FormHelperText error>{errorText}</FormHelperText>}
    </Box>
  );
};
