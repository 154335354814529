import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { PasswordField } from '@components/PasswordField';
import { useTranslation } from 'react-i18next';
import { AuthTitle } from '@components/AuthTitle';
import Box from '@mui/material/Box';
import { Messenger, toast } from '@core/Messenger';
import { Auth } from 'aws-amplify';
import { externalLogEvent } from '@services/log.service';
import { fetchInitialDataAction } from '@redux/login/login.asyncActions';
import { CognitoException } from '@typings/CognitoException';
import { setCognitoError } from '@services/cognito.service';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { newPasswordFormResolver } from './NewPasswordForm.validation';
import { NewPasswordFormProps, NewPasswordFormValues } from './types';

export const NewPasswordForm: FC<NewPasswordFormProps> = ({ email, code }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<NewPasswordFormValues>({
    mode: 'onTouched',
    resolver: newPasswordFormResolver,
  });

  const onSubmit = async ({ password }: NewPasswordFormValues) => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      await Auth.signIn({
        username: email,
        password,
      });

      externalLogEvent('console_forgot_password');

      await dispatch(fetchInitialDataAction());

      navigate('/');
    } catch (e) {
      const error = e as CognitoException;
      if (!setCognitoError(setError, error, 'password', 'password')) {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AuthTitle
            title={t('auth.newPassword')}
            subtitle={`${t('auth.newPasswordInfo')}.`}
          />
          <Box mt="12px" mb="12px">
            <Messenger />
          </Box>
          <PasswordField
            label={`${t('common.password')} *`}
            fullWidth
            disabled={isSubmitting}
            inputProps={{ 'data-testid': 'password-input' }}
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            label={`${t('common.confirmPassword')} *`}
            fullWidth
            disabled={isSubmitting}
            inputProps={{ 'data-testid': 'confirm-password-input' }}
            error={!!errors.passwordConfirm}
            helperText={errors.passwordConfirm?.message}
            {...register('passwordConfirm')}
          />

          <Box mt={1} ml={3}>
            <Typography variant="caption">
              {`${t('auth.newPasswordShouldContain')}:`}
              <Box ml={1}>
                <li>{t('auth.passwordCondition1')}</li>
                <li>{t('auth.passwordCondition2')}</li>
                <li>{t('auth.passwordCondition3')}</li>
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={324} mx="auto">
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              data-testid="reset-password-submit"
            >
              {t('auth.resetPassword')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
