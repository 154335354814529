import React, { FC } from 'react';
import { Props } from '@components/AuthTitle/types';
import Typography from '@mui/material/Typography';

export const AuthTitle: FC<Props> = ({ title, subtitle }) => (
  <>
    <Typography
      variant="h4"
      sx={{ textAlign: 'center', fontWeight: 800, mb: 1, fontSize: 40 }}
    >
      {title}
    </Typography>

    <Typography
      variant="body1"
      sx={{ textAlign: 'center', fontWeight: 400, fontSize: 18 }}
    >
      {subtitle}
    </Typography>
  </>
);
