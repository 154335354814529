import { FC, MouseEvent, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logoutAction } from '@redux/login/login.asyncActions';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ChangePasswordModal } from '@components/ChangePasswordModal';
import { externalLogEvent } from '@services/log.service';
import { toast } from '@core/Messenger';

export const UserMenu: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { email } = useSelector((store: Store) => store.login);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = async () => {
    setAnchorElUser(null);
    externalLogEvent('console_logout');
    try {
      await dispatch(logoutAction());
      navigate('/sign-in');
    } catch (e) {
      toast.error((e as Error).message);
    }
  };

  return (
    <>
      <Box
        className="pointer"
        onClick={handleOpenUserMenu}
        sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}
      >
        <Box
          data-testid="user-menu-btn"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 32,
            height: 32,
            borderRadius: 16,
            bgcolor: '#3E4954',
            fontSize: 20,
            fontWeight: 500,
            fontFamily: 'Inter',
            color: 'white',
            textTransform: 'uppercase',
            lineHeight: 1,
            mr: 2,
          }}
        >
          {email[0]}
        </Box>

        <Box component="span" color="grey.600" lineHeight="1">
          {anchorElUser ? (
            <KeyboardArrowUpIcon fontSize="medium" color="inherit" />
          ) : (
            <KeyboardArrowDownIcon fontSize="medium" color="inherit" />
          )}
        </Box>
      </Box>

      <Menu
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          data-testid="user-menu-change-password-btn"
          onClick={() => {
            externalLogEvent('console_change_password');
            setOpen(true);
            handleCloseUserMenu();
          }}
        >
          <ListItemText
            primary={
              <Typography variant="body2">
                {t('auth.changePassword')}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          data-testid="opt-in-btn"
          onClick={() => navigate('/internal/opt-in')}
        >
          <ListItemText
            primary={<Typography variant="body2">Opt-in generator</Typography>}
          />
        </MenuItem>

        <MenuItem data-testid="user-menu-logout-link" onClick={logout}>
          <ListItemText
            primary={
              <Typography variant="body2">{t('auth.logout')}</Typography>
            }
          />
        </MenuItem>
      </Menu>

      <ChangePasswordModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};
