import Box from '@mui/material/Box';
import { UserMenu } from '@core/LoggedInLayout/UserMenu/UserMenu';
import { useMemo, useState } from 'react';
import { MobileMenu } from '@core/LoggedInLayout/TopBar/MobileMenu';
import { Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';

export const TopBar = () => {
  const [expanded, setExpanded] = useState(false);
  const { clients, activeClientKey } = useSelector(({ login }: Store) => login);
  const { sideMenuHidden } = useSelector(({ ui }: Store) => ui);

  const clientName = useMemo(
    () =>
      activeClientKey.length === 1
        ? clients[activeClientKey[0] as string]?.clientName
        : 'Overview',
    [activeClientKey, clients]
  );

  const logoUrlName = useMemo(
    () =>
      activeClientKey.length === 1 &&
      clients[activeClientKey[0] as string]?.branding?.brandingLogoUrl,
    [activeClientKey, clients]
  );

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          borderBottom: '1px solid',
          borderColor: 'grey.300',
          borderRadius: 0,
        }}
      >
        <Box
          height="72px"
          boxSizing="border-box"
          display="flex"
          alignItems="center"
          justifyContent={{
            xs: 'space-between',
          }}
          sx={{ px: 4, py: 2 }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5"
              fontWeight="500"
              flexGrow={1}
              ml={sideMenuHidden ? 4 : 0}
            >
              {clientName}
            </Typography>

            {logoUrlName && (
              <Box
                mr={2}
                display={{ xs: 'none', sm: 'block' }}
                data-testid="top-logo"
              >
                <img
                  src={logoUrlName}
                  alt="Logo"
                  height="40px"
                  style={{ maxWidth: 200 }}
                />
              </Box>
            )}
          </Box>

          <Box
            pl="12px"
            height="40px"
            borderLeft="1px solid"
            borderColor="grey.300"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <UserMenu />
          </Box>
        </Box>
      </Paper>
      <MobileMenu open={expanded} onClick={() => setExpanded(false)} />
    </>
  );
};
