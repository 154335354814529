import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_VALUES,
  getUTMUrl,
  URL_REGEXP,
  UTM,
} from './UTMLinkCreator.service';

export const UTMLinkCreator: FC = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setValues(DEFAULT_VALUES);
    setOpen(!open);
  };

  const register = (key: string) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: ({ target: { value } }: any) =>
      setValues({ ...values, [key]: value.trim() }),
    value: values[key],
  });

  const isValidUrl = URL_REGEXP.test(values.url);

  return (
    <>
      <Button variant="outlined" size="small" onClick={toggleOpen}>
        {t('optinGen.UTMGenerator')}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{t('optinGen.UTMGenerator')}</span>

          <IconButton
            size="small"
            onClick={toggleOpen}
            data-testid="close-icon"
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box mb={3}>
            <Typography variant="body2">
              {t('optinGen.UTMGeneratorLine1')}
              <br />
              <br />
              {t('optinGen.UTMGeneratorLine2')}
              <br />
              {`${t('optinGen.UTMGeneratorLine3')} `}
              <span style={{ textDecoration: 'underline' }}>http</span> or{' '}
              <span style={{ textDecoration: 'underline' }}>https</span>:
              <Box my={2}>
                <TextField
                  fullWidth
                  label={t('optinGen.landingPageURL')}
                  variant="outlined"
                  size="small"
                  {...register('url')}
                  error={!isValidUrl && Boolean(values.url)}
                  helperText={
                    isValidUrl || !values.url ? undefined : t('validation.url')
                  }
                />
              </Box>
              <Box
                sx={{
                  transition: '0.3s',
                  height: isValidUrl ? '400px' : '0px',
                  overflow: isValidUrl ? 'visible' : 'hidden',
                }}
              >
                <Box mb={2}>{`${t('optinGen.UTMGeneratorLine4')}:`}</Box>
                {UTM.map((key) => (
                  <Box mb={1}>
                    <TextField
                      label={key}
                      variant="outlined"
                      size="small"
                      {...register(key)}
                    />
                  </Box>
                ))}
                <Box my={2}>
                  Your url is:{' '}
                  <Box
                    mt={1}
                    fontWeight={500}
                    fontSize="16px"
                    color={COLOR_PRIMARY}
                    sx={{ wordBreak: 'break-all' }}
                  >
                    {getUTMUrl(values)}
                  </Box>
                </Box>
              </Box>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
