import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '@core/i18n/i18n';
import { Auth } from 'aws-amplify';
import {
  fetchClients,
  fetchClientsWeb,
  fetchUserExportInfo,
} from '@services/clients.service';
import { blockUiAction, unblockUiAction } from '@redux/ui/ui.slice';
import { loggedOutAction } from '@redux/login/login.slice';
import { toast } from '@core/Messenger';
import { setDefaultMessage } from '@core/Messenger/Messenger.service';

export const fetchInitialDataAction = createAsyncThunk(
  'login/initData',
  async (_, { dispatch }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const clients = await fetchClients().catch(() => {
        toast.error(i18n.t('auth.unrecognizedEmail'));
        setDefaultMessage({
          variant: 'error',
          message: i18n.t('auth.unrecognizedEmail'),
        });
        return Auth.signOut().then(() =>
          Promise.reject(i18n.t('auth.unrecognizedEmail'))
        );
      });

      const exportPermissions = await fetchUserExportInfo();

      await Promise.all(
        clients.clients.map((client) =>
          fetchClientsWeb(client.clientKey)
            .then((item) => {
              client.isWithUtm = Boolean(item?.utmEnabled);
              client.displayName = item?.displayName;
              client.storeLocator = item?.storeLocator;
              client.promotions = client.promotions.map((promotion) => {
                const writeProp = (input: string[]) => {
                  input.forEach((key) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    promotion[key] =
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      item.promotions?.[promotion?.promotionKey]?.[key];
                  });
                };

                writeProp([
                  'optinSurveyEnabled',
                  'startDate',
                  'endDate',
                  'landingPageUrl',
                  'inventory',
                  'retailerList',
                  'deal',
                ]);

                return promotion;
              });
            })
            .catch(() => {
              client.isWithUtm = false;
            })
        )
      );

      return {
        user,
        clients,
        exportPermissions,
      };
    } catch (e) {
      dispatch(loggedOutAction());

      throw e;
    }
  }
);

export const logoutAction = createAsyncThunk(
  'login/logout',
  async (_, { dispatch }) => {
    dispatch(blockUiAction());

    try {
      await Auth.signOut();

      dispatch(loggedOutAction());
    } finally {
      dispatch(unblockUiAction());
    }
  }
);
