import * as Yup from 'yup';
import i18n from '@core/i18n/i18n';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required(i18n.t('validation.required'))
    .test('length', i18n.t('auth.codeInvalid'), (value) =>
      Boolean(value && value.length === 6)
    ),
});

export const resetPasswordFormResolver = yupResolver(validationSchema);
