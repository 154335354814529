import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { PasswordField } from '@components/PasswordField';
import { useTranslation } from 'react-i18next';
import { setCognitoError } from '@services/cognito.service';
import { CognitoException } from '@typings/CognitoException';
import Box from '@mui/material/Box';
import { Link } from '@core/Link';
import { AuthTitle } from '@components/AuthTitle';
import { useDispatch } from 'react-redux';
import { setTempPassword } from '@redux/login/login.slice';
import { externalLogEvent } from '@services/log.service';
import { TERMS_OF_SERVICE_URL } from '@config/constants';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import { Messenger, toast } from '@core/Messenger';
import { SocialMediaLoginButtons } from '@components/SocialMediaLoginBtns';
import { SignUpValues } from './types';
import { signUpResolver } from './SignUpForm.validation';

export const SignUpForm: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SignUpValues>({
    resolver: signUpResolver,
    mode: 'onTouched',
  });

  const onSubmit = async (data: SignUpValues) => {
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
        },
      });

      externalLogEvent('console_signup_password');

      dispatch(setTempPassword(data.password));

      navigate(`/confirm-sign-up/${data.email}`);
    } catch (e) {
      const error = e as CognitoException;
      if (!setCognitoError(setError, error, 'email', 'password')) {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AuthTitle
            title={`${t('auth.welcomeToOurcart')}!`}
            subtitle={
              <>
                {`${t('auth.haveAccount')}? `}
                <Link to="/sign-in">{t('auth.logInHere')}</Link>
              </>
            }
          />

          <Box mt="12px" mb="12px">
            <Messenger />
          </Box>
          <Box mt={2}>
            <SocialMediaLoginButtons isSignUpPage disabled={isSubmitting} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center', fontWeight: 500, my: 1 }}
          >
            {t('auth.orRegisterEmail')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={`${t('auth.workEmail')} *`}
            fullWidth
            disabled={isSubmitting}
            inputProps={{ 'data-testid': 'email-input' }}
            variant="outlined"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            label={`${t('common.password')} *`}
            fullWidth
            inputProps={{ 'data-testid': 'password-input' }}
            disabled={isSubmitting}
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            label={`${t('auth.validatePassword')} *`}
            fullWidth
            inputProps={{ 'data-testid': 'confirm-password-input' }}
            disabled={isSubmitting}
            error={!!errors.passwordConfirm}
            helperText={errors.passwordConfirm?.message}
            {...register('passwordConfirm')}
          />

          <Box mt={1} ml={3}>
            <Typography variant="caption">
              {`${t('auth.newPasswordShouldContain')}:`}
              <Box ml={1}>
                <li>{t('auth.passwordCondition1')}</li>
                <li>{t('auth.passwordCondition2')}</li>
                <li>{t('auth.passwordCondition3')}</li>
              </Box>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box maxWidth={324} mx="auto">
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              data-testid="create-account-submit"
              fullWidth
            >
              {t('auth.signUp')}
            </Button>
          </Box>
          <Box mt={1} mx="auto">
            <FormControlLabel
              sx={{ mr: -2 }}
              color="error"
              label={
                <Typography variant="caption">
                  {t('auth.acceptTOC1')}
                  <a
                    href={TERMS_OF_SERVICE_URL}
                    style={{ color: COLOR_PRIMARY, textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {` ${t('auth.termsOfServices')} `}
                  </a>
                </Typography>
              }
              control={
                <Checkbox
                  color="default"
                  data-testid="tos-checkbox"
                  checkedIcon={<CheckBoxIcon />}
                  {...register('tos')}
                  value
                />
              }
            />
            {errors.tos && (
              <FormHelperText error>{t('auth.tosError')}</FormHelperText>
            )}
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
