import { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import clsx from 'clsx';
import { DropdownInputProps } from './types';
import classes from './DropdownInput.module.scss';

export const DropdownInput = forwardRef<HTMLDivElement, DropdownInputProps>(
  ({ error, icon, fullWidth, label, onClick, open, disabled }, ref) => (
    <Box
      ref={ref}
      onClick={disabled ? undefined : onClick}
      className={clsx(classes.input, {
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: disabled,
        [classes.error]: error,
        [classes.open]: open,
      })}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        overflow="hidden"
        width="100%"
      >
        <img
          src={icon}
          alt="calendar"
          height="18px"
          width="20px"
          style={{ marginRight: 8 }}
        />
        <Typography
          variant="body2"
          color="inherit"
          fontWeight="inherit"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
          }}
        >
          {label}
        </Typography>
      </Box>

      <Box component="span" color="grey.600" lineHeight="1">
        {open ? (
          <KeyboardArrowUpIcon fontSize="medium" color="inherit" />
        ) : (
          <KeyboardArrowDownIcon fontSize="medium" color="inherit" />
        )}
      </Box>
    </Box>
  )
);
