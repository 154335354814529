import { colors, createTheme, lighten } from '@mui/material';

export const COLOR_PRIMARY = '#DF1C71';
export const COLOR_SECONDARY = '#FFBFD9';
export const COLOR_LIGHT_PRIMARY = lighten(COLOR_PRIMARY, 0.8);
export const COLOR_TRANSPARENT_DARK = 'rgba(0,0,0,0.15)';
export const COLOR_TRANSPARENT_DARKEST = 'rgba(0,0,0,0.3)';
export const COLOR_TRANSPARENT_WHITE = 'rgba(255,255,255,0.15)';

document.documentElement.style.setProperty('--primary-color', COLOR_PRIMARY);

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Roboto, Arial',
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: { paper: { borderRadius: 16 } },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.grey['600'],

          '&:hover': {
            color: colors.grey['800'],
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: 1.25,
          marginTop: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 50,
        },
        sizeLarge: {
          height: 54,
          fontWeight: 700,
        },
        sizeMedium: {
          height: 40,
          fontWeight: 700,
        },
        sizeSmall: {
          fontSize: 14,
          height: 32,
          fontWeight: 500,
        },
        text: {
          fontWeight: '500 !important',
        },
        outlinedInherit: {
          borderColor: colors.grey[300],
        },
        containedSizeLarge: {
          fontWeight: 700,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          marginLeft: '0 !important',
        },
        outlined: {
          marginLeft: 8,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingLeft: 24,
        },
        root: {
          borderRadius: 50,
        },
      },
    },
  },
});
