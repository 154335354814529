import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import dayjs, { Dayjs } from 'dayjs';
import IconButton from '@mui/material/IconButton';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { CalendarContainer } from './CalendarContainer/CalendarContainer';
import { Props } from './types';
import { DAY_FORMAT } from '../../MainChart.service';
import { ChartFiltersDateType } from '../../types';

export const DateRangeInput: FC<Props> = ({
  onChange,
  fromDate,
  toDate,
  dateType,
  disabled,
  disabledFuture,
}) => {
  const [currentFrom, setCurrentFrom] = useState<Dayjs>();
  const [currentTo, setCurrentTo] = useState<Dayjs>();
  const [month, setMonth] = useState<Dayjs>(dayjs());

  useEffect(() => {
    setCurrentFrom(fromDate ? dayjs(fromDate) : undefined);
    setCurrentTo(toDate ? dayjs(toDate) : undefined);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (dateType !== ChartFiltersDateType.Custom) {
      setMonth(dayjs(toDate).startOf('month'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateType]);

  const onDateClick = (newDate: Dayjs) => {
    if (currentFrom && !currentTo) {
      if (newDate.isAfter(currentFrom)) {
        setCurrentTo(newDate);
        onChange(currentFrom.format(DAY_FORMAT), newDate.format(DAY_FORMAT));
      } else {
        setCurrentTo(currentFrom);
        setCurrentFrom(newDate);
        onChange(newDate.format(DAY_FORMAT), currentFrom.format(DAY_FORMAT));
      }

      return;
    }

    setCurrentFrom(newDate);
    setCurrentTo(undefined);
  };

  const isAllTime = dateType === ChartFiltersDateType.AllTimes;
  const prevMonth = month.subtract(1, 'month');
  const nextMonth = month.add(1, 'month');

  return (
    <Box display="flex" justifyContent="space-around">
      <Box mr={3}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          mx={-2}
        >
          <IconButton
            size="small"
            onClick={() => setMonth(prevMonth)}
            disabled={disabled}
          >
            <ChevronLeft />
          </IconButton>

          <Typography variant="body2" fontWeight={500}>
            {prevMonth.format('MMMM')}
          </Typography>

          <Box width="28px" height="28px" />
        </Box>

        <CalendarContainer
          isAllTime={isAllTime}
          disabled={disabled}
          onClick={onDateClick}
          fromDate={currentFrom}
          toDate={currentTo}
          month={prevMonth}
        />
      </Box>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
          mx={-2}
        >
          <Box width="28px" height="28px" />

          <Typography variant="body2" fontWeight={500}>
            {month.format('MMMM')}
          </Typography>

          <IconButton
            disabled={
              disabled ||
              (disabledFuture && nextMonth.isAfter(dayjs(), 'month'))
            }
            size="small"
            onClick={() => setMonth(nextMonth)}
          >
            <ChevronRight />
          </IconButton>
        </Box>

        <CalendarContainer
          disabledFuture={disabledFuture}
          isAllTime={isAllTime}
          disabled={disabled}
          onClick={onDateClick}
          fromDate={currentFrom}
          toDate={currentTo}
          month={month}
        />
      </Box>
    </Box>
  );
};
