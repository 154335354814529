import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnySchema } from 'yup';
import i18n from '@core/i18n/i18n';
import { isPasswordPolicyCompliant } from '@services/validation.service';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required(i18n.t('validation.required')),
  password: Yup.string()
    .required(i18n.t('validation.required'))
    .test(
      'policy',
      i18n.t('validation.passwordPolicy'),
      isPasswordPolicyCompliant
    ),
  passwordConfirm: Yup.string()
    .required(i18n.t('validation.required'))
    .when('password', (password: string, schema: AnySchema) =>
      schema.test(
        'match',
        i18n.t('validation.match'),
        (value: string) => value === password
      )
    ),
});

export const changePasswordFormResolver = yupResolver(validationSchema);
