import React, { FC, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import { useTranslation } from 'react-i18next';
import { OptinCreatorCodeResult } from './OptinCreatorCodeResult';
import { OptinCreatorForm } from './OptinCreatorForm';
import { OptinCreatorFormValues, Props } from './types';

export const defaultValues: OptinCreatorFormValues = {
  heightDesktop: 490,
  heightMobile: 490,
  widthDesktop: 460,
  widthMobile: 360,
  withUTMS: true,
};

export const OptinCreator: FC<Props> = ({
  clientKey,
  promotionKey,
  isWithUtm,
}) => {
  const { t } = useTranslation();
  const [formValues, setFormValues] =
    useState<OptinCreatorFormValues>(defaultValues);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ color: COLOR_PRIMARY, fontWeight: 500 }}>
          {t('optinGen.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          {`${t('optinGen.clientKey')}: `}
          <Box
            component="span"
            color={COLOR_PRIMARY}
            display="inline-block"
            fontWeight={500}
            fontSize="1.5em"
          >
            {clientKey}
          </Box>
          <br />
          {`${t('optinGen.promotionKey')}: `}
          <Box
            component="span"
            color={COLOR_PRIMARY}
            display="inline-block"
            fontWeight={500}
            fontSize="1.5em"
          >
            {promotionKey}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{t('optinGen.firstLinePublic')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <OptinCreatorForm
          defaultValues={defaultValues}
          onChange={setFormValues}
          withoutUTM={!isWithUtm}
        />
      </Grid>

      <Grid item xs={12}>
        <OptinCreatorCodeResult
          clientKey={clientKey}
          promotionKey={promotionKey}
          {...formValues}
        />
      </Grid>
    </Grid>
  );
};
