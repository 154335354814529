import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  Email,
  Initial,
  Optin,
  Registration,
} from '@components/RetoolContainmentTest';

export const RetoolPreviewPage: FC = () => {
  const { view } = useParams<{ view: string }>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [decided, setDecided] = useState<boolean>();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!showErrorModal && !decided) {
        setShowSuccessModal(true);
      }
      setDecided(true);
    }, 200);

    return () => clearTimeout(timeoutId);
  }, [showErrorModal, decided]);

  const onDetected = (result: boolean) => {
    if (!result) {
      setShowErrorModal(true);
      setDecided(true);
    }
  };

  const getContent = () => {
    switch (view) {
      case 'initial':
        return <Initial onDetected={onDetected} />;
      case 'optin':
        return <Optin onDetected={onDetected} />;
      case 'email':
        return <Email onDetected={onDetected} />;
      case 'registration':
        return <Registration onDetected={onDetected} />;
      default:
        return null;
    }
  };

  return (
    <>
      {getContent()}
      <Dialog open={Boolean(showErrorModal || showSuccessModal)}>
        <DialogTitle color={showErrorModal ? 'red' : 'green'}>
          {showErrorModal && 'Invalid'}
          {showSuccessModal && 'Valid'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {showSuccessModal &&
              'All fields are at a fine length, the element can be published'}
            {showErrorModal &&
              'Some fields are too long, please shorten them to continue and try again'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShowErrorModal(false);
              setShowSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
