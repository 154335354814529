import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Dialog, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { PasswordField } from '@components/PasswordField';
import { useTranslation } from 'react-i18next';
import { CognitoException } from '@typings/CognitoException';
import { setCognitoError } from '@services/cognito.service';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { externalLogEvent } from '@services/log.service';
import { toast } from '@core/Messenger';
import { changePasswordFormResolver } from './ChangePasswordModal.validation';
import { ChangePasswordFormValues, ChangePasswordModalProps } from './types';

export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordFormValues>({
    mode: 'onTouched',
    resolver: changePasswordFormResolver,
  });

  const onSubmit = async ({
    oldPassword,
    password,
  }: ChangePasswordFormValues) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, password);

      externalLogEvent('console_change_password');

      toast.success(t('auth.passwordChangedSuccess'));
      onClose();
    } catch (e) {
      const error = e as CognitoException;

      if (error.code === 'NotAuthorizedException') {
        setError('oldPassword', {
          type: error.code,
          message: t('cognito.NotAuthorizedExceptionAlt', error.message),
        });
        return;
      }

      if (!setCognitoError(setError, error, 'oldPassword')) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    reset();
  }, [open, reset]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <Box mb={6}>
        <Box textAlign="right" px={3} pt={3} pb={1}>
          <IconButton size="small" onClick={onClose} data-testid="close-icon">
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box maxWidth="440px" mx="auto" px={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'center',
                    fontSize: 40,
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  {t('auth.changePassword')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  disabled={isSubmitting}
                  label={`${t('auth.currentPassword')} *`}
                  fullWidth
                  inputProps={{ 'data-testid': 'change-old-password-input' }}
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message}
                  {...register('oldPassword')}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  label={`${t('auth.newPassword')} *`}
                  fullWidth
                  inputProps={{ 'data-testid': 'change-password-input' }}
                  disabled={isSubmitting}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  {...register('password')}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  label={`${t('auth.confirmNewPassword')} *`}
                  fullWidth
                  inputProps={{
                    'data-testid': 'change-password-confirm-input',
                  }}
                  disabled={isSubmitting}
                  error={!!errors.passwordConfirm}
                  helperText={errors.passwordConfirm?.message}
                  {...register('passwordConfirm')}
                />

                <Box mt={1} ml={3} mb={3}>
                  <Typography variant="caption" lineHeight={1.5}>
                    {`${t('auth.newPasswordShouldContain')}:`}
                    <Box ml={1}>
                      <li>{t('auth.passwordCondition1')}</li>
                      <li>{t('auth.passwordCondition2')}</li>
                      <li>{t('auth.passwordCondition3')}</li>
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} color="grey.500" textAlign="center">
                <Button
                  onClick={onClose}
                  sx={{ width: 70, mr: 1 }}
                  variant="text"
                  color="inherit"
                  data-testid="change-password-cancel"
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  sx={{ width: 218 }}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  data-testid="change-password-submit"
                >
                  {t('auth.updatePassword')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};
