import { getRequest, graphqlRequest } from '@services/http.service';
import {
  ClientPermissions,
  ClientsResp,
  UserExportInfoResp,
} from '@typings/ClientsResp';
import gql from 'graphql-tag';
import { ClientConfiguration } from '@typings/ClientConfiguration';
import { ExportFileType, ExportUrlReq } from '@typings/ExportUrlReq';

export const fetchClients = () =>
  graphqlRequest<ClientsResp>(
    gql`
      {
        clients {
          clientId
          clientKey
          clientName
          branding {
            brandingLogoUrl
          }
          promotions {
            promotionId
            promotionKey
            questions {
              questionKey
              questionText
              questionType
              questionUsedFor
            }
          }
        }
      }
    `
  ).then(({ data }) => data || { clients: [] });

export const fetchUserExportInfo = (): Promise<ClientPermissions[]> =>
  graphqlRequest<UserExportInfoResp>(
    gql`
      {
        userInfo {
          id
          email
          clientPermissions {
            clientKey
            piiAllowed
            exportsAllowed
          }
        }
      }
    `
  ).then(({ data }) => data?.userInfo?.clientPermissions || []);

export const fetchExportUrl = (dataRequest: ExportUrlReq): Promise<string> =>
  graphqlRequest(
    gql`
      query ExportsQuery($dataRequest: DataRequestInput) {
        ${
          dataRequest.fileTypes.includes(ExportFileType.KPI)
            ? 'kpis'
            : 'exports'
        }(dataRequest: $dataRequest) {
          url
        }
      }
    `,
    { dataRequest }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ).then(({ data }: any) => data?.exports?.url || data?.kpis?.url || '');

export const fetchClientsWeb = (
  clientKey: string
): Promise<ClientConfiguration> =>
  getRequest(`/configuration?client-key=${clientKey}`, {
    headers: { 'client-key': clientKey },
  });
