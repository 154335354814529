import React, { FC, useEffect, useState } from 'react';
import { Backdrop, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { IFramePopupsProps, Position } from './types';

const MAX_WIDTH = 1324;
const MAX_HEIGHT = 990;
const PADDING = 16;
const MIN_AVAILABLE_WIDTH = 420;

export const IFramePopups: FC<IFramePopupsProps> = ({
  btnRef,
  url,
  onClose,
}) => {
  const [popupPos, setPopupPos] = useState<Position>({
    bottom: PADDING,
    left: 0,
    right: 0,
    top: 0,
  });

  useEffect(() => {
    const onResize = () => {
      if (btnRef) {
        const { left, width } = btnRef.getBoundingClientRect();

        const posLeft = left + width + PADDING;
        const availableWidth = window.innerWidth - posLeft - PADDING;
        const availableHeight = window.innerHeight - 2 * PADDING;

        setPopupPos({
          bottom: PADDING,
          left: availableWidth < MIN_AVAILABLE_WIDTH ? PADDING : posLeft,
          right:
            availableWidth > MAX_WIDTH
              ? window.innerWidth - (posLeft + MAX_WIDTH)
              : PADDING,
          top:
            availableHeight > MAX_HEIGHT
              ? window.innerHeight - (posLeft + MAX_HEIGHT)
              : PADDING,
        });
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [btnRef]);

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={Boolean(btnRef)}
    >
      <Paper
        sx={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          ...popupPos,
        }}
      >
        <Box textAlign="right" p={1}>
          <IconButton size="small" onClick={onClose} data-testid="close-btn">
            <ClearIcon />
          </IconButton>
        </Box>
        <iframe
          width="100%"
          height="100%"
          src={url}
          title="iframe"
          frameBorder="0"
          referrerPolicy="no-referrer"
        />
      </Paper>
    </Backdrop>
  );
};
