import React, { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { OptinCreator } from '@components/OptinCreator';
import { useSearchParams } from 'react-router-dom';
import { fetchClientsWeb } from '@services/clients.service';

/*
Shopify
Magento
BigCommerce
WooCommerce
*/
export const OptInPage: FC = () => {
  const [searchParams] = useSearchParams();
  const [isWithUtm, setWithUtm] = useState(false);

  const promotionKey = searchParams.get('promotionKey');
  const clientKey = searchParams.get('clientKey');

  useEffect(() => {
    if (clientKey) {
      fetchClientsWeb(clientKey)
        .then((item) => {
          setWithUtm(Boolean(item.utmEnabled));
        })
        .catch(() => undefined);
    }
  }, [clientKey]);

  if (!promotionKey || !clientKey) {
    return null;
  }

  return (
    <Box p={3}>
      <OptinCreator
        promotionKey={promotionKey}
        clientKey={clientKey}
        isWithUtm={isWithUtm}
      />
    </Box>
  );
};
