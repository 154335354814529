import { ChartFiltersDateType } from '@components/MainChart/types';
import i18n from '@core/i18n/i18n';

export const getDateLabel = (
  dateType: ChartFiltersDateType,
  fromDate?: string,
  toDate?: string
) =>
  dateType === ChartFiltersDateType.Custom
    ? `${fromDate} - ${toDate}`
    : i18n.t(`dateOption.${dateType}`);
