export enum ExportFileType {
  USER_REGISTRATION_REDEMPTION = 'USER_REGISTRATION_REDEMPTION',
  SURVEY = 'SURVEY',
  ITEM = 'ITEM',
  OPT_IN = 'OPT_IN',
  KPI = 'KPI',
}

export enum ExportFormat {
  CSV = 'csv',
  EXCEL = 'xlsx',
}

export interface ClientPromotionsFilters {
  clientKey: string;
  promotionKeys?: string[];
}

export interface ExportUrlReq {
  startDate: string | null;
  endDate: string | null;
  clientPromotions: ClientPromotionsFilters[];
  fileTypes: ExportFileType[];
  piiIncluded: boolean;
  format: ExportFormat;
}
