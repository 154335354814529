import { Box, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { FC, useMemo } from 'react';
import { ChartTooltipProps } from './types';

const RowBigY = [2.45, 6.8];
const columnBigX = [1, 25.4, 49.9, 75.4];

export const ChartTooltip: FC<ChartTooltipProps> = ({
  text,
  index,
  iFrameWidth,
}) => {
  const positions = useMemo(() => {
    const column = index % 4;
    const row = Math.floor(index / 4);

    if (iFrameWidth > 2000) {
      return {
        top: `${RowBigY[row] + 0.3}%`,
        left: `${columnBigX[column]}%`,
      };
    }
    if (iFrameWidth > 1338) {
      return {
        top: `${RowBigY[row] + 0.15}%`,
        left: `${columnBigX[column]}%`,
      };
    }
    if (iFrameWidth > 1042) {
      return {
        top: `${RowBigY[row]}%`,
        left: `${columnBigX[column] - 0.5}%`,
      };
    }
    return {
      top: `${RowBigY[row]}%`,
      left: `${columnBigX[column] - 1}%`,
    };
  }, [index, iFrameWidth]);

  return (
    <Box
      fontSize={iFrameWidth > 842 ? '1.4em' : '1.1em'}
      component="span"
      color="grey.300"
      lineHeight={1}
      position="absolute"
      sx={positions}
    >
      <Tooltip arrow title={text} placement="bottom-start">
        <HelpIcon fontSize="inherit" color="inherit" />
      </Tooltip>
    </Box>
  );
};
