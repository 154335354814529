/* eslint max-len: 0 */
import { FC, ReactNode, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import classes from './GeneralInfoTab.module.scss';
import { Props } from './types';

export const GeneralInfoTab: FC<Props> = ({
  promotion: {
    startDate,
    endDate,
    deal: { type, par1, par2, par3 },
  },
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const getContent = (): ReactNode | null => {
    switch (type) {
      case 'unit_price':
        return (
          <div className={classes.promoContainer}>
            <span className={classes.value}>
              <span className={classes.thin}>
                {t('promotionDetails.unitPricePart1')}{' '}
              </span>
              {t('promotionDetails.unitPricePart2', { x: par3 || 1 })}
              <span className={classes.thin}>
                {' '}
                {` - ${t('promotionDetails.unitPricePart3')} `}
              </span>
              {t('promotionDetails.unitPricePart4', {
                x: parseFloat(par1) * 100,
              })}
            </span>
            <span className={classes.upTo}>
              ({`${t('promotionDetails.unitPriceUpToMax1')} `}
              <span className={classes.bold}>
                {' '}
                {t('promotionDetails.unitPriceUpToMax2', { x: par2 })}
              </span>
              )
            </span>
          </div>
        );
      case 'volume_maximizer':
        return (
          <>
            {par1.split(',').map((item, index) => {
              const values = item.split(':');
              return (
                <div key={item} className={classes.promoContainer}>
                  <div className={classes.value}>
                    {t('promotionDetails.volumeMaximizer', {
                      x: values[0],
                      y: values[1],
                      z: index === 0 ? 'unit' : 'units',
                    })}
                  </div>
                </div>
              );
            })}
          </>
        );
      case 'quantity':
        return (
          <div className={classes.promoContainer}>
            <div className={classes.value}>
              {t('promotionDetails.quantity', {
                x: par2,
                y: parseFloat(par1) * 100,
              })}
            </div>
            <div className={classes.sideNote}>
              {t('promotionDetails.quantityNote', { x: par3 })}
            </div>
          </div>
        );
      case 'expense_maximizer':
        return (
          <>
            {par1.split(',').map((item) => {
              const values = item.split(':');
              return (
                <div key={item} className={classes.promoContainer}>
                  <div className={classes.value}>
                    {t('promotionDetails.expenseMaximizer', {
                      x: values[0],
                      y: values[1],
                    })}
                  </div>
                </div>
              );
            })}
          </>
        );
      case 'percentage_threshold':
        return (
          <>
            {par1.split(',').map((item) => {
              const values = item.split(':');
              return (
                <div key={item} className={classes.promoContainer}>
                  <div className={classes.value}>
                    {t('promotionDetails.percentageThreshold', {
                      x: values[0],
                      y: values[1],
                    })}
                  </div>
                </div>
              );
            })}

            <div className={classes.sideNote}>
              {t('promotionDetails.percentageThresholdNote', { x: par2 })}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getStartDate = (): string => {
    if (!startDate) {
      return t('promotionDetails.startOngoing');
    }
    const date = dayjs(startDate);
    if (date.isAfter(dayjs(), 'day')) {
      return t('promotionDetails.pending');
    }
    return dayjs(startDate).format('MMM DD, YYYY');
  };

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.dateContainer}>
          <div className={classes.label}>{t('promotionDetails.startDate')}</div>
          <div className={classes.value}>
            <span>{getStartDate()}</span>
          </div>
        </div>
        <svg
          className={classes.arrow}
          width="13"
          height="10"
          viewBox="0 0 13 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5 4C0.947715 4 0.5 4.44772 0.5 5C0.5 5.55228 0.947715 6 1.5 6V4ZM11.5 5L12.2071 5.70711C12.3946 5.51957 12.5 5.26522 12.5 5C12.5 4.73478 12.3946 4.48043 12.2071 4.29289L11.5 5ZM8.20711 0.292893C7.81658 -0.0976311 7.18342 -0.0976311 6.79289 0.292893C6.40237 0.683417 6.40237 1.31658 6.79289 1.70711L8.20711 0.292893ZM6.79289 8.29289C6.40237 8.68342 6.40237 9.31658 6.79289 9.70711C7.18342 10.0976 7.81658 10.0976 8.20711 9.70711L6.79289 8.29289ZM1.5 6H11.5V4H1.5V6ZM6.79289 1.70711L10.7929 5.70711L12.2071 4.29289L8.20711 0.292893L6.79289 1.70711ZM10.7929 4.29289L6.79289 8.29289L8.20711 9.70711L12.2071 5.70711L10.7929 4.29289Z"
            fill="#14181F"
          />
        </svg>
        <div className={classes.dateContainer}>
          <div className={classes.label}>{t('promotionDetails.endDate')}</div>
          <div className={classes.value}>
            {endDate ? (
              <span>{dayjs(endDate).format('MMM DD, YYYY')}</span>
            ) : (
              <span>
                {`${t('promotionDetails.startOngoing')} `}
                <span className={classes.grey}>
                  {t('promotionDetails.endOngoing')}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={classes.label}>
        {t('promotionDetails.promotionOffer')}:
      </div>

      {getContent()}

      <div
        className={clsx(classes.promotionNotesContainer, {
          [classes.open]: open,
        })}
      >
        <div className={classes.promotionNotes} onClick={() => setOpen(!open)}>
          <svg
            className={classes.icon}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0001 7.75H8.95007C8.53003 7.75 8.31986 7.75 8.15942 7.83175C8.0183 7.90365 7.90365 8.01839 7.83175 8.15952C7.75 8.31995 7.75 8.52994 7.75 8.94998V13M13 3.40001V7.5059C13 7.87278 13.0001 8.05621 12.9586 8.22884C12.9219 8.38189 12.861 8.52821 12.7788 8.66241C12.686 8.81379 12.5567 8.94351 12.2972 9.20294L9.20312 12.2971C8.9437 12.5565 8.81397 12.6862 8.6626 12.779C8.52839 12.8612 8.38175 12.9218 8.2287 12.9586C8.05607 13 7.87291 13 7.50603 13H3.40015C2.56007 13 2.13972 13 1.81885 12.8365C1.5366 12.6927 1.3073 12.4632 1.16349 12.181C1 11.8601 1 11.4401 1 10.6V3.40001C1 2.55993 1 2.1399 1.16349 1.81903C1.3073 1.53679 1.5366 1.3073 1.81885 1.16349C2.13972 1 2.56007 1 3.40015 1H10.6001C11.4402 1 11.86 1 12.1808 1.16349C12.4631 1.3073 12.6929 1.53679 12.8367 1.81903C13.0002 2.1399 13 2.55993 13 3.40001Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span>{t('promotionDetails.promotionNotes')}</span>

          <svg
            className={classes.chevron}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 14.3604L12 10.3604L16 14.3604"
              stroke={COLOR_PRIMARY}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className={classes.promotionNotesContent}>
          <ul>
            <li>{t('promotionDetails.receiptPurchaseNote')}</li>
            <li>
              <span>{t('promotionDetails.receiptPurchaseDate.firstPart')}</span>
              <span className={classes.red}> 14 days </span>
              <span>
                {t('promotionDetails.receiptPurchaseDate.secondPart')}{' '}
              </span>
              <span className={classes.red}>
                {t('promotionDetails.receiptPurchaseDate.thirdPart')}
              </span>
            </li>
            <li>{t('promotionDetails.keepReceiptNote')}</li>
          </ul>
        </div>
      </div>
    </>
  );
};
