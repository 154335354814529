export const amplifyAuthConfig = (() => {
  try {
    return JSON.parse(process.env.REACT_APP_AMPLIFY_AUTH_CONFIG as string);
  } catch (e) {
    throw new Error('No Cognito Auth configuration');
  }
})();

export const isMaintenance =
  (process.env.REACT_APP_MAINTENANCE_MODE || '').toUpperCase() === 'TRUE';

export const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export const amplifyApiConfig = (() => {
  try {
    return JSON.parse(process.env.REACT_APP_AMPLIFY_API_CONFIG as string);
  } catch (e) {
    throw new Error('No Cognito Api configuration');
  }
})();

export const firebaseConfig = (() => {
  try {
    return JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string);
  } catch (e) {
    throw new Error('No Firebase configuration');
  }
})();

export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const WEBAPP_API_URL = process.env.REACT_APP_WEBAPP_API_URL;
