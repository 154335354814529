import React, { FC, useState } from 'react';
import { LoggedInLayout } from '@core/LoggedInLayout';
import Box from '@mui/material/Box';
import { Restrict } from '@core/Restrict';
import { MainChart } from '@components/MainChart';
import Button from '@mui/material/Button';
import { isProduction } from '@config/system';

export const HomePage: FC = () => {
  const [showDashboard, setShowDashboard] = useState(isProduction);

  return (
    <Restrict isRedirect>
      <LoggedInLayout>
        <Box display="flex" flexDirection="column">
          {showDashboard ? (
            <MainChart />
          ) : (
            <Box p={3}>
              <Button variant="outlined" onClick={() => setShowDashboard(true)}>
                Show Dashboard
              </Button>
            </Box>
          )}
        </Box>
      </LoggedInLayout>
    </Restrict>
  );
};
