import React, { FC } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import { LinkProps } from '@core/Link/types';

export const Link: FC<LinkProps> = ({
  to,
  color = COLOR_PRIMARY,
  newWindow,
  children,
}) => (
  <LinkRouter
    to={to}
    style={{ color, textDecoration: 'none' }}
    target={newWindow ? '_blank' : undefined}
  >
    {children}
  </LinkRouter>
);
