import { FC, useEffect, useState } from 'react';
import { COLOR_PRIMARY } from '@core/Theme/theme';

export const PillText: FC<{ values: string[] }> = ({ values }) => {
  const [refWrapper, setRefWrapper] = useState<HTMLSpanElement | null>(null);
  const [unseenItems, setUnseenItems] = useState(0);

  useEffect(() => {
    const onCalculate = () => {
      if (refWrapper) {
        const wrapperWidth = 266; // refWrapper.clientWidth;

        let startPoint = 0;
        for (
          let i = 0;
          i < refWrapper.childNodes.item(0).childNodes.length;
          i++
        ) {
          if (wrapperWidth <= startPoint) {
            setUnseenItems(values.length - i);
            break;
          }

          startPoint +=
            refWrapper.children?.item(0)?.children?.item(i)?.clientWidth || 0;
        }

        if (wrapperWidth > startPoint) {
          setUnseenItems(0);
        }
      }
    };

    onCalculate();

    window.addEventListener('resize', onCalculate);
    return () => window.removeEventListener('resize', onCalculate);
  }, [refWrapper, values]);

  return (
    <span
      style={{
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <span
        ref={setRefWrapper}
        style={{
          width: 'calc(100% - 30px)',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <span
          style={{
            visibility: 'hidden',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {values.map((val, index) => (
            <span key={val} style={{ display: 'inline-block' }}>{`${val}${
              values.length - 1 !== index ? ', ' : ''
            }`}</span>
          ))}
        </span>
        <span
          style={{
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'absolute',
            left: 0,
            right: 0,
          }}
        >
          {values.map((val, index) => (
            <span key={val} style={{ display: 'inline' }}>{`${val}${
              values.length - 1 !== index ? ' ,' : ''
            }`}</span>
          ))}
        </span>
      </span>
      {unseenItems > 0 && (
        <span
          style={{
            display: 'inline-block',
            color: COLOR_PRIMARY,
            fontSize: 14,
            fontWeight: 700,
          }}
        >
          {' '}
          +{unseenItems}
        </span>
      )}
    </span>
  );
};
