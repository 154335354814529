import React, { FC, useState } from 'react';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { externalLogEvent } from '@services/log.service';
import { Props } from '@components/SocialMediaLoginBtns/types';
import { Loader } from '@core/Loader';
import linkedInLogo from '../../assets/linkedIn_logo.svg';
import googleLogo from '../../assets/google_logo.svg';

const signInWith = (provider: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Auth.federatedSignIn({ provider });
};

export const SocialMediaLoginButtons: FC<Props> = ({
  disabled,
  isSignUpPage,
}) => {
  const [isBlocked, setBlocked] = useState(false);
  const { t } = useTranslation();

  return (
    <Box mx={-2}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: { xs: 'center', md: 'right' } }}
        >
          <Button
            sx={{ width: 200 }}
            size="small"
            onClick={() => {
              setBlocked(true);
              externalLogEvent('console_login_google');
              signInWith('Google');
            }}
            variant="outlined"
            color="inherit"
            disabled={disabled}
            fullWidth
          >
            <img src={googleLogo} alt="" height={16} />
            <Box component="span" ml={2} whiteSpace="nowrap">
              {t(isSignUpPage ? 'auth.signUpGoogle' : 'auth.signInGoogle')}
            </Box>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Button
            sx={{ width: 200 }}
            size="small"
            onClick={() => {
              setBlocked(true);
              externalLogEvent('console_login_linkedin');
              signInWith('LinkedIn');
            }}
            variant="outlined"
            color="inherit"
            disabled={disabled}
            fullWidth
          >
            <img src={linkedInLogo} alt="" height={16} />
            <Box component="span" ml={2} whiteSpace="nowrap">
              {t(isSignUpPage ? 'auth.signUpLinkedIn' : 'auth.signInLinkedIn')}
            </Box>
          </Button>
        </Grid>
      </Grid>
      <Loader open={isBlocked} />
    </Box>
  );
};
