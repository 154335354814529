import dayjs from 'dayjs';
import { Client, Promotion } from '@typings/ClientsResp';
import { ChartFiltersDateType, ChartFiltersValue } from './types';

export const DAY_FORMAT = 'YYYY-MM-DD';

export const getDateFilters = (
  input: ChartFiltersDateType,
  filter: ChartFiltersValue = {
    clientKey: [],
    promotionKey: [],
    dateType: ChartFiltersDateType.AllTimes,
  }
): ChartFiltersValue => {
  const today = dayjs().format(DAY_FORMAT);
  const yesterday = dayjs().subtract(1, 'day').format(DAY_FORMAT);

  switch (input) {
    case ChartFiltersDateType.Today:
      return {
        ...filter,
        dateType: input,
        fromDate: today,
        toDate: today,
      };
    case ChartFiltersDateType.Yesterday:
      return {
        ...filter,
        dateType: input,
        fromDate: yesterday,
        toDate: yesterday,
      };
    case ChartFiltersDateType.Last7Days:
      return {
        ...filter,
        dateType: input,
        fromDate: dayjs().subtract(7, 'day').format(DAY_FORMAT),
        toDate: today,
      };
    case ChartFiltersDateType.Last30days:
      return {
        ...filter,
        dateType: input,
        fromDate: dayjs().subtract(30, 'day').format(DAY_FORMAT),
        toDate: today,
      };
    case ChartFiltersDateType.AllTimes:
      return {
        ...filter,
        dateType: input,
        fromDate: undefined,
        toDate: undefined,
      };
    default:
      return {
        ...filter,
        dateType: input,
      };
  }
};

const getDefaultQuicksightDates = (key: string) => {
  if (key === 'fromDate') {
    return '1900-01-01';
  }

  if (key === 'toDate') {
    return dayjs().add(1, 'day').format(DAY_FORMAT);
  }

  return '';
};

export const getQuicksightFilter = (input: ChartFiltersValue) =>
  Object.keys(input).reduce((output, key) => {
    if (key === 'dateType') {
      return output;
    }

    const value = input[key as keyof ChartFiltersValue];
    if (Array.isArray(value)) {
      if (value.length) {
        if (key === 'promotionKey') {
          output[key] = value.map(
            (filter) => (filter as Promotion).promotionKey
          );
        } else {
          output[key] = value.map((filter) => filter as string);
        }
      } else {
        output[key] = '[ALL]';
      }
    } else {
      output[key] = value || getDefaultQuicksightDates(key);
    }

    return output;
  }, {} as Record<string, string | string[]>);

export const getQuicksightAnswersString = (promotion?: Promotion[]) => {
  const questions = promotion?.[0]?.questions;
  const output: Record<string, string> = {};

  if (questions) {
    let index = 0;
    let openEndedIndex = 0;
    questions
      .filter(({ questionUsedFor }) => questionUsedFor === 'REBATE')
      .forEach((item) => {
        if (item.questionType === 'open_ended') {
          openEndedIndex += 1;
          output[`openEndedKey${openEndedIndex}`] = item.questionKey;
          output[`openEndedText${openEndedIndex}`] = item.questionText;
        } else {
          index += 1;
          output[`q${index}Key`] = item.questionKey;
          output[`q${index}Text`] = item.questionText;
        }
      });

    for (let i = 1; i <= 4; i++) {
      output[`q${i}Key`] = output[`q${i}Key`] || 'ALL';
      output[`q${i}Text`] = output[`q${i}Text`] || 'ALL';
    }
    for (let i = 1; i <= 2; i++) {
      output[`openEndedKey${i}`] = output[`openEndedKey${i}`] || 'ALL';
      output[`openEndedText${i}`] = output[`openEndedText${i}`] || 'ALL';
    }
  }

  return output;
};

export const getQuicksightUTMData = (
  clients: {
    [clientKey: string]: Client;
  },
  selectedClientKey: string[] = [],
  selectedPromotions: Promotion[] = []
) => {
  const utmClientKeys = Object.keys(clients).filter(
    (clientKey) =>
      clients[clientKey].isWithUtm &&
      (!selectedClientKey.length || selectedClientKey.includes(clientKey))
  );

  const demographicSurveyClientKeys = selectedPromotions
    .filter(({ optinSurveyEnabled }) => optinSurveyEnabled)
    .map(({ promotionKey }) => promotionKey);

  return {
    UTMConfig: `,${utmClientKeys.join(',')},`,
    DemographicSurveyConfig: `,${demographicSurveyClientKeys.join(',')},`,
    UTMEnabled: utmClientKeys.length ? 'YES' : 'NO',
  };
};
