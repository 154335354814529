import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDateInputStyle = makeStyles((theme: Theme) => ({
  input: {
    fontSize: 14,
    fontWeight: 500,
    height: 33,
    width: 108,
    textAlign: 'center',
    border: '1px solid',
    boxSizing: 'border-box',
    borderColor: theme.palette.grey['300'],
    borderRadius: 18,
    color: theme.palette.grey['600'],
  },
  error: {
    borderColor: theme.palette.error.main,
  },
}));
