import { forwardRef, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import IconButton from '@mui/material/IconButton';
import { PasswordFieldProps } from '@components/PasswordField/types';

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({ label, error, fullWidth, helperText, ...props }, ref) => {
    const {
      palette: { grey, error: errorColor },
    } = useTheme();
    const [showPassword, setShowPassword] = useState(false);

    return (
      <FormControl variant="outlined" error={error} fullWidth={fullWidth}>
        <InputLabel error={error}>{label}</InputLabel>
        <OutlinedInput
          autoComplete="current-password"
          fullWidth={fullWidth}
          error={error}
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{
                height: 56,
                borderLeft: `1px solid ${error ? errorColor.main : grey[400]}`,
                ml: 1,
                mr: -1,
                maxHeight: '100%',
              }}
            >
              <IconButton
                data-testid="password-show-btn"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
                size="small"
                sx={{
                  height: 56,
                  px: 1,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                {showPassword ? (
                  <VisibilityOff data-testid="password-hidden-icon" />
                ) : (
                  <Visibility data-testid="password-shown-icon" />
                )}
              </IconButton>
            </InputAdornment>
          }
          label={label}
          {...props}
          ref={ref}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
