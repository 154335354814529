/* eslint max-len: 0 */
import React, { FC, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PromotionDetailsModal } from '@components/PromotionDetailsModal';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { Props } from './types';

const menuItemStyle = {
  display: 'flex',
};

export const PromotionSpecBtn: FC<Props> = ({ promotions, clientKeys }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const { clients } = useSelector(({ login }: Store) => login);

  const closeDropdown = () => setOpen(false);

  return (
    <>
      <Button
        disabled={promotions.length !== 1}
        ref={anchorEl}
        variant="text"
        onClick={() => setOpen(true)}
      >
        Promotion specs
        <svg
          style={{ marginLeft: 12, rotate: open ? '180deg' : 'none' }}
          width="14"
          height="9"
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.999999 1.5L7 7.5L13 1.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>

      <PromotionDetailsModal
        promotion={promotions[0]}
        onClose={() => setOpenModal(false)}
        open={openModal}
        displayName={
          clientKeys.length ? clients[clientKeys[0]].displayName : ''
        }
        storeLocator={
          clientKeys.length ? clients[clientKeys[0]].storeLocator : ''
        }
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl?.current}
        open={open}
        onClose={closeDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            closeDropdown();
          }}
          sx={menuItemStyle}
        >
          <svg
            style={{ marginRight: 12 }}
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 0.25C0.930203 0.25 0.25 0.930203 0.25 1.75V12.25C0.25 13.0698 0.930203 13.75 1.75 13.75H5.52197L7 15.2559L8.47803 13.75H12.25C13.0698 13.75 13.75 13.0698 13.75 12.25V1.75C13.75 0.930203 13.0698 0.25 12.25 0.25H1.75ZM1.75 1.75H12.25V12.25H7.84814L7 13.1143L6.15186 12.25H1.75V1.75ZM6.25 3.25V4.75H7.75V3.25H6.25ZM6.25 6.25V10.75H7.75V6.25H6.25Z"
              fill="currentColor"
            />
          </svg>
          Promotion details
        </MenuItem>
        <MenuItem
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.open(promotions[0].landingPageUrl, '_blank').focus();
            closeDropdown();
          }}
          sx={menuItemStyle}
        >
          <svg
            style={{ marginRight: 12 }}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 0.25C0.930203 0.25 0.25 0.930203 0.25 1.75V12.25C0.25 13.0698 0.930203 13.75 1.75 13.75H12.25C13.0698 13.75 13.75 13.0698 13.75 12.25V7H12.25V12.25H1.75V1.75H7V0.25H1.75ZM8.5 0.25V1.75H11.1895L4.21973 8.71973L5.28027 9.78027L12.25 2.81055V5.5H13.75V0.25H8.5Z"
              fill="currentColor"
            />
          </svg>
          Landing page
        </MenuItem>
        <MenuItem
          sx={menuItemStyle}
          onClick={() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window
              .open(
                `https://app.cm.ourcart.com/${promotions[0].clientKey}?d=${promotions[0].promotionKey}`,
                '_blank'
              )
              .focus();
            closeDropdown();
          }}
        >
          <svg
            style={{ marginRight: 12 }}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 0.25C0.930203 0.25 0.25 0.930203 0.25 1.75V12.25C0.25 13.0698 0.930203 13.75 1.75 13.75H12.25C13.0698 13.75 13.75 13.0698 13.75 12.25V7H12.25V12.25H1.75V1.75H7V0.25H1.75ZM8.5 0.25V1.75H11.1895L4.21973 8.71973L5.28027 9.78027L12.25 2.81055V5.5H13.75V0.25H8.5Z"
              fill="currentColor"
            />
          </svg>
          Registration page
        </MenuItem>
      </Menu>
    </>
  );
};
