/* eslint max-len: 0 */
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import {
  clearExportErrorMessage,
  clearExportSuccessModal,
} from '@redux/ui/ui.slice';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { exportQSAction, getAllowUnload } from '@redux/ui/ui.asyncActions';
import Box from '@mui/material/Box';
import { CONTACT_MAIL } from '@config/constants';
import { useEffect } from 'react';
import { ExportErrorModal } from './ExportErrorModal';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const beforeunload = (event: any) => {
  const msg =
    'Are you sure you want to refresh the page?\n' +
    'It will stop the export job in process';
  if (getAllowUnload()) {
    event.returnValue = msg;
    return msg;
  }
  return undefined;
};

export const ExportErrorListener = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exportErrorMessage, exportSuccessModal, exportInProgress } =
    useSelector(({ ui }: Store) => ui);

  useEffect(() => {
    if (exportInProgress) {
      window.addEventListener('beforeunload', beforeunload);
    } else {
      window.removeEventListener('beforeunload', beforeunload);
    }
  }, [exportInProgress]);

  useEffect(() => {
    if (exportSuccessModal) {
      setTimeout(() => {
        dispatch(clearExportSuccessModal());
      }, 50);
    }
  }, [exportSuccessModal, dispatch]);

  const closeError = () => dispatch(clearExportErrorMessage());

  if (exportErrorMessage === 'NO_DATA_PRESENT') {
    return (
      <ExportErrorModal
        title="No Available Data"
        open
        onClose={closeError}
        actionBtns={
          <Button
            sx={{ width: '104px' }}
            variant="contained"
            onClick={closeError}
          >
            OK
          </Button>
        }
      >
        <Box maxWidth="450px">
          It appears there is no data available to export for the selected
          filters.
          <br />
          <br />
          Please broaden your filter selection and try again.
        </Box>
      </ExportErrorModal>
    );
  }

  if (exportErrorMessage) {
    return (
      <ExportErrorModal
        title=""
        open
        onClose={closeError}
        actionBtns={
          <>
            <a
              href={`mailto:${CONTACT_MAIL}`}
              style={{ textDecoration: 'none' }}
            >
              <Button variant="text">{t('export.contactSupport')}</Button>
            </a>
            <Box ml={2}>
              <Button
                variant="contained"
                onClick={() => dispatch(exportQSAction(null))}
              >
                {t('export.tryAgain')}
              </Button>
            </Box>
          </>
        }
      >
        <svg
          width="130"
          height="155"
          viewBox="0 0 130 155"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1190_25304)">
            <path
              d="M110.835 3.08203H40.4462V24.3624L19.9844 21.0885V98.0251L110.835 106.21V3.08203Z"
              fill="white"
            />
            <path
              d="M38.9395 6.4624L22.2148 18.8509L23.4955 19.1449L38.9395 21.2011V6.4624Z"
              fill="#F6FDE8"
            />
            <path
              d="M110.563 0.626465H40.1575C37.5537 1.74087 18.3914 16.3624 16.4141 18.7574V131.308C16.4141 133.032 17.8161 134.436 19.5378 134.436H110.563C112.285 134.436 113.687 133.034 113.687 131.308V3.75235C113.687 2.02853 112.285 0.626465 110.563 0.626465ZM22.2184 18.8512L38.943 6.46272V21.2015L23.4968 19.1452L22.0756 18.9556L22.2184 18.8512ZM109.174 129.923H20.925V23.3557L40.9012 26.0149C41.0013 26.0277 41.0994 26.0341 41.1995 26.0341C41.7429 26.0341 42.2713 25.8381 42.6868 25.4758C43.1769 25.0475 43.456 24.4296 43.456 23.7776V5.1395H109.174V129.923Z"
              fill="#DF1C71"
            />
            <path
              d="M51.94 42.5371L48.8013 39.6988L51.94 36.8606C52.5558 36.3045 52.6048 35.352 52.0465 34.7362C51.4904 34.1204 50.5379 34.0714 49.9221 34.6297L46.5576 37.6724L43.193 34.6297C42.5772 34.0735 41.6248 34.1204 41.0686 34.7362C40.5125 35.352 40.5594 36.3045 41.1752 36.8606L44.3138 39.6988L41.1752 42.5371C40.5594 43.0932 40.5104 44.0457 41.0686 44.6615C41.3648 44.9896 41.7739 45.1579 42.1852 45.1579C42.5453 45.1579 42.9054 45.0301 43.193 44.7701L46.5576 41.7273L49.9242 44.7701C50.2119 45.0301 50.572 45.1579 50.9321 45.1579C51.3433 45.1579 51.7524 44.9917 52.0486 44.6615C52.6048 44.0457 52.5579 43.0932 51.9421 42.5371H51.94Z"
              fill="#DF1C71"
            />
            <path
              d="M90.0493 41.3295L86.9107 38.4913L90.0493 35.6531C90.6651 35.0969 90.7141 34.1445 90.1559 33.5287C89.5997 32.9129 88.6473 32.8639 88.0315 33.4221L84.6669 36.4649L81.3024 33.4221C80.6866 32.866 79.7341 32.9129 79.178 33.5287C78.6219 34.1445 78.6687 35.0969 79.2845 35.6531L82.4232 38.4913L79.2845 41.3295C78.6687 41.8857 78.6197 42.8381 79.178 43.4539C79.4742 43.7821 79.8833 43.9504 80.2945 43.9504C80.6546 43.9504 81.0147 43.8226 81.3024 43.5626L84.6691 40.5198L88.0336 43.5626C88.3213 43.8226 88.6814 43.9504 89.0415 43.9504C89.4527 43.9504 89.8618 43.7842 90.158 43.4539C90.7141 42.8381 90.6673 41.8857 90.0515 41.3295H90.0493Z"
              fill="#DF1C71"
            />
            <path
              d="M49.0526 82.3873C49.9518 83.327 51.0087 83.783 52.2787 83.783C54.6865 83.783 55.9522 82.7219 56.796 79.9944C57.7122 77.0284 57.9338 72.5324 58.2172 66.8389C58.279 65.5945 58.3429 64.3224 58.4154 62.9971C57.8763 63.1334 57.3372 63.2847 56.796 63.4531C55.5899 63.8238 54.4457 64.2521 53.3718 64.7102C53.4208 64.853 53.4506 65.0043 53.4527 65.1641L53.7788 77.9531C53.8001 78.7842 53.1438 79.4745 52.3128 79.4958C52.3 79.4958 52.2872 79.4958 52.2744 79.4958C51.4626 79.4958 50.7914 78.8481 50.7722 78.0299L50.4675 66.0995C48.5157 67.1372 46.9027 68.209 45.6966 69.0933C45.6647 72.3491 46.1931 79.4042 49.0548 82.3895L49.0526 82.3873Z"
              fill="#F6FDE8"
            />
            <path
              d="M90.0137 68.6102C84.105 63.6688 77.8554 60.6026 71.4353 59.4989C66.2745 58.6125 61.0114 58.9875 55.7952 60.6133C46.9631 63.3663 41.3719 68.7103 41.1375 68.9362C40.5409 69.5136 40.5259 70.464 41.1013 71.0606C41.3953 71.3653 41.7895 71.5187 42.1837 71.5187C42.3776 71.5187 42.5694 71.4804 42.7526 71.4079C42.7995 72.2965 42.8805 73.3192 43.019 74.4081C43.6071 79.0149 44.9069 82.3986 46.8842 84.4633C48.3609 86.006 50.1785 86.788 52.2858 86.788C56.0318 86.788 58.4481 84.8554 59.6776 80.8793C60.7046 77.551 60.9369 72.8888 61.2288 66.9844C61.3012 65.4992 61.3779 63.9736 61.4717 62.3712C70.6682 60.9563 79.6027 63.818 88.0875 70.9157C88.7246 71.4484 89.6728 71.3653 90.2055 70.7261C90.7382 70.0889 90.6551 69.1407 90.018 68.608L90.0137 68.6102ZM58.2222 66.8373C57.941 72.5308 57.7172 77.0268 56.801 79.9929C55.9593 82.7182 54.6936 83.7814 52.2837 83.7814C51.0116 83.7814 49.9569 83.3255 49.0577 82.3858C46.1981 79.4027 45.6697 72.3455 45.6995 69.0896C46.9056 68.2053 48.5186 67.1335 50.4704 66.0958L50.7751 78.0262C50.7964 78.8444 51.4655 79.4922 52.2773 79.4922C52.2901 79.4922 52.3029 79.4922 52.3157 79.4922C53.1467 79.4708 53.803 78.7805 53.7816 77.9495L53.4556 65.1604C53.4514 65.0027 53.4237 64.8493 53.3747 64.7065C54.4486 64.2484 55.5928 63.8201 56.7989 63.4494C57.3401 63.2832 57.8792 63.1319 58.4183 62.9934C58.3458 64.3187 58.2819 65.5908 58.2201 66.8352L58.2222 66.8373Z"
              fill="#DF1C71"
            />
            <path
              d="M117.949 154.501L8.49609 143.08L14.8246 90.7241L124.277 102.147L117.949 154.501Z"
              fill="#FFD9CE"
            />
            <path
              d="M19.7844 66.6446C19.2922 65.9755 18.3503 65.8306 17.6813 66.3228C17.5193 66.4422 13.6818 69.2825 9.5949 74.177C7.18923 77.0578 5.1948 80.058 3.67128 83.0944C1.74717 86.9234 0.566706 90.8206 0.161855 94.6774C-0.692595 102.8 1.83879 110.731 7.68996 118.299C7.68144 118.423 7.67505 118.549 7.67505 118.674C7.67505 121.57 10.0232 123.918 12.9189 123.918C15.8147 123.918 18.1628 121.57 18.1628 118.674C18.1628 115.779 15.8147 113.431 12.9189 113.431C11.3976 113.431 10.0296 114.078 9.07072 115.114C4.42771 108.596 2.43755 101.852 3.15136 95.0119C3.98024 87.0853 8.33773 80.3925 11.8493 76.1735C15.7082 71.5369 19.4243 68.7775 19.4626 68.7498C20.1317 68.2576 20.2745 67.3158 19.7844 66.6467V66.6446Z"
              fill="#DF1C71"
            />
            <path
              d="M129.836 94.6725C129.432 90.8157 128.251 86.9185 126.327 83.0895C124.801 80.0531 122.809 77.0529 120.403 74.1721C116.317 69.2776 112.479 66.4373 112.317 66.318C111.648 65.8257 110.706 65.9706 110.214 66.6397C109.722 67.3088 109.867 68.2506 110.536 68.7428C110.572 68.7705 114.239 71.483 118.094 76.1004C121.624 80.328 126.01 87.0378 126.845 94.9857C127.516 101.367 125.833 107.664 121.84 113.771C121.256 113.547 120.623 113.424 119.96 113.424C117.064 113.424 114.716 115.772 114.716 118.667C114.716 121.563 117.064 123.911 119.96 123.911C122.856 123.911 125.204 121.563 125.204 118.667C125.204 117.532 124.842 116.479 124.226 115.62C128.705 108.866 130.591 101.843 129.836 94.6725Z"
              fill="#DF1C71"
            />
            <path
              d="M35.6491 128.265L28.0102 127.409C26.5975 127.251 25.3851 126.618 25.5598 125.063L27.3795 108.834C27.4924 107.827 28.5599 106.895 29.8278 107.036L37.6393 107.912C38.7345 108.035 39.5591 109.003 39.4462 110.011C39.329 111.048 38.3233 111.694 37.228 111.573L31.232 110.901L30.7121 115.542L36.1605 116.154C37.1684 116.267 38.0612 117.155 37.9461 118.193C37.8289 119.231 36.8743 119.94 35.7514 119.814L30.3029 119.203L29.7766 123.901L36.0604 124.605C37.1279 124.724 37.9866 125.638 37.8779 126.618C37.7607 127.656 36.7465 128.389 35.6512 128.265H35.6491Z"
              fill="#FF553E"
            />
            <path
              d="M51.4709 121.251L54.7544 127.631C55.5449 129.178 53.9596 130.548 52.6641 130.403C51.9439 130.322 51.3728 129.936 51.0639 129.319L47.0708 121.196L45.1104 120.976L44.371 127.578C44.2581 128.586 43.2076 129.374 42.1103 129.25C41.0449 129.131 40.2181 128.164 40.3353 127.126L42.1955 110.523C42.3212 109.398 43.4378 108.56 44.6182 108.692L49.6341 109.255C55.0527 109.862 56.4377 113.139 56.0968 116.167C55.7772 119.02 54.0406 120.723 51.4709 121.253V121.251ZM49.1078 112.903L46.0522 112.56L45.5153 117.346L48.7434 117.708C50.7315 117.932 51.8928 117.213 52.059 115.716C52.1953 114.505 51.7287 113.197 49.1057 112.903H49.1078Z"
              fill="#FF553E"
            />
            <path
              d="M68.9045 123.204L72.188 129.584C72.9786 131.131 71.3933 132.501 70.0977 132.356C69.3775 132.275 68.8065 131.889 68.4975 131.271L64.5044 123.149L62.544 122.929L61.8047 129.53C61.6917 130.538 60.6391 131.327 59.5439 131.203C58.4764 131.084 57.6517 130.116 57.7689 129.079L59.6291 112.475C59.7548 111.35 60.8714 110.513 62.0518 110.645L67.0677 111.208C72.4864 111.815 73.8714 115.092 73.5304 118.12C73.2108 120.973 71.4721 122.676 68.9045 123.206V123.204ZM66.5414 114.856L63.4859 114.512L62.9489 119.298L66.1771 119.66C68.1651 119.884 69.3264 119.166 69.4947 117.668C69.6311 116.458 69.1644 115.15 66.5414 114.856Z"
              fill="#FF553E"
            />
            <path
              d="M75.4744 125.316L76.1137 119.61C76.5718 115.516 79.0648 112.295 84.4536 112.898C89.8445 113.501 91.5598 117.195 91.1017 121.289L90.4625 126.995C89.9915 131.203 87.1043 134.295 82.1182 133.735C77.1322 133.176 75.0014 129.522 75.4744 125.314V125.316ZM80.1494 120.061L79.5102 125.768C79.2523 128.073 80.1664 129.81 82.5295 130.076C84.8925 130.34 86.1689 128.849 86.4289 126.543L87.0681 120.837C87.3302 118.502 86.5823 116.842 84.0445 116.558C81.5088 116.275 80.4115 117.728 80.1494 120.061Z"
              fill="#FF553E"
            />
            <path
              d="M104.07 127.146L107.354 133.526C108.144 135.073 106.559 136.443 105.264 136.298C104.543 136.217 103.972 135.832 103.663 135.214L99.6702 127.091L97.7099 126.872L96.9705 133.473C96.8575 134.481 95.8071 135.269 94.7118 135.145C93.6464 135.026 92.8197 134.059 92.9369 133.021L94.7971 116.418C94.9228 115.293 96.0393 114.455 97.2198 114.587L102.236 115.15C107.654 115.757 109.039 119.034 108.698 122.062C108.379 124.915 106.64 126.618 104.072 127.149L104.07 127.146ZM101.707 118.798L98.6517 118.455L98.1147 123.241L101.343 123.603C103.331 123.827 104.492 123.109 104.661 121.611C104.797 120.4 104.33 119.092 101.707 118.798Z"
              fill="#FF553E"
            />
          </g>
          <defs>
            <clipPath id="clip0_1190_25304">
              <rect
                width="130"
                height="153.873"
                fill="white"
                transform="translate(0 0.626465)"
              />
            </clipPath>
          </defs>
        </svg>

        <Box maxWidth="490px" mt={4} mb={3}>
          {t('export.an')}
          {t('export.error')}
        </Box>
      </ExportErrorModal>
    );
  }
  return null;
};
