import { Message, Observer } from './types';

let registry: Observer[] = [];

export const registerObserver = (input: Observer) => {
  registry.push(input);
};

export const removeObserver = (input: Observer) => {
  registry = registry.filter((observer) => observer !== input);
};

const showMessage = (message: Message) => {
  registry.forEach((observable) => observable(message));
};

export const showError = (message: string, title?: string) =>
  showMessage({
    variant: 'error',
    message,
    title,
  });

export const showInfo = (message: string, title?: string) =>
  showMessage({
    variant: 'info',
    message,
    title,
  });

export const showSuccess = (message: string, title?: string) =>
  showMessage({
    variant: 'success',
    message,
    title,
  });

export const showWarning = (message: string, title?: string) =>
  showMessage({
    variant: 'warning',
    message,
    title,
  });

const MESSAGE_KEY_AFTER_REFRESH = 'MESSAGE_KEY_AFTER_REFRESH';

export const getDefaultMessage = () => {
  try {
    const output = JSON.parse(
      localStorage.getItem(MESSAGE_KEY_AFTER_REFRESH) as string
    );

    return output;
  } catch (e) {
    return null;
  }
};

export const setDefaultMessage = (message: Message) => {
  localStorage.setItem(MESSAGE_KEY_AFTER_REFRESH, JSON.stringify(message));
};

export const removeDefaultMessage = () => {
  localStorage.removeItem(MESSAGE_KEY_AFTER_REFRESH);
};
