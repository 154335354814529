import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSideMenuStyle = makeStyles((theme: Theme) => ({
  accordion: {
    borderRadius: '28px 0 0 28px',
    backgroundColor: theme.palette.grey['100'],
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    '&>div:last-child': {
      overflow: 'auto',
    },
  },
  accordionTitle: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -10px 14px black',
  },
  accordionTitleContent: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
  },
}));
