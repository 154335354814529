import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { exportQSAction, setAllowUnload } from '@redux/ui/ui.asyncActions';
import { ExportFormValues } from '@components/MainChart/ExpotModal/types';
import { UiStore } from './ui.types';

export const initialState: UiStore = {
  isBlocked: false,
  menuExpanded: {},
  sideMenuHidden: false,
  exportErrorMessage: '',
  exportInProgress: false,
  exportSuccessModal: false,
  exportFormValues: null,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSideMenuAction: (state) => {
      state.sideMenuHidden = !state.sideMenuHidden;
    },
    blockUiAction: (state) => {
      state.isBlocked = true;
    },
    unblockUiAction: (state) => {
      state.isBlocked = false;
    },
    clearExportErrorMessage: (state) => {
      state.exportErrorMessage = '';
    },
    clearExportSuccessModal: (state) => {
      state.exportSuccessModal = false;
    },
    setExportFormValues: (
      state,
      { payload }: PayloadAction<ExportFormValues>
    ) => {
      state.exportFormValues = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(exportQSAction.pending, (state) => {
      setAllowUnload(true);
      state.exportInProgress = true;
      state.exportErrorMessage = '';
    });
    builder.addCase(exportQSAction.fulfilled, (state) => {
      setAllowUnload(false);
      state.exportInProgress = false;
      state.exportSuccessModal = true;
    });
    builder.addCase(exportQSAction.rejected, (state, { payload }) => {
      setAllowUnload(false);
      state.exportInProgress = false;

      switch (payload) {
        case 'TIMEOUT':
          state.exportErrorMessage = 'TIMEOUT';
          break;
        case 'ERROR_DURING_GEN':
          state.exportErrorMessage = 'ERROR_DURING_GEN';
          break;
        case 'NO_DATA_PRESENT':
          state.exportErrorMessage = 'NO_DATA_PRESENT';
          break;
        default:
          state.exportErrorMessage = 'HTTP_ERROR';
      }
    });
  },
});

export const {
  blockUiAction,
  unblockUiAction,
  toggleSideMenuAction,
  clearExportErrorMessage,
  clearExportSuccessModal,
  setExportFormValues,
} = uiSlice.actions;

export default uiSlice.reducer;
