/* eslint max-len: 0 */
import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { Props } from './types';

export const useExportModalStyle = makeStyles({
  popup: {
    maxWidth: '728px',
    borderRadius: '12px !important',
  },
});

export const ExportModalTemplate: FC<Props> = ({
  open,
  onClose,
  title,
  actionBtns,
  children,
}) => {
  const classes = useExportModalStyle();

  return (
    <Dialog open={open} classes={{ paper: classes.popup }} maxWidth={false}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          p: {
            xs: '32px 32px 24px',
            md: '56px 56px 32px',
          },
          fontWeight: 600,
          fontSize: '32px',
          lineHeight: 1.2,
        }}
      >
        {title}
        <Box
          onClick={onClose}
          position="absolute"
          top={24}
          right={24}
          lineHeight={0}
        >
          <svg
            style={{ cursor: 'pointer' }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.43974 0.000463748C1.22763 0.000726393 1.02042 0.0642131 0.844571 0.182814C0.668721 0.301414 0.532218 0.469744 0.452499 0.666301C0.372781 0.862857 0.353467 1.07872 0.397024 1.2863C0.440581 1.49389 0.545031 1.68378 0.697033 1.83171L3.6762 4.81088L0.697033 7.79005C0.594661 7.88834 0.512931 8.00606 0.456627 8.13633C0.400323 8.2666 0.370578 8.4068 0.369135 8.54871C0.367691 8.69062 0.394578 8.83139 0.448219 8.96278C0.501861 9.09417 0.58118 9.21353 0.681531 9.31388C0.781881 9.41423 0.901246 9.49355 1.03264 9.54719C1.16402 9.60084 1.3048 9.62772 1.44671 9.62628C1.58862 9.62484 1.72881 9.59509 1.85908 9.53879C1.98935 9.48248 2.10708 9.40075 2.20537 9.29838L5.18453 6.31921L8.1637 9.29838C8.26199 9.40075 8.37971 9.48249 8.50999 9.53879C8.64026 9.5951 8.78045 9.62484 8.92236 9.62629C9.06427 9.62773 9.20505 9.60084 9.33644 9.5472C9.46782 9.49356 9.58719 9.41424 9.68754 9.31389C9.78789 9.21354 9.86721 9.09417 9.92085 8.96278C9.9745 8.83139 10.0014 8.69062 9.99994 8.54871C9.99849 8.4068 9.96875 8.2666 9.91244 8.13633C9.85614 8.00606 9.77441 7.88834 9.67203 7.79005L6.69287 4.81088L9.67203 1.83171C9.82617 1.68189 9.93145 1.48899 9.97407 1.2783C10.0167 1.06761 9.99467 0.848965 9.9109 0.651007C9.82712 0.453049 9.68549 0.285017 9.50458 0.168933C9.32366 0.052849 9.1119 -0.00587265 8.89703 0.000463748C8.61992 0.00872095 8.3569 0.124539 8.1637 0.32338L5.18453 3.30255L2.20537 0.32338C2.10584 0.221074 1.9868 0.13978 1.85529 0.0843126C1.72378 0.0288453 1.58247 0.000332736 1.43974 0.000463748Z"
              fill="#9C9C9C"
            />
          </svg>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: 'center',
          p: {
            xs: '0 32px 32px',
            md: '0 56px 56px',
          },
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          p: {
            xs: '0 32px 32px',
            md: '0 56px 56px',
          },
        }}
      >
        {actionBtns}
      </DialogActions>
    </Dialog>
  );
};
