import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RetoolContainmentTest } from '../RetoolContainmentTest';
import { ViewProps } from '../types';

export const Initial: FC<ViewProps> = ({ onDetected }) => {
  const [searchParams] = useSearchParams();

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={3} />
        <Grid item xs={9}>
          Desktop
        </Grid>
        <Grid item xs={3}>
          Headline text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinIntroheadlineText')}
            width={424}
            height={86.4}
            lineHeight={43.2}
            fontWeight={800}
            fontSize={36}
          />
        </Grid>
        <Grid item xs={3}>
          Explanatory text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinIntroExplanatoryText')}
            width={394}
            height={101}
            lineHeight="120%"
            fontWeight={500}
            fontSize={28}
          />
        </Grid>
        <Grid item xs={3}>
          CTA button text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinIntroCtaButtonText')}
            width={394}
            height={24}
            lineHeight="120%"
            fontWeight={700}
            fontSize={16}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9}>
          Mobile
        </Grid>
        <Grid item xs={3}>
          Headline text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinIntroheadlineText')}
            width={312}
            height={68}
            lineHeight={33.6}
            fontWeight={800}
            fontSize={28}
          />
        </Grid>
        <Grid item xs={3}>
          Explanatory text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinIntroExplanatoryText')}
            width={312}
            height={80}
            lineHeight="120%"
            fontWeight={500}
            fontSize={22}
          />
        </Grid>
        <Grid item xs={3}>
          CTA button text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinIntroCtaButtonText')}
            width={310}
            height={24}
            lineHeight="120%"
            fontWeight={700}
            fontSize={16}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
