import { FC, useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

let initializatd = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let res: any;
const nav = new Promise<NavigateFunction>((resolve) => {
  res = resolve;
});
export const getNavigate = () => nav;
export const LocationProvider: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!initializatd) {
      res(navigate);
      initializatd = true;
    }
  }, [navigate]);

  return null;
};
