import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { Loader } from '@core/Loader';
import { Messenger } from '@core/Messenger';
import { TopBar } from './TopBar';
import { SideMenu } from './SideMenu';
import { LocationProvider } from '../LocationProvider';

export const LoggedInLayout: FC = ({ children }) => {
  const isBlocked = useSelector((store: Store) => store.ui.isBlocked);

  return (
    <>
      <Box
        display="grid"
        gridTemplateRows="auto 1fr"
        gridTemplateColumns={{
          xs: 'auto 1fr',
        }}
        minHeight="100vh"
      >
        <Box gridRow={{ xs: '1 / span 2' }}>
          <SideMenu />
        </Box>
        <Box
          bgcolor="var(--console-bg-color)"
          gridColumn={{
            xs: '2',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <TopBar />
          <Messenger />
        </Box>

        {children}
      </Box>
      <Loader open={isBlocked} />
      <LocationProvider />
    </>
  );
};
