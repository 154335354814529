import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Store } from '@typings/Store';
import { Props } from './types';

export const Restrict: FC<Props> = ({
  groupsRequired = [],
  children,
  isRedirect,
}) => {
  const { groups, isLoggedIn } = useSelector((store: Store) => store.login);
  const isInGroup = groupsRequired?.length
    ? groupsRequired.some((group) => groups.includes(group))
    : true;

  if (isLoggedIn && isInGroup) {
    return children as ReactElement;
  }

  return isRedirect ? <Navigate to="/sign-in" /> : null;
};
