/* eslint max-len: 0 */
import React, { FC } from 'react';
import { LoggedInLayout } from '@core/LoggedInLayout';
import Box from '@mui/material/Box';
import { Restrict } from '@core/Restrict';
import classes from './ConnectionLostPage.module.scss';

export const ConnectionLostPage: FC = () => (
  <Restrict isRedirect>
    <LoggedInLayout>
      <Box
        p={2}
        boxSizing="border-box"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        display="flex"
        alignItems="center"
        height="100%"
      >
        <Box className={classes.title}>Connection lost</Box>
        <Box className={classes.subtitle}>
          Please check your internet connection and try again.
        </Box>
        <svg
          width="632"
          height="196"
          viewBox="0 0 632 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1144_23008)">
            <path
              d="M6.13623 90.0653C16.1304 86.9922 24.4911 80.9132 35.5881 75.171C64.2423 60.3437 96.7204 43.5387 128.279 63.0268C133.378 66.1759 136.968 69.7719 139.251 74.0213C141.369 77.963 142.294 82.4516 141.999 87.3632C141.349 98.1918 135.937 107.801 131.359 117.338C128.719 122.837 126.746 129.09 128.307 134.986C130.115 141.815 136.418 146.718 143.164 148.815C157.753 153.349 174.912 151.434 188.844 146.357"
              stroke="#D1D3D4"
              strokeWidth="5.11436"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M630.829 128.477C613.863 125.336 591.609 120.991 589.313 99.7736C588.171 89.2173 593.566 81.3972 598.326 74.499C600.791 70.9257 603.12 67.5513 604.588 63.9223C607.736 56.1453 607.844 48.3506 604.883 42.5387C602.038 36.9522 596.619 33.4398 592.575 31.4759C568.96 20.0155 542.771 27.2265 519.7 36.3039C505.294 41.9714 491 47.9403 476.656 53.7661"
              stroke="#D1D3D4"
              strokeWidth="5.11436"
              strokeMiterlimit="10"
            />
            <path
              d="M274.685 144.82L245.177 155.075C239.269 157.129 232.815 154.004 230.761 148.096L219.519 115.749C217.465 109.841 220.59 103.387 226.498 101.333L256.006 91.0784L274.683 144.82H274.685Z"
              fill="#DF1C71"
            />
            <path
              d="M234.735 152.028L218.398 157.707C214.76 158.971 210.737 156.907 209.413 153.097L200.236 126.693C198.912 122.883 200.787 118.769 204.425 117.504L220.763 111.825"
              fill="#DF1C71"
            />
            <path
              d="M260.111 85.3664L258.964 85.7652C256.598 86.5883 255.345 89.1739 256.168 91.5404L274.525 144.359C275.348 146.725 277.933 147.978 280.3 147.155L281.447 146.756C283.813 145.933 285.066 143.347 284.243 140.981L265.887 88.1622C265.063 85.7956 262.478 84.5434 260.111 85.3664Z"
              fill="#DF1C71"
            />
            <path
              d="M216.786 125.196C216.588 124.63 215.971 124.331 215.405 124.528C214.839 124.725 214.54 125.342 214.736 125.908L221.166 144.41L216.786 125.196ZM216.786 125.196L223.215 143.698L216.786 125.196ZM222.547 145.079C223.113 144.881 223.412 144.264 223.215 143.698L222.547 145.079ZM222.547 145.079C221.981 145.275 221.364 144.976 221.166 144.41L222.547 145.079Z"
              fill="white"
              stroke="#828282"
              strokeWidth="0.12662"
            />
            <path
              d="M211.37 127.081C211.173 126.515 210.555 126.216 209.99 126.412C209.424 126.609 209.125 127.227 209.321 127.793L215.751 146.294L211.37 127.081ZM211.37 127.081L217.8 145.582L211.37 127.081ZM217.131 146.963C217.697 146.766 217.996 146.148 217.8 145.582L217.131 146.963ZM217.131 146.963C216.565 147.159 215.948 146.86 215.751 146.294L217.131 146.963Z"
              fill="white"
              stroke="#828282"
              strokeWidth="0.12662"
            />
            <path
              d="M192.306 136.161L204.423 131.951L209.523 146.629L197.407 150.838C193.448 152.214 189.118 150.117 187.742 146.158L187.626 145.824C186.251 141.865 188.348 137.536 192.306 136.161Z"
              fill="#DF1C71"
            />
            <path
              d="M425.489 44.8242L441.939 39.282C446.048 37.8981 450.501 40.1063 451.887 44.2152L462.705 76.3236C464.089 80.4324 461.881 84.8856 457.772 86.2708L441.321 91.813"
              fill="#DF1C71"
            />
            <path
              d="M472.58 63.9967L458.102 68.8741L453.182 54.2723L467.659 49.3949C471.692 48.0362 476.062 50.204 477.421 54.2356C478.779 58.2684 476.611 62.6381 472.58 63.9967Z"
              fill="#DF1C71"
            />
            <path
              d="M354.009 73.6551L375.282 66.488L377.585 73.3251L356.313 80.4923C354.568 81.0802 352.674 80.1405 352.086 78.3958L351.912 77.881C351.325 76.1363 352.264 74.2418 354.009 73.6539L354.009 73.6551Z"
              fill="#333333"
            />
            <path
              d="M364.475 104.706L385.747 97.539L388.051 104.376L366.778 111.543C365.034 112.131 363.139 111.192 362.551 109.447L362.378 108.932C361.79 107.187 362.73 105.293 364.474 104.705L364.475 104.706Z"
              fill="#333333"
            />
            <path
              d="M433.637 97.2086L388.295 112.485L370.28 59.02L415.624 43.7419"
              fill="#DF1C71"
            />
            <path
              d="M401.445 74.6697L377.441 82.7571L380.351 91.3941L404.355 83.3068L401.445 74.6697Z"
              fill="white"
            />
            <path
              d="M439.073 99.2119L439.155 99.184C441.829 98.2825 443.267 95.3842 442.366 92.7099L425.399 42.3492C424.497 39.675 421.599 38.2366 418.925 39.1381L418.842 39.166C416.168 40.0675 414.73 42.9659 415.631 45.6401L432.598 96.0008C433.5 98.675 436.398 100.113 439.073 99.2119Z"
              fill="#DF1C71"
            />
            <path
              d="M439.844 53.6806C440.444 53.4768 441.096 53.7984 441.3 54.3986L447.602 72.9447C447.806 73.5448 447.484 74.1969 446.884 74.4008C446.284 74.6047 445.631 74.283 445.428 73.6828L439.126 55.1368C438.922 54.5366 439.243 53.8845 439.844 53.6806Z"
              fill="white"
            />
            <path
              d="M434.414 55.5271C435.014 55.3232 435.666 55.6448 435.87 56.245L442.172 74.7911C442.376 75.3913 442.054 76.0434 441.454 76.2472C440.854 76.4511 440.202 76.1295 439.998 75.5293L433.696 56.9832C433.492 56.383 433.814 55.7309 434.414 55.5271Z"
              fill="white"
            />
            <path
              d="M314.526 1.89966L312.271 37.4812"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M346.816 156.731L360.49 187.652"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M265.396 19.7529L285.656 44.9504"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M369.322 137.41L395.181 150.805"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M317.134 193.766L318.48 163.312"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M276.246 184.146L291.74 159.914"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M345.803 45.5839L364.923 24.5649"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M253.15 100.242L230.991 107.839C230.991 107.839 227.446 109.612 228.206 112.777"
              stroke="white"
              strokeWidth="2.53241"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M263.246 65.4626L234.757 54.9531"
              stroke="#D1D3D4"
              strokeWidth="3.79861"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1144_23008">
              <rect
                width="632.001"
                height="195.665"
                fill="white"
                transform="translate(-0.000488281)"
              />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </LoggedInLayout>
  </Restrict>
);
