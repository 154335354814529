import { FC } from 'react';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import sideImage from './assets/side.svg';
import backgroundImage from './assets/background.svg';
import { LocationProvider } from '../LocationProvider';

export const DefaultLayout: FC = ({ children }) => (
  <Box
    sx={{
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: '100%',
      backgroundRepeat: 'repeat',
    }}
  >
    <Paper
      elevation={2}
      sx={{
        m: 2,
        maxWidth: { xs: 'auto', md: '1100px' },
        borderTopLeftRadius: { xs: 'initial', md: '24px' },
        borderBottomLeftRadius: { xs: 'initial', md: '24px' },
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
        }}
      >
        <Box
          sx={{
            width: '50%',
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <img alt="Logo" src={sideImage} height="100%" />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            background: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box p={4}>
            <Box maxWidth={440} mx="auto">
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
    <LocationProvider />
  </Box>
);
