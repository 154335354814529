import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { store } from '@redux/index';
import { firebaseConfig } from '@config/system';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const externalLogEvent = (eventName: string) => {
  logEvent(analytics, eventName, { user_email: store.getState().login.email });
};
