import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { Promotion } from '@typings/ClientsResp';
import { ChartFiltersDefaultValues } from '../types';

const areValuesDiffrent = (a: Promotion[], b: Promotion[]) => {
  if (a.length !== b.length) {
    return true;
  }

  const biggerObject = a.length > b.length ? a : b;
  let diffrences = biggerObject.length;

  for (let i = 0; i < biggerObject.length; i++) {
    if (b.some((item) => item !== biggerObject[i])) {
      return true;
    }
    diffrences -= 1;
  }

  return !diffrences;
};

export const usePromotionOptions = (
  onChange: (val: Promotion[]) => void,
  value: ChartFiltersDefaultValues,
  isExport?: boolean
) => {
  const { clients, exportPermissions } = useSelector(
    ({ login }: Store) => login
  );
  const [promotionOptions, setPromotionOptions] = useState<Promotion[]>([]);

  useEffect(() => {
    const promotionOptionsTmp = (
      value.clientKey.length ? value.clientKey : Object.keys(clients)
    ).reduce<Promotion[]>((output, clientKey) => {
      if (
        isExport &&
        !exportPermissions.find((item) => item.clientKey === clientKey)
          ?.exportsAllowed
      ) {
        return output;
      }

      const promotionsTmp = clients[clientKey].promotions.map(
        (item: Promotion) => ({
          ...item,
          clientKey,
        })
      );

      return [...output, ...promotionsTmp];
    }, []);

    const getPromotionKey = () =>
      value.promotionKey.length
        ? value.promotionKey.filter((promo) =>
            promotionOptionsTmp.some(
              (item) => item.promotionKey === promo.promotionKey
            )
          )
        : [];

    const newValue =
      promotionOptionsTmp.length === 1
        ? promotionOptionsTmp
        : getPromotionKey();

    if (areValuesDiffrent(newValue, value.promotionKey)) {
      onChange(newValue);
    }

    setPromotionOptions(
      promotionOptionsTmp
        .slice()
        .sort((a, b) => (a.promotionKey > b.promotionKey ? 1 : -1))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, value.clientKey]);

  return promotionOptions;
};
