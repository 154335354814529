import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const OpsSnapshotPreviewPage: FC = () => {
  const [zoomedIn, setZoomedIn] = useState(false);
  const [wrapperStyles, setWrapperStyles] = useState<Record<string, number>>(
    {}
  );
  const [searchParams] = useSearchParams();
  const url = searchParams.get('url');
  const rotate = searchParams.get('rotate');

  const isOnSide = Boolean(parseInt(rotate || '0', 10) % 180);

  useEffect(() => {
    const onResize = () => {
      setWrapperStyles(
        zoomedIn || isOnSide
          ? {}
          : {
              minHeight: isOnSide ? window.innerWidth : window.innerHeight,
              minWidth: isOnSide ? window.innerHeight : window.innerWidth,
            }
      );
    };

    onResize();
    window.addEventListener('resize', onResize);
  }, [zoomedIn, isOnSide]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={() => setZoomedIn(!zoomedIn)}
      style={{
        cursor: zoomedIn ? 'zoom-out' : 'zoom-in',
        background: 'black',
        display: zoomedIn ? 'block' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...wrapperStyles,
      }}
    >
      <img
        src={url || ''}
        alt=""
        style={{
          rotate: `${rotate}deg`,
          maxHeight: zoomedIn ? 'none' : window.innerHeight,
          maxWidth: zoomedIn ? 'none' : window.innerWidth,
        }}
      />
    </div>
  );
};
