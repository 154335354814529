import { FC } from 'react';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Props } from './types';
import {
  DAY_CONTAINER_SIZE,
  useCalendarContainerStyle,
} from './CalendarContainer.style';

const calendarWidth = 7 * DAY_CONTAINER_SIZE;

export const CalendarContainer: FC<Props> = ({
  onClick,
  fromDate,
  toDate,
  month,
  isAllTime,
  disabled,
  disabledFuture,
}) => {
  const classes = useCalendarContainerStyle();

  return (
    <Box>
      <Box width={calendarWidth}>
        <Box display="flex">
          {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((dayOfWeek, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={clsx(classes.dayOfWeek, {
                [classes.disabled]: disabled,
              })}
            >
              {dayOfWeek}
            </Box>
          ))}
        </Box>
        <Box display="flex" flexWrap="wrap">
          {new Array(month.daysInMonth()).fill(0).map((_, index) => {
            const day = month.add(index, 'day');

            const isRangePresent = fromDate && toDate;
            const isFirst = fromDate && day.isSame(fromDate, 'day');
            const isLast = toDate && day.isSame(toDate, 'day');
            const isBetween =
              isRangePresent &&
              day.isBefore(toDate, 'day') &&
              day.isAfter(fromDate, 'day');

            const isDayDisabled =
              disabled || (disabledFuture && day.isAfter(dayjs(), 'day'));

            return (
              <Box
                key={day.format()}
                ml={`${index ? 0 : day.day() * DAY_CONTAINER_SIZE}px`}
                onClick={() => {
                  if (!isDayDisabled) {
                    onClick(day);
                  }
                }}
                className={clsx(classes.day, {
                  [classes.availableDay]:
                    !isAllTime && !isBetween && !isFirst && !isLast,
                  [classes.selected]: !isAllTime && (isFirst || isLast),
                  [classes.firstSelected]:
                    !isAllTime && isRangePresent && isFirst,
                  [classes.lastSelected]:
                    !isAllTime && isRangePresent && isLast,
                  [classes.dayInBetween]: isAllTime || isBetween,
                  [classes.disabled]: isDayDisabled,
                  disabled: isDayDisabled,
                })}
              >
                {day.format('D')}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
