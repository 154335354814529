import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Link } from '@core/Link';
import { useDispatch } from 'react-redux';
import { PasswordField } from '@components/PasswordField';
import Typography from '@mui/material/Typography';
import { SocialMediaLoginButtons } from '@components/SocialMediaLoginBtns';
import { useTranslation } from 'react-i18next';
import { setCognitoError } from '@services/cognito.service';
import { CognitoException } from '@typings/CognitoException';
import { fetchInitialDataAction } from '@redux/login/login.asyncActions';
import Box from '@mui/material/Box';
import { AuthTitle } from '@components/AuthTitle';
import { externalLogEvent } from '@services/log.service';
import { Messenger, toast } from '@core/Messenger';
import { SignInFormProps, SignInValues } from './types';
import { signInResolver } from './SignInForm.validation';

export const SignInForm: FC<SignInFormProps> = ({ onPasswordRequired }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SignInValues>({
    resolver: signInResolver,
    mode: 'onTouched',
  });

  const onSubmit = async (data: SignInValues) => {
    try {
      const signInUser = await Auth.signIn({
        username: data.email,
        password: data.password,
      });

      externalLogEvent('console_login_password');

      if (signInUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        onPasswordRequired(signInUser);
        return;
      }

      await dispatch(fetchInitialDataAction());

      navigate('/');
    } catch (e) {
      const error = e as CognitoException;
      if (setCognitoError(setError, error, 'email')) {
        return;
      }

      if (error.code === 'UserNotConfirmedException') {
        navigate(`/confirm-sign-up/${data.email}`);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AuthTitle
            title={`${t('auth.logInToOurcart')}!`}
            subtitle={
              <>
                {`${t('auth.noAccount')}? `}
                <Link to="/sign-up">{t('auth.signUp')}</Link>
              </>
            }
          />
          <Box mt="12px" mb="12px">
            <Messenger />
          </Box>
          <Box mt={1}>
            <SocialMediaLoginButtons disabled={isSubmitting} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center', fontWeight: 500, my: 1 }}
          >
            {t('auth.orSignInEmail')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={isSubmitting}
            label={`${t('auth.workEmail')} *`}
            fullWidth
            variant="outlined"
            inputProps={{ 'data-testid': 'email-input' }}
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            disabled={isSubmitting}
            label={`${t('common.password')} *`}
            fullWidth
            inputProps={{ 'data-testid': 'password-input' }}
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={324} mx="auto">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  data-testid="sign-in-submit"
                  fullWidth
                >
                  {t('auth.logIn')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  disabled={isSubmitting}
                  onClick={() => navigate('/forgot-password')}
                  fullWidth
                >
                  {`${t('auth.passwordReminder')}?`}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
