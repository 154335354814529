import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EMAIL_REGEX } from '@config/constants';
import i18n from '@core/i18n/i18n';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(i18n.t('validation.required'))
    .matches(EMAIL_REGEX, i18n.t('validation.email')),
  password: Yup.string().required(i18n.t('validation.required')),
});

export const signInResolver = yupResolver(validationSchema);
