import { Promotion } from '@typings/ClientsResp';

export interface DashboardEmbedUrlResp {
  dashboardEmbedUrl: string;
}

export enum ChartFiltersDateType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last7Days',
  Last30days = 'Last30days',
  AllTimes = 'AllTimes',
  Custom = 'Custom',
}

export interface ChartFiltersDefaultValues {
  promotionKey: Promotion[];
  clientKey: string[];
}

export interface ChartFiltersValue extends ChartFiltersDefaultValues {
  dateType: ChartFiltersDateType;
  fromDate?: string;
  toDate?: string;
}
