import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const DAY_CONTAINER_SIZE = 34;

export const useCalendarContainerStyle = makeStyles((theme: Theme) => ({
  dayOfWeek: {
    fontSize: 12,
    fontWeight: 500,
    width: DAY_CONTAINER_SIZE,
    height: DAY_CONTAINER_SIZE,
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  availableDay: {
    '&:hover:not(.disabled)': {
      borderRadius: '50%',
    },
  },
  day: {
    width: DAY_CONTAINER_SIZE,
    height: DAY_CONTAINER_SIZE,
    minWidth: DAY_CONTAINER_SIZE,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey['600'],
    userSelect: 'none',

    '&:hover:not(.disabled)': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
  dayInBetween: {
    color: theme.palette.grey['500'],
    backgroundColor: theme.palette.grey['100'],

    '&:hover:not(.disabled)': {
      backgroundColor: theme.palette.grey['200'],
    },
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,

    '&:hover:not(.disabled)': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  firstSelected: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  lastSelected: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  disabled: {
    opacity: 0.65,
    cursor: 'default',
  },
}));
