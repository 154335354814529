import i18n from '@core/i18n/i18n';
import { CognitoException } from '@typings/CognitoException';
import { Path, UseFormSetError } from 'react-hook-form';

export const setCognitoError = <T = FormData>(
  setError: UseFormSetError<T>,
  { code, message }: CognitoException,
  primaryFieldName: Path<T>,
  secondaryFieldName?: Path<T>
) => {
  if (
    [
      'UsernameExistsException',
      'NotAuthorizedException',
      'UserNotFoundException',
      'CodeMismatchException',
      'ExpiredCodeException',
      'UserNotFoundException',
    ].includes(code)
  ) {
    setError(primaryFieldName, {
      type: code,
      message: i18n.t(`cognito.${code}`, message),
    });

    return true;
  }

  if (secondaryFieldName && ['InvalidPasswordException'].includes(code)) {
    setError(secondaryFieldName, {
      type: code,
      message: i18n.t(`cognito.${code}`, message),
    });

    return true;
  }

  return false;
};
