import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { fetchInitialDataAction } from '@redux/login/login.asyncActions';
import { useDispatch } from 'react-redux';
import { PasswordField } from '@components/PasswordField';
import { CognitoException } from '@typings/CognitoException';
import { setCognitoError } from '@services/cognito.service';
import { useTranslation } from 'react-i18next';
import { AuthTitle } from '@components/AuthTitle';
import Box from '@mui/material/Box';
import { Messenger, toast } from '@core/Messenger';
import { CompleteInviteFormProps, CompleteInviteFormValues } from './types';
import { completeInviteFormResolver } from './CompleteInviteForm.validation';

export const CompleteInviteForm: FC<CompleteInviteFormProps> = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<CompleteInviteFormValues>({
    mode: 'onTouched',
    resolver: completeInviteFormResolver,
  });

  const onSubmit = async ({ password }: CompleteInviteFormValues) => {
    try {
      await Auth.completeNewPassword(user, password);
      await dispatch(fetchInitialDataAction());

      navigate('/');
    } catch (e) {
      const error = e as CognitoException;
      if (!setCognitoError(setError, error, 'password')) {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AuthTitle
            title={t('auth.setPassword')}
            subtitle={`${t('auth.invitePasswordInfo')}.`}
          />
          <Box mt="12px" mb="12px">
            <Messenger />
          </Box>
          <PasswordField
            label={`${t('common.password')} *`}
            fullWidth
            disabled={isSubmitting}
            inputProps={{ 'data-testid': 'change-password-input' }}
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register('password')}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordField
            label={`${t('common.confirmPassword')} *`}
            fullWidth
            disabled={isSubmitting}
            inputProps={{ 'data-testid': 'change-password-confirm-input' }}
            error={!!errors.passwordConfirm}
            helperText={errors.passwordConfirm?.message}
            {...register('passwordConfirm')}
          />

          <Box mt={1} ml={3}>
            <Typography variant="caption">
              {`${t('auth.newPasswordShouldContain')}:`}
              <Box ml={1}>
                <li>{t('auth.passwordCondition1')}</li>
                <li>{t('auth.passwordCondition2')}</li>
                <li>{t('auth.passwordCondition3')}</li>
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={324} mx="auto">
            <Button
              data-testid="complete-invite-submit"
              size="large"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            >
              {t('auth.setNewPassword')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
