import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialDataActionPayload, LoginStore } from '@redux/login/login.types';
import { fetchInitialDataAction } from '@redux/login/login.asyncActions';
import md5 from 'md5';

export const initialState: LoginStore = {
  isLoading: true,
  isLoggedIn: false,
  activeClientKey: [],
  groups: [],
  authSource: '',
  email: '',
  hashedEmail: '',
  name: '',
  surname: '',
  accessToken: '',
  idToken: '',
  refreshToken: '',
  clients: {},
  tempPassword: '',
  exportPermissions: [],
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setTempPassword: (store, { payload }) => {
      store.tempPassword = payload;
    },
    setActiveClientKey: (store, { payload }) => {
      store.activeClientKey = payload;
    },
    loggedOutAction: () => ({
      ...initialState,
      isLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchInitialDataAction.fulfilled,
      (
        state,
        {
          payload: { user, clients, exportPermissions },
        }: PayloadAction<InitialDataActionPayload>
      ) => ({
        ...state,
        exportPermissions,
        tempPassword: '',
        isLoading: false,
        isLoggedIn: true,
        groups: user.signInUserSession.idToken.payload['cognito:groups'],
        authSource: user.attributes.identities,
        email: user.attributes.email,
        hashedEmail: md5(
          `${user.attributes.email}$2a$10$CwTycUXWue0Thq9StjUM0u`
        ),
        name: user.attributes.given_name,
        surname: user.attributes.family_name,
        accessToken: user.signInUserSession.accessToken.jwtToken,
        refreshToken: user.signInUserSession.refreshToken.token,
        idToken: user.signInUserSession.idToken.jwtToken,
        activeClientKey:
          clients.clients.length === 1 ? [clients.clients[0].clientKey] : [],
        clients: clients.clients.reduce<LoginStore['clients']>(
          (output, item) => ({
            ...output,
            [item.clientKey as string]: item,
          }),
          {}
        ),
      })
    );
    builder.addCase(fetchInitialDataAction.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { setTempPassword, loggedOutAction, setActiveClientKey } =
  loginSlice.actions;

export default loginSlice.reducer;
