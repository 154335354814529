import { FC } from 'react';
import { Typography } from '@mui/material';
import {
  COLOR_TRANSPARENT_DARK,
  COLOR_TRANSPARENT_DARKEST,
} from '@core/Theme/theme';
import { MenuButtonProps } from './types';

export const MenuButton: FC<MenuButtonProps> = ({
  children,
  onClick,
  icon,
}) => (
  <Typography
    className="pointer"
    alignItems="center"
    display="flex"
    justifyContent="center"
    flexDirection="row"
    height="36px"
    variant="body2"
    onClick={onClick}
    color="white"
    fontSize="14px"
    fontWeight={500}
    lineHeight={1.2}
    sx={{
      textDecoration: 'none',
      transition: '.3s',
      bgcolor: COLOR_TRANSPARENT_DARK,
      '&:hover': {
        bgcolor: COLOR_TRANSPARENT_DARKEST,
      },
    }}
    borderRadius="4px"
    whiteSpace="nowrap"
    my="2px"
  >
    {icon && (
      <img
        data-testid="menu-btn-icon"
        src={icon}
        alt="help"
        height="15px"
        width="15px"
        style={{ marginRight: 6 }}
      />
    )}
    {children}
  </Typography>
);
