import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RetoolContainmentTest } from '../RetoolContainmentTest';
import { ViewProps } from '../types';

export const Optin: FC<ViewProps> = ({ onDetected }) => {
  const [searchParams] = useSearchParams();

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={3} />
        <Grid item xs={9}>
          Desktop
        </Grid>
        <Grid item xs={3}>
          Opt-in form header
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinFormHeader')}
            width={394}
            height={43.2}
            lineHeight={43.2}
            fontWeight={800}
            fontSize={36}
          />
        </Grid>
        <Grid item xs={3}>
          Opt-in form text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinFormText')}
            width={394}
            height={24}
            lineHeight="150%"
            fontWeight={400}
            fontSize={16}
          />
        </Grid>

        <Grid item xs={3} />
        <Grid item xs={9}>
          Mobile
        </Grid>
        <Grid item xs={3}>
          Opt-in form header
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinFormHeader')}
            width={312}
            height={33.6}
            lineHeight={33.6}
            fontWeight={800}
            fontSize={28}
          />
        </Grid>
        <Grid item xs={3}>
          Opt-in form text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('optinFormText')}
            width={312}
            height={20}
            lineHeight="150%"
            fontWeight={400}
            fontSize={13}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
