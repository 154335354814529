import {
  showError,
  showInfo,
  showSuccess,
  showWarning,
} from './Messenger.service';

export const toast = {
  error: showError,
  info: showInfo,
  success: showSuccess,
  warning: showWarning,
};
export { Messenger } from './Messenger';
export { ErrorMessenger } from './ErrorMessenger';
