import { API, graphqlOperation } from 'aws-amplify';
import axios, { RawAxiosRequestConfig } from 'axios';
import { DocumentNode } from 'graphql/language/ast';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { WEBAPP_API_URL } from '@config/system';
import { getNavigate } from '@core/LocationProvider';

const requestCheck = (status?: number) => {
  if (!window.navigator.onLine) {
    getNavigate().then((nav) => nav('/connection-lost'));
  }
  if (status === 500) {
    getNavigate().then((nav) => nav('/server-error'));
  }
};

export const graphqlRequest = <T = unknown>(
  query: string | DocumentNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables?: Record<string, any>,
  additionalHeaders?: Record<string, string>
) => {
  requestCheck();

  return API.graphql(
    graphqlOperation(query, variables),
    additionalHeaders
  ) as Promise<GraphQLResult<T>>;
};

export const getRequest = async (
  path: string,
  headers?: RawAxiosRequestConfig
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  requestCheck();
  const { data, status } = await axios.get(`${WEBAPP_API_URL}${path}`, headers);
  requestCheck(status);
  return data;
};
