import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Initializer } from '@core/Initializer';
import { Router } from '@pages/Router';
import { ThemeProvider } from '@mui/material';
import '@core/i18n/i18n';
import { theme } from '@core/Theme/theme';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { LocalizationProvider } from '@mui/lab';
import { ErrorMessenger } from '@core/Messenger';
import { ExportErrorListener } from '@core/ExportErrorListener';
import { MaintenancePage } from '@pages/MaintenancePage';
import { isMaintenance } from '@config/system';
import { AnalyticsInit } from '@config/analytics';
import { store } from './redux';
import './App.css';

function App() {
  useEffect(() => {
    AnalyticsInit();
  }, []);

  if (isMaintenance) {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <MaintenancePage />
        </ThemeProvider>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ThemeProvider theme={theme}>
          <ErrorMessenger />
          <Provider store={store}>
            <Initializer>
              <Router />
            </Initializer>
            <ExportErrorListener />
          </Provider>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
