import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { COLOR_LIGHT_PRIMARY } from '@core/Theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { Typography } from '@mui/material';
import { toggleSideMenuAction } from '@redux/ui/ui.slice';
import {
  CONTACT_MAIL,
  HELP_URL,
  TERMS_OF_SERVICE_URL,
} from '@config/constants';
import { externalLogEvent } from '@services/log.service';
import { useTranslation } from 'react-i18next';
import { IFramePopups } from './IFramePopups';
import { MenuButton } from './MenuButton/MenuButton';
import logo from '../../../assets/logo_white.svg';
import atIcon from './assets/at.svg';
import pedometerIcon from './assets/pedometer.svg';
import helpIcon from './assets/help.svg';
import colapseIcon from './assets/colapse.svg';
import { useSideMenuStyle } from './SideMenu.styles';

export const SideMenu: FC = () => {
  const classes = useSideMenuStyle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sideMenuHidden } = useSelector(({ ui }: Store) => ui);
  const [iframeUrl, setIframeUrl] = useState('');
  const [btnRef, setBtnRef] = useState<HTMLParagraphElement | null>(null);

  return (
    <Box
      sx={{
        transition: '.3s',
      }}
      onTransitionEnd={() => window.dispatchEvent(new Event('resize'))}
      height="100vh"
      position="sticky"
      top={0}
      zIndex={2}
      bgcolor="#3e4954"
      width="180px"
      ml={sideMenuHidden ? '-180px' : 0}
      display="grid"
      color={COLOR_LIGHT_PRIMARY}
      gridTemplateRows="auto 1fr"
    >
      <Box
        ml={2}
        my={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Link to="/">
          <img src={logo} alt="Logo" height="20px" />
        </Link>

        <Box
          sx={{
            transition: '.3s',
          }}
          lineHeight={1}
          zIndex={4}
          borderRadius="0 10px 10px 0"
          top={20}
          left={8}
          bgcolor="#3e4954"
          className="pointer"
          onClick={() => dispatch(toggleSideMenuAction())}
          p={1}
          m={-1}
          mr={sideMenuHidden ? -6 : 1}
        >
          <img
            style={{ transform: sideMenuHidden ? 'scaleX(-1)' : undefined }}
            src={colapseIcon}
            alt="Logo"
            height="32px"
          />
        </Box>
      </Box>

      <Box width="164px" ml="auto" mt={3} overflow="hidden">
        <div className={classes.accordion}>
          <div className={classes.accordionTitle}>
            <div
              className={classes.accordionTitleContent}
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={pedometerIcon}
                alt="Dashboard"
                height="15px"
                width="18px"
                style={{ marginRight: 11 }}
              />
              <Typography variant="body1" color="primary" fontWeight="500">
                {t('common.dashboard')}
              </Typography>
            </div>
          </div>
        </div>
      </Box>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <MenuButton
          icon={helpIcon}
          onClick={({ target }) => {
            externalLogEvent('console_knowledge_base');
            setIframeUrl(HELP_URL);
            setBtnRef(target as HTMLParagraphElement);
          }}
        >
          {t('common.helpCenter')}
        </MenuButton>
        <a
          href={`mailto:${CONTACT_MAIL}`}
          style={{ textDecoration: 'none' }}
          onClick={() => externalLogEvent('console_contact')}
        >
          <MenuButton icon={atIcon}>{t('common.contactUs')}</MenuButton>
        </a>
        <hr
          style={{
            opacity: 0.8,
            width: '100%',
            borderColor: 'white',
            borderTop: 0,
            margin: '16px 0',
          }}
        />
        <a
          href={TERMS_OF_SERVICE_URL}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', textAlign: 'center' }}
          onClick={() => externalLogEvent('console_tos')}
        >
          <Typography
            lineHeight={1.5}
            fontWeight={500}
            className="pointer"
            variant="caption"
            color="white"
          >
            {t('auth.termsOfService')}
          </Typography>
        </a>
        <IFramePopups
          btnRef={btnRef}
          url={iframeUrl}
          onClose={() => setBtnRef(null)}
        />
      </Box>
    </Box>
  );
};
