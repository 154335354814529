import ReactGA4 from 'react-ga4';
import { isProduction } from '@config/system';

const AnalyticsInit = () => {
  // Init ReactGA4
  ReactGA4.initialize([
    {
      trackingId: isProduction ? 'G-YDPCMYQ7TF' : 'G-2K6Z5SJ6NN',
      gaOptions: { name: 'ConsolePrivaterTracker' },
    },
  ]);
};
const AnalyticsEvents = (
  category: string,
  action: string,
  label: string,
  value?: number
): {
  sendGoogle: () => void;
} => ({
  sendGoogle: () => {
    if (category && action && label) {
      ReactGA4.event(
        {
          category,
          action,
          label,
          value,
        },
        ['ConsolePrivaterTracker']
      );
    }
  },
});
export { AnalyticsInit, AnalyticsEvents };
