import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CognitoException } from '@typings/CognitoException';
import { setCognitoError } from '@services/cognito.service';
import { fetchInitialDataAction } from '@redux/login/login.asyncActions';
import { useDispatch } from 'react-redux';
import { AuthTitle } from '@components/AuthTitle';
import { EmailCodeInput } from '@components/EmailCodeInput';
import Box from '@mui/material/Box';
import { Messenger, toast } from '@core/Messenger';
import { resetPasswordFormResolver } from './ConfirmSignUpForm.validation';
import { ConfirmSignUpFormProps, ConfirmSignUpFormValues } from './types';

export const ConfirmSignUpForm: FC<ConfirmSignUpFormProps> = ({
  email,
  password,
}) => {
  const [sendingCode, setSendingCode] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    setValue,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ConfirmSignUpFormValues>({
    mode: 'onSubmit',
    resolver: resetPasswordFormResolver,
  });

  const onSubmit = async ({ code }: ConfirmSignUpFormValues) => {
    try {
      await Auth.confirmSignUp(email, code);

      if (password) {
        await Auth.signIn({
          username: email,
          password,
        });
        await dispatch(fetchInitialDataAction());

        navigate('/');
        return;
      }

      toast.success(t('auth.signUpSuccess'));
      navigate('/sign-in');
    } catch (e) {
      const error = e as CognitoException;
      if (!setCognitoError(setError, error, 'code')) {
        toast.error(error.message);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AuthTitle
            title={t('auth.verificationCode')}
            subtitle={`${t('auth.verificationCodePasswordInfo')}.`}
          />
          <Box mt="12px" mb="12px">
            <Messenger />
          </Box>
          <Box textAlign="center">
            <EmailCodeInput
              errorText={errors.code?.message}
              charAmount={6}
              onChange={(value) => setValue('code', value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">
            {`${t('auth.noMainInfo')} `}
            <Box
              className="pointer"
              component="span"
              color={sendingCode ? 'grey.400' : 'primary.main'}
              onClick={async () => {
                if (!sendingCode) {
                  try {
                    setSendingCode(true);
                    await Auth.resendSignUp(email);
                  } finally {
                    setSendingCode(false);
                  }
                }
              }}
              data-testid="resend-code-btn"
            >
              {t('auth.reSendCode')}
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={324} mx="auto">
            <Button
              size="large"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              data-testid="confirm-code-submit"
            >
              {t('auth.send')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
