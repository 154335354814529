import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import clsx from 'clsx';
import { useDateInputStyle } from './DateInput.style';
import { DAY_FORMAT } from '../../MainChart.service';
import { DateInputProps } from './types';

dayjs.extend(customParseFormat);

const DATE_FORMAT = 'M.D.YYYY';

export const DateInput: FC<DateInputProps> = ({
  disabled,
  value,
  onChange,
}) => {
  const classes = useDateInputStyle();
  const [error, setError] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    setCurrentValue(value ? dayjs(value).format(DATE_FORMAT) : '');
    setError(false);
  }, [value]);

  return (
    <input
      disabled={disabled}
      className={clsx(classes.input, { [classes.error]: error })}
      placeholder={DATE_FORMAT}
      value={currentValue}
      onChange={({ target }) => setCurrentValue(target.value)}
      onBlur={() => {
        const date = dayjs(currentValue, DATE_FORMAT);
        if (date.isValid()) {
          onChange(date.format(DAY_FORMAT));
        } else {
          setError(true);
        }
      }}
    />
  );
};
