import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { EMAIL_REGEX } from '@config/constants';
import { Messenger, toast } from '@core/Messenger';
import { CognitoException } from '@typings/CognitoException';
import { setCognitoError } from '@services/cognito.service';
import { useTranslation } from 'react-i18next';
import { AuthTitle } from '@components/AuthTitle';
import Box from '@mui/material/Box';
import { ForgotPasswordFormValues } from './types';
import { ForgotPasswordConfirmation } from './ForgotPasswordConfirmation';

export const ForgotPasswordForm: FC = () => {
  const { t } = useTranslation();
  const [submittedEmail, setSubmittedEmail] = useState('');

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordFormValues>({
    mode: 'onChange',
  });

  const onSubmit = async ({ email }: ForgotPasswordFormValues) => {
    try {
      await Auth.forgotPassword(email);

      setSubmittedEmail(email);
    } catch (e) {
      const error = e as CognitoException;
      if (!setCognitoError(setError, error, 'email')) {
        toast.error(error.message);
      }
    }
  };

  if (submittedEmail) {
    return (
      <ForgotPasswordConfirmation
        email={submittedEmail}
        onBack={() => setSubmittedEmail('')}
      />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AuthTitle
            title={`${t('auth.forgotPassword')}?`}
            subtitle={t('auth.passwordReminderInstruction')}
          />
          <Box mt="12px" mb="12px">
            <Messenger />
          </Box>
          <TextField
            disabled={isSubmitting}
            label={`${t('auth.workEmail')} *`}
            inputProps={{ 'data-testid': 'email-input' }}
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register('email', {
              required: t('validation.required') as string,
              pattern: {
                value: EMAIL_REGEX,
                message: t('validation.email'),
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Box maxWidth={324} mx="auto">
            <Button
              size="large"
              variant="contained"
              type="submit"
              data-testid="send-code-submit"
              disabled={isSubmitting}
              fullWidth
            >
              {t('auth.send')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
