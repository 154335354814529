import { FC } from 'react';
import { Drawer } from '@mui/material';
import { MobileMenuProps } from '@core/LoggedInLayout/TopBar/types';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import { COLOR_LIGHT_PRIMARY } from '@core/Theme/theme';
import logo from '../../../assets/logo_white.svg';

export const MobileMenu: FC<MobileMenuProps> = ({ open, onClick }) => (
  <Drawer
    data-testid="mobile-menu-drawer"
    anchor="left"
    PaperProps={{
      sx: {
        right: 0,
        bgcolor: 'primary.main',
        color: COLOR_LIGHT_PRIMARY,
        overflowX: 'hidden',
      },
    }}
    open={open}
    sx={{ display: { xs: 'block', sm: 'none' } }}
  >
    <Box p={1} display="flex" alignItems="center" textAlign="right">
      <Box
        sx={{
          height: '20px',
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'center',
        }}
      >
        <img src={logo} alt="Logo" />
      </Box>

      <IconButton
        size="large"
        onClick={onClick}
        color="inherit"
        data-testid="mobile-menu-close-btn"
      >
        <MenuIcon />
      </IconButton>
    </Box>
  </Drawer>
);
