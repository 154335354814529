import React, { FC } from 'react';
import { DefaultLayout } from '@core/DefaultLayout';
import { NewPasswordForm } from '@components/NewPasswordForm';
import { useParams } from 'react-router-dom';

export const ForgotPasswordLinkPage: FC = () => {
  const { email, code } = useParams();

  return (
    <DefaultLayout>
      <NewPasswordForm email={email as string} code={code as string} />
    </DefaultLayout>
  );
};
