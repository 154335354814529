import React, { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { MainChartPromotionFilters } from '@components/MainChart/MainChartPromotionFilters';
import { MainChartClientFilters } from '@components/MainChart/MainChartClientFilters';
import { Promotion } from '@typings/ClientsResp';
import { OptinCreatorCodeResult } from './OptinCreatorCodeResult';
import { OptinCreatorForm } from './OptinCreatorForm';
import { OptinCreatorFormValues } from './types';
import { defaultValues } from './OptinCreator';

export const OptinInternalCreator: FC = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] =
    useState<OptinCreatorFormValues>(defaultValues);
  const { clients } = useSelector((store: Store) => store.login);

  const [clientKey, setClientKey] = useState<string[]>([]);
  const [promotionKey, setPromotionKey] = useState<Promotion[]>([]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t('optinGen.firstLinePrivate')}
        </Typography>
      </Grid>
      <Grid item>
        <MainChartClientFilters
          singleSelect
          label="Brand"
          value={clientKey}
          onChange={setClientKey}
        />
      </Grid>
      <Grid item>
        <MainChartPromotionFilters
          singleSelect
          allLabel="Promotion"
          value={promotionKey}
          formValues={{
            clientKey,
            promotionKey,
          }}
          onChange={(item) => {
            if (!clientKey[0] || clientKey[0] !== item[0]?.clientKey) {
              setTimeout(() => {
                setClientKey([item[0].clientKey as string]);
              }, 0);
            }
            setPromotionKey(item);
          }}
        />
      </Grid>

      {promotionKey[0] && clientKey[0] && (
        <>
          <Grid item xs={12}>
            <OptinCreatorForm
              defaultValues={defaultValues}
              onChange={setFormValues}
              withoutUTM={!clients[clientKey[0]]?.isWithUtm}
            />
          </Grid>
          <Grid item xs={12}>
            <OptinCreatorCodeResult
              clientKey={clientKey[0]}
              promotionKey={promotionKey[0].promotionKey}
              {...formValues}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
