import React, { FC, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UTMLinkCreator } from '@components/OptinCreator/UTMLinkCreator';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { COLOR_TRANSPARENT_DARKEST } from '@core/Theme/theme';
import { OptinCreatorFormValues } from '../types';
import { OptinCreatorFormProps } from './types';
import { UTMExplanation } from '../UTMExplanation';

export const OptinCreatorForm: FC<OptinCreatorFormProps> = ({
  withoutUTM,
  defaultValues,
  onChange,
}) => {
  const { t } = useTranslation();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [values, setValues] = useState<OptinCreatorFormValues>(defaultValues);

  const register = (key: keyof OptinCreatorFormValues, checkbox?: boolean) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: ({ target: { value, checked } }: any) => {
      const newValue = { ...values, [key]: checkbox ? checked : value };
      setValues(newValue);
      onChange(newValue);
    },
    value: values[key] || '',
    checked: Boolean(values[key]),
    name: key,
  });

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          ml={2}
          mb={1}
          display="inline-flex"
          onClick={() => setFilterOpen(!isFilterOpen)}
          style={{ cursor: 'pointer' }}
        >
          <Typography variant="body2" fontWeight={400}>
            {isFilterOpen
              ? t('optinGen.lessOptions')
              : t('optinGen.moreOptions')}
          </Typography>

          {isFilterOpen ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </Box>

        <Typography
          sx={{ cursor: 'pointer' }}
          variant="caption"
          onClick={() => {
            setValues(defaultValues);
            onChange(defaultValues);
          }}
        >
          {t('optinGen.reset')}
        </Typography>
      </Box>

      <Divider />
      <Box overflow="hidden">
        <Box mt={-8}>
          <Accordion expanded={isFilterOpen}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2">
                {t('optinGen.advanceOptions')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid container spacing={3}>
                  <Grid container item xs={withoutUTM ? 6 : 4} wrap="nowrap">
                    <Grid item flexGrow={1}>
                      <Box pb={2}>
                        <Typography variant="body2" fontWeight={600}>
                          {`${t('optinGen.height')}:`}
                        </Typography>
                      </Box>
                      <Box pb={2} position="relative">
                        {defaultValues.heightDesktop ===
                          +values.heightDesktop && (
                          <Box
                            fontSize={14}
                            position="absolute"
                            top={10}
                            left={65}
                            color={COLOR_TRANSPARENT_DARKEST}
                          >
                            ({t('common.default')})
                          </Box>
                        )}
                        <TextField
                          fullWidth
                          disabled={values.isFullHeight}
                          label="Desktop"
                          variant="outlined"
                          {...register('heightDesktop')}
                          size="small"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <Typography variant="caption" pl={1}>
                                px
                              </Typography>
                            ),
                          }}
                        />
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                {...register('isFullHeight', true)}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {t('optinGen.fullHeightDesktop')}
                              </Typography>
                            }
                          />

                          <Tooltip
                            arrow
                            title={
                              <>
                                {t('optinGen.tooltip1')}
                                <br /> <br />
                                <strong>height: 100%</strong>
                              </>
                            }
                            placement="top"
                          >
                            <HelpIcon fontSize="small" color="inherit" />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box position="relative">
                        {defaultValues.heightMobile ===
                          +values.heightMobile && (
                          <Box
                            fontSize={14}
                            position="absolute"
                            top={10}
                            left={65}
                            color={COLOR_TRANSPARENT_DARKEST}
                          >
                            ({t('common.default')})
                          </Box>
                        )}
                        <TextField
                          fullWidth
                          disabled={values.isFullHeightMobile}
                          label="Mobile"
                          variant="outlined"
                          {...register('heightMobile')}
                          size="small"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <Typography variant="caption" pl={1}>
                                px
                              </Typography>
                            ),
                          }}
                        />

                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                {...register('isFullHeightMobile', true)}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {t('optinGen.fullHeightMobile')}
                              </Typography>
                            }
                          />
                          <Tooltip
                            arrow
                            title={
                              <>
                                {t('optinGen.tooltip2')}
                                <br /> <br />
                                <strong>height: 100%</strong>
                              </>
                            }
                            placement="top"
                          >
                            <HelpIcon fontSize="small" color="inherit" />
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Divider orientation="vertical" sx={{ ml: 3 }} />
                    </Grid>
                  </Grid>
                  <Grid container item xs={withoutUTM ? 6 : 4} wrap="nowrap">
                    <Grid item flexGrow={1}>
                      <Box pb={2}>
                        <Typography variant="body2" fontWeight={600}>
                          {`${t('optinGen.width')}:`}
                        </Typography>
                      </Box>
                      <Box pb={2} position="relative">
                        {defaultValues.widthDesktop ===
                          +values.widthDesktop && (
                          <Box
                            fontSize={14}
                            position="absolute"
                            top={10}
                            left={65}
                            color={COLOR_TRANSPARENT_DARKEST}
                          >
                            ({t('common.default')})
                          </Box>
                        )}
                        <TextField
                          fullWidth
                          disabled={values.isFullWidth}
                          label="Desktop"
                          variant="outlined"
                          {...register('widthDesktop')}
                          size="small"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <Typography variant="caption" pl={1}>
                                px
                              </Typography>
                            ),
                          }}
                        />
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                {...register('isFullWidth', true)}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {t('optinGen.fullWidthDesktop')}
                              </Typography>
                            }
                          />
                          <Tooltip
                            arrow
                            title={
                              <>
                                {t('optinGen.tooltip3')}
                                <br /> <br />
                                <strong>width: 100%</strong>
                              </>
                            }
                            placement="top"
                          >
                            <HelpIcon fontSize="small" color="inherit" />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box position="relative">
                        {defaultValues.widthMobile === +values.widthMobile && (
                          <Box
                            fontSize={14}
                            position="absolute"
                            top={10}
                            left={65}
                            color={COLOR_TRANSPARENT_DARKEST}
                          >
                            ({t('common.default')})
                          </Box>
                        )}
                        <TextField
                          fullWidth
                          disabled={values.isFullWidthMobile}
                          label="Mobile"
                          variant="outlined"
                          {...register('widthMobile')}
                          size="small"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <Typography variant="caption" pl={1}>
                                px
                              </Typography>
                            ),
                          }}
                        />
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                {...register('isFullWidthMobile', true)}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                {t('optinGen.fullWidthMobile')}
                              </Typography>
                            }
                          />
                          <Tooltip
                            arrow
                            title={
                              <>
                                {t('optinGen.tooltip4')}
                                <br /> <br />
                                <strong>width: 100%</strong>
                              </>
                            }
                            placement="top"
                          >
                            <HelpIcon fontSize="small" color="inherit" />
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                    {!withoutUTM && (
                      <Grid item>
                        <Divider orientation="vertical" sx={{ ml: 3 }} />
                      </Grid>
                    )}
                  </Grid>
                  {!withoutUTM && (
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} />
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                          <UTMExplanation />
                        </Grid>
                        <Grid item xs={12}>
                          <UTMLinkCreator />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Divider />
    </div>
  );
};
