import { FC, useEffect, useMemo, useState } from 'react';
import {
  ChartFiltersDateType,
  ChartFiltersValue,
} from '@components/MainChart/types';
import { Box, Button, Menu, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMainChartDateFiltersStyle } from './MainChartDateFilters.style';
import { getDateFilters } from '../MainChart.service';
import { DateInput } from './DateInput/DateInput';
import calendarIcon from './assets/calendar.svg';
import { DropdownInput } from '../DropdownInput';
import { Props } from './types';
import { getDateLabel } from './MainChartDateFilters.service';
import { DateRangeInput } from './DateRangeInput';

export const MainChartDateFilters: FC<Props> = ({
  dateType,
  fromDate,
  toDate,
  onChange,
  dateSeparator,
}) => {
  const classes = useMainChartDateFiltersStyle();
  const { t } = useTranslation();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [formValue, setFormValue] = useState<ChartFiltersValue>({
    clientKey: [],
    promotionKey: [],
    dateType,
    fromDate,
    toDate,
  });

  useEffect(() => {
    setFormValue({
      clientKey: [],
      promotionKey: [],
      dateType,
      fromDate,
      toDate,
    });
  }, [anchorElUser, dateType, fromDate, toDate]);

  const isCalendarDisabled = formValue.dateType !== ChartFiltersDateType.Custom;
  const closePopup = () => setAnchorElUser(null);

  const fromLabel = useMemo(
    () =>
      dayjs(fromDate, 'YYYY-MM-DD').format(
        `MM${dateSeparator}DD${dateSeparator}YYYY`
      ),
    [fromDate, dateSeparator]
  );
  const toLabel = useMemo(
    () =>
      dayjs(toDate, 'YYYY-MM-DD').format(
        `MM${dateSeparator}DD${dateSeparator}YYYY`
      ),
    [toDate, dateSeparator]
  );

  return (
    <>
      <DropdownInput
        open={Boolean(anchorElUser)}
        icon={calendarIcon}
        label={getDateLabel(dateType, fromLabel, toLabel)}
        onClick={({ currentTarget }) => setAnchorElUser(currentTarget)}
      />

      <Menu
        classes={{ paper: classes.menuPaper }}
        onClose={closePopup}
        open={Boolean(anchorElUser)}
        anchorEl={anchorElUser}
        keepMounted
      >
        <Box px={2} py={1} display="flex" flexDirection="row" overflow="auto">
          <Box>
            {Object.keys(ChartFiltersDateType).map((key) => (
              <Typography
                key={key}
                className="pointer"
                bgcolor={key === formValue.dateType ? 'grey.100' : undefined}
                variant="body2"
                p={1}
                width={130}
                onClick={() => {
                  setFormValue(
                    getDateFilters(key as ChartFiltersDateType, formValue)
                  );
                }}
              >
                {t(`dateOption.${key}`)}
              </Typography>
            ))}
          </Box>
          <Box
            display="grid"
            gridTemplateRows="auto auto"
            gridTemplateColumns="auto auto"
          >
            <Box gridColumn={{ xs: '1 / span 2' }} mb={3} mx={2}>
              <DateRangeInput
                disabledFuture
                disabled={isCalendarDisabled}
                dateType={formValue.dateType}
                fromDate={formValue.fromDate}
                toDate={formValue.toDate}
                onChange={(from, to) => {
                  setFormValue({
                    ...formValue,
                    fromDate: from,
                    toDate: to,
                  });
                }}
              />
            </Box>

            <Box
              pt={2}
              borderTop="1px solid"
              borderColor="grey.300"
              color="grey.600"
            >
              <DateInput
                disabled={isCalendarDisabled}
                value={formValue.fromDate}
                onChange={(newValue) => {
                  setFormValue({
                    ...formValue,
                    fromDate: newValue,
                  });
                }}
              />
              {` - `}
              <DateInput
                disabled={isCalendarDisabled}
                value={formValue.toDate}
                onChange={(newValue) => {
                  setFormValue({
                    ...formValue,
                    toDate: newValue,
                  });
                }}
              />
            </Box>

            <Box
              pt={2}
              textAlign="right"
              borderTop="1px solid"
              borderColor="grey.300"
              color="grey.500"
            >
              <Button
                sx={{ width: 89, mr: 1 }}
                variant="text"
                color="inherit"
                onClick={closePopup}
              >
                Cancel
              </Button>
              <Button
                sx={{ width: 89 }}
                variant="contained"
                onClick={() => {
                  onChange(formValue);
                  closePopup();
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
