import React, { FC } from 'react';
import Box from '@mui/material/Box';
import { Promotion } from '@typings/ClientsResp';
import dayjs from 'dayjs';

export const PromotionDataRow: FC<{ promotions: Promotion[] }> = ({
  promotions,
}) => {
  const startDate = (() => {
    if (promotions.length !== 1) {
      return '-';
    }
    return promotions[0].startDate
      ? dayjs(promotions[0].startDate).format('MM.DD.YYYY')
      : 'Pending';
  })();

  const endDate = (() => {
    if (promotions.length !== 1) {
      return '-';
    }
    if (!promotions[0].startDate) {
      return 'Pending';
    }
    return promotions[0].endDate
      ? dayjs(promotions[0].endDate).format('MM.DD.YYYY')
      : 'Ongoing';
  })();

  return (
    <Box
      pr={4}
      display="flex"
      flexDirection="row"
      fontSize="15px"
      lineHeight="18px"
    >
      <Box color="#9C9C9C" mr={5} fontWeight={400}>
        Promotion Date
      </Box>
      <Box fontWeight={500} borderRight="1px solid #DBDDEA" pr={2} mr={2}>
        Start date: {startDate}
      </Box>
      <Box fontWeight={500}>End date: {endDate}</Box>
    </Box>
  );
};
