import React, { FC, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { SelectPromotionFieldProps } from './types';

export const SelectPromotionField: FC<SelectPromotionFieldProps> = ({
  onScrollTop,
  bottom,
  text,
  heightRatio,
}) => {
  const ref = useRef<HTMLDivElement>();
  const [top, setTop] = useState(48);

  useEffect(() => {
    const onScroll = () => {
      if (ref.current) {
        const dimensions = ref.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const topPos = -dimensions.top + windowHeight / 2;

        setTop(topPos < 48 ? 48 : topPos);
      }
    };

    window.document.addEventListener('scroll', onScroll);
    return () => window.document.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        aspectRatio: heightRatio,
      }}
      left={0}
      right={0}
      bottom={bottom}
      boxShadow="black 0 40px 50px"
      position="absolute"
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      bgcolor="common.white"
    >
      <Box position="absolute" top={top}>
        <Typography
          variant="body1"
          fontSize="20px"
          fontWeight={600}
          maxWidth={600}
        >
          {text}
        </Typography>
        <Box
          sx={{ cursor: 'pointer', lineHeight: 1 }}
          onClick={() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });

            let timeoutId = setTimeout(() => undefined);
            const callback = () => {
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => {
                onScrollTop();

                window.removeEventListener('scroll', callback);
              }, 100);
            };

            window.addEventListener('scroll', callback);
          }}
          fontSize="16px"
        >
          <ArrowUpwardIcon color="primary" fontSize="inherit" />
          <Typography
            fontSize="14px"
            variant="body2"
            sx={{ textDecoration: 'underline' }}
            color="primary"
            fontWeight={500}
          >
            Select Now
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
