import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { isProduction } from '@config/system';
import login from './login/login.slice';
import ui from './ui/ui.slice';

const reducer = combineReducers({
  login,
  ui,
});

export const store = configureStore({
  reducer,
  devTools: !isProduction,
});
