import { useCallback, useEffect, useState } from 'react';
import { graphqlRequest } from '@services/http.service';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { toast } from '@core/Messenger';
import { DashboardEmbedUrlResp } from './types';

const fetchUrl = () =>
  graphqlRequest<DashboardEmbedUrlResp>(
    gql`
      {
        dashboardEmbedUrl
      }
    `
  );

export const useGraphUrl = () => {
  const [url, setUrl] = useState<string>();
  const [isLoading, setLoading] = useState(true);
  const { t } = useTranslation();

  const fetchData = useCallback(() => {
    fetchUrl()
      .then((resp) => setUrl(resp.data?.dashboardEmbedUrl as string))
      .catch(() => toast.error(t('chart.error')))
      .finally(() => setLoading(false));
  }, [t]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    url,
    isLoading,
    refresh: fetchData,
  };
};
