import React, { FC, useEffect, useRef, useState } from 'react';
import clientsIcon from '@components/MainChart/MainChartPromotionFilters/assets/clients.svg';
import { ListItemText, Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { COLOR_PRIMARY } from '@core/Theme/theme';
import { useMainChartPromotionFiltersStyle } from '../MainChartPromotionFilters/MainChartPromotionFilters.style';
import { DropdownInput } from '../DropdownInput';
import { MainChartClientFiltersProps } from './types';
import { useClients } from './useClients.hooks';
import { PillText } from '../PillText';

export const MainChartClientFilters: FC<MainChartClientFiltersProps> = ({
  isExport,
  value,
  onChange,
  label,
  singleSelect,
  error,
}) => {
  const classes = useMainChartPromotionFiltersStyle();
  const anchorElUser = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { clients, options } = useClients(isExport);

  const closeDropdown = () => setOpen(false);

  useEffect(() => {
    if (options.length === 1) {
      setTimeout(() => {
        onChange([options[0].clientKey]);
      }, 100);
    }
  }, [options, onChange]);

  return (
    <>
      <DropdownInput
        ref={anchorElUser}
        disabled={options.length < 2}
        open={open}
        icon={clientsIcon}
        error={Boolean(error)}
        label={
          value.length ? (
            <PillText values={value.map((item) => clients[item]?.clientName)} />
          ) : (
            label
          )
        }
        onClick={() => setOpen(true)}
      />

      {error && (
        <Box
          color="red"
          fontSize="12px"
          margin="3px 24px 0"
          mb="-18px"
          fontWeight={500}
        >
          {error}
        </Box>
      )}

      <Menu
        classes={{ paper: classes.menuPaper }}
        onClose={closeDropdown}
        open={open}
        anchorEl={anchorElUser.current}
        keepMounted
      >
        {!singleSelect && (
          <Box
            bgcolor="white"
            pt={1}
            pb={1}
            px={2}
            color={COLOR_PRIMARY}
            whiteSpace="nowrap"
            display="flex"
            justifyContent="space-between"
            fontSize="12px"
            fontWeight="500"
            minWidth="200px"
            top={0}
            position="sticky"
            zIndex={1}
          >
            <span
              className="pointer"
              onClick={() =>
                onChange(options.map(({ clientKey }) => clientKey))
              }
            >
              Select all ({options.length})
            </span>
            <span className="pointer" onClick={() => onChange([])}>
              clear
            </span>
          </Box>
        )}

        {options.map((client) => (
          <MenuItem
            key={client.clientKey}
            value={client.clientKey}
            onClick={() => {
              const newValues = value.includes(client.clientKey)
                ? value.filter((item) => item !== client.clientKey)
                : [...value, client.clientKey];

              onChange(singleSelect ? [client.clientKey] : newValues);
              if (singleSelect) {
                closeDropdown();
              }
            }}
          >
            {!singleSelect && (
              <Checkbox
                checked={value.includes(client.clientKey)}
                sx={{ margin: '-7px 0 -7px -9px' }}
              />
            )}

            <ListItemText
              primary={
                <Typography variant="body2">{client.clientName}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
