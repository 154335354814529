import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {
  getDefaultMessage,
  registerObserver,
  removeObserver,
} from './Messenger.service';
import { Message } from './types';
import { useErrorMessengerStyle } from './ErrorMessenger.style';

export const ErrorMessenger: FC = () => {
  const classes = useErrorMessengerStyle();
  const { t } = useTranslation();
  const [message, setMessage] = useState<Message | null>(getDefaultMessage());

  useEffect(() => {
    const setter = (input: Message) => {
      if (input.variant === 'error') {
        setMessage(input);
      }
    };

    registerObserver(setter);
    return () => removeObserver(setter);
  }, []);

  const handleClose = () => setMessage(null);

  if (message) {
    return (
      <Dialog classes={classes} maxWidth="xs" fullWidth open>
        <Box p={3} position="relative">
          <IconButton
            size="small"
            onClick={handleClose}
            data-testid="close-icon"
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
          <DialogTitle
            sx={{
              color: '#F12828',
              fontWeight: 600,
              fontSize: 32,
              pb: 1,
            }}
          >
            {message.title || t('common.errorMessage')}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">{message.message}</Typography>
          </DialogContent>
          <DialogActions sx={{ my: 1, justifyContent: 'center' }}>
            <Button
              sx={{ width: 104, fontWeight: 700, textTransform: 'uppercase' }}
              onClick={handleClose}
              variant="contained"
            >
              {t('common.ok')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }

  return null;
};
