import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { useMemo } from 'react';

export const useClients = (isExport?: boolean) => {
  const { clients, exportPermissions } = useSelector(
    ({ login }: Store) => login
  );

  return {
    clients,
    options: useMemo(
      () =>
        Object.keys(clients)
          .filter(
            (clientKey) =>
              !isExport ||
              exportPermissions.find((item) => item.clientKey === clientKey)
                ?.exportsAllowed
          )
          .map((key) => clients[key])
          .sort((a, b) => (a.clientName > b.clientName ? 1 : -1)),
      [clients, exportPermissions, isExport]
    ),
  };
};
