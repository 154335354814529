import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Error404Page } from '@pages/Error404Page';
import { ConnectionLostPage } from '@pages/ConnectionLostPage';
import { ServerErrorPage } from '@pages/ServerErrorPage';
import { OptInPage } from './OptInPage';
import { SignInPage } from './SignInPage';
import { ForgotPasswordPage } from './ForgotPasswordPage';
import { HomePage } from './HomePage';
import { LogoutPage } from './LogoutPage';
import { ConfirmSignUpPage } from './ConfirmSignUpPage';
import { SignUpPage } from './SignUpPage';
import { ForgotPasswordLinkPage } from './ForgotPasswordLinkPage';
import { OptInInternalPage } from './OptInInternalPage';
import { RetoolPreviewPage } from './RetoolPreviewPage';
import { OpsSnapshotPreviewPage } from './OpsSnapshotPreviewPage';

export const Router: FC = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/sign-up" element={<SignUpPage />} />
    <Route path="/sign-in" element={<SignInPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route
      path="/forgot-password/:code/:email"
      element={<ForgotPasswordLinkPage />}
    />
    <Route path="/confirm-sign-up/:email" element={<ConfirmSignUpPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route path="/opt-in" element={<OptInPage />} />
    <Route path="/internal/opt-in" element={<OptInInternalPage />} />
    <Route path="/preview/:view" element={<RetoolPreviewPage />} />
    <Route path="/snaphot-preview" element={<OpsSnapshotPreviewPage />} />
    <Route path="/connection-lost" element={<ConnectionLostPage />} />
    <Route path="/server-error" element={<ServerErrorPage />} />
    <Route path="*" element={<Error404Page />} />
  </Routes>
);
