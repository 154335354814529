/* eslint max-len: 0 */
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainChartDateFilters } from '@components/MainChart/MainChartDateFilters';
import { MainChartPromotionFilters } from '@components/MainChart/MainChartPromotionFilters';
import { MainChartClientFilters } from '@components/MainChart/MainChartClientFilters';
import Box from '@mui/material/Box';
import { ExportFormat } from '@typings/ExportUrlReq';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { useClients } from '@components/MainChart/MainChartClientFilters/useClients.hooks';
import { ExportModalTemplate } from '@components/MainChart/ExportModalTemplate';
import { ExportFormValues, ExportModalProps } from './types';

const Label: FC = ({ children }) => (
  <Box fontWeight={400} fontSize="16px" lineHeight={1.1} color="#9C9C9C" mb={1}>
    {children}
  </Box>
);

export const ExportModal: FC<ExportModalProps> = ({
  open,
  initialData,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState(false);
  const [successfullDownload, setSuccessfullDownload] = useState(false);
  const [formValues, setFormValues] = useState<ExportFormValues>(initialData);
  const { exportPermissions } = useSelector(({ login }: Store) => login);
  const { exportInProgress, exportSuccessModal } = useSelector(
    ({ ui }: Store) => ui
  );

  const { options: clientsList } = useClients(true);

  useEffect(() => {
    setInvalid(false);
  }, [open]);

  useEffect(() => {
    if (exportSuccessModal) {
      setSuccessfullDownload(true);
    }
  }, [exportSuccessModal]);

  useEffect(() => {
    setSuccessfullDownload(false);
  }, [formValues]);

  useEffect(() => {
    setFormValues({
      ...initialData,
      optinData: false,
      registrationRedeemData: false,
      receiptsData: false,
      surveyData: false,
      fileFormat: ExportFormat.CSV,
      includePii: false,
    });
  }, [open, initialData]);

  const isPiiTooltipDisabled = (() => {
    const clientKeys = formValues.clientKey || [];

    if (clientKeys.length) {
      return !exportPermissions.find((item) => item.clientKey === clientKeys[0])
        ?.piiAllowed;
    }

    return clientsList.some(
      ({ clientKey }) =>
        !exportPermissions.find((item) => item.clientKey === clientKey)
          ?.piiAllowed
    );
  })();

  useEffect(() => {
    if (isPiiTooltipDisabled) {
      setFormValues((item) => ({
        ...item,
        includePii: false,
      }));
    }
  }, [isPiiTooltipDisabled]);

  const getPiiTooltip = () => {
    const clientKeys = formValues.clientKey || [];

    const noPiiClientsList = clientKeys.length
      ? (exportPermissions.find((item) => item.clientKey === clientKeys[0])
          ?.piiAllowed && [clientKeys[0]]) ||
        []
      : clientsList
          .filter(
            ({ clientKey }) =>
              !exportPermissions.find((item) => item.clientKey === clientKey)
                ?.piiAllowed
          )
          .map(({ clientKey }) => clientKey);

    return (
      <>
        PII data includes user email and/or phone number
        {isPiiTooltipDisabled && (
          <>
            <br />
            <br />
            You dont have access to pii for:
            <br />
            <strong>{noPiiClientsList.join(', ')}</strong>
          </>
        )}
      </>
    );
  };

  const isPiiPresent = clientsList.some(
    ({ clientKey }) =>
      exportPermissions.find((item) => item.clientKey === clientKey)?.piiAllowed
  );

  const onBrandChange = useMemo(
    () => (newValues: string[]) => {
      if (newValues.length) {
        setInvalid(false);
      }
      setFormValues((filtersTmp) => ({
        ...filtersTmp,
        clientKey: newValues,
      }));
    },
    [setFormValues, setInvalid]
  );

  return (
    <ExportModalTemplate
      title="Export"
      open={open}
      onClose={onClose}
      actionBtns={
        <>
          <Button variant="text" onClick={onClose}>
            <span style={{ color: '#9C9C9C' }}>Cancel</span>
          </Button>
          <Button
            startIcon={
              exportInProgress ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 0.126465V2.12646H11V0.126465H9ZM9 4.12646V12.7124L5.70703 9.41943L4.29297 10.8335L10 16.5405L15.707 10.8335L14.293 9.41943L11 12.7124V4.12646H9ZM0 15.1265V18.1265C0 19.2195 0.906937 20.1265 2 20.1265H18C19.0931 20.1265 20 19.2195 20 18.1265V15.1265H18V18.1265H2V15.1265H0Z"
                    fill="#FDFDFD"
                  />
                </svg>
              )
            }
            disabled={exportInProgress || successfullDownload}
            sx={{ width: '167px', color: 'white !important', ml: 2 }}
            variant="contained"
            onClick={() => {
              if (formValues.clientKey.length === 1) {
                onSubmit(formValues);
              } else {
                setInvalid(true);
              }
            }}
          >
            Export
          </Button>
        </>
      }
    >
      <Grid container spacing={{ xs: 2, md: 5 }} sx={{ textAlign: 'left' }}>
        <Grid item xs={12} md={6}>
          <Label>Period</Label>
          <MainChartDateFilters
            dateSeparator="/"
            dateType={formValues.dateType}
            fromDate={formValues.fromDate}
            toDate={formValues.toDate}
            onChange={(newValues) =>
              setFormValues({
                ...newValues,
                clientKey: formValues.clientKey,
                promotionKey: formValues.promotionKey,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Label>Brand</Label>
          <MainChartClientFilters
            isExport
            singleSelect
            error={invalid ? 'Field required' : ''}
            label="Brands"
            value={formValues.clientKey}
            onChange={onBrandChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Label>Promotion</Label>
          <MainChartPromotionFilters
            isExport
            fullWidth
            allLabel={t('common.allPromotions')}
            formValues={formValues}
            value={formValues.promotionKey}
            onChange={(newValues) =>
              setFormValues({
                ...formValues,
                promotionKey: newValues,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Label>Files selection</Label>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(formValues.optinData)}
                  onChange={() =>
                    setFormValues({
                      ...formValues,
                      optinData: !formValues.optinData,
                    })
                  }
                />
              }
              label="Opt-in data"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.registrationRedeemData}
                  onChange={() =>
                    setFormValues({
                      ...formValues,
                      registrationRedeemData:
                        !formValues.registrationRedeemData,
                    })
                  }
                />
              }
              label="Registration/redeem data"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.receiptsData}
                  onChange={() =>
                    setFormValues({
                      ...formValues,
                      receiptsData: !formValues.receiptsData,
                    })
                  }
                />
              }
              label="Receipts data"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.surveyData}
                  onChange={() =>
                    setFormValues({
                      ...formValues,
                      surveyData: !formValues.surveyData,
                    })
                  }
                />
              }
              label="Survey data"
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="space-between"
          flexDirection="column"
        >
          <div>
            <Label>File format</Label>
            <RadioGroup
              value={formValues.fileFormat}
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  fileFormat: event.target.value as ExportFormat,
                })
              }
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel
                value="xlsx"
                control={<Radio />}
                label="Excel"
              />
            </RadioGroup>
          </div>

          {isPiiPresent && (
            <Box
              display="flex"
              alignItems="center"
              lineHeight={1}
              mt={{
                xs: 2,
                md: 'auto',
              }}
            >
              <Tooltip title={getPiiTooltip()}>
                <Box component="span" mr={1}>
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.33333 0.626465C3.73333 0.626465 0 4.3598 0 8.9598C0 13.5598 3.73333 17.2931 8.33333 17.2931C12.9333 17.2931 16.6667 13.5598 16.6667 8.9598C16.6667 4.3598 12.9333 0.626465 8.33333 0.626465ZM8.75 14.7931H7.91667C7.68667 14.7931 7.5 14.6065 7.5 14.3765V13.5431C7.5 13.3131 7.68667 13.1265 7.91667 13.1265H8.75C8.98 13.1265 9.16667 13.3131 9.16667 13.5431V14.3765C9.16667 14.6065 8.98 14.7931 8.75 14.7931ZM10.8917 8.3348L10.1417 9.10147C9.54167 9.7098 9.16667 10.2098 9.16667 11.4598H7.5V11.0431C7.5 10.1265 7.875 9.29313 8.475 8.6848L9.50833 7.6348C9.88917 7.2648 10.0792 6.71647 9.96917 6.1323C9.85667 5.5348 9.39583 5.02646 8.81167 4.86063C7.89 4.59896 7.03083 5.12646 6.75667 5.92063C6.65 6.2298 6.39 6.4598 6.0625 6.4598H5.82C5.33583 6.4598 4.9975 5.9898 5.1325 5.5248C5.49 4.29813 6.53333 3.36397 7.82 3.16563C9.09 2.9698 10.2942 3.62313 11.0475 4.6648C12.0308 6.0248 11.7408 7.48563 10.8917 8.3348Z"
                      fill="#DBDDEA"
                    />
                  </svg>
                </Box>
              </Tooltip>
              Include PII
              <Switch
                disabled={isPiiTooltipDisabled}
                checked={Boolean(formValues.includePii)}
                onChange={() =>
                  setFormValues({
                    ...formValues,
                    includePii: !formValues.includePii,
                  })
                }
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </ExportModalTemplate>
  );
};
