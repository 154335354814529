import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { ChartFiltersValue } from '@components/MainChart/types';
import { exportQSAction } from '@redux/ui/ui.asyncActions';
import { AnalyticsEvents } from '@config/analytics';
import { ExportFormValues } from '../ExpotModal/types';

export const useExportBtn = (initialData: ChartFiltersValue) => {
  const dispatch = useDispatch();
  const [openExportModal, setOpenExportModal] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { hashedEmail } = useSelector(({ login }: Store) => login);

  const closeDropdown = () => setOpen(false);

  const { exportPermissions } = useSelector(({ login }: Store) => login);
  const { exportInProgress } = useSelector(({ ui }: Store) => ui);

  const exportFile = async (input: ExportFormValues) => {
    const { sendGoogle } = AnalyticsEvents(
      'User Activity',
      `Exported CVS data - System export`,
      hashedEmail
    );
    sendGoogle();
    dispatch(exportQSAction(input));
  };

  const initialDataOutput = useMemo(
    () => ({
      ...initialData,
      clientKey: (initialData.clientKey || []).filter(
        (key) =>
          exportPermissions.find(({ clientKey }) => clientKey === key)
            ?.exportsAllowed
      ),
      promotionKey: (initialData.promotionKey || []).filter(
        ({ clientKey }) =>
          exportPermissions.find((item) => clientKey === item.clientKey)
            ?.exportsAllowed
      ),
    }),
    [initialData, exportPermissions]
  );

  return {
    initialData: initialDataOutput,
    exportNotAllowed: !exportPermissions.some((item) => item.exportsAllowed),
    openExportModal,
    setOpenExportModal,
    open,
    setOpen,
    anchorEl,
    exportFile,
    closeDropdown,
    exportInProgress,
  };
};
