import React, { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';
import MuiTable from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { COLOR_TRANSPARENT_DARK } from '@core/Theme/theme';

export const Highlight: FC<{ bgColor?: string }> = ({ children, bgColor }) => (
  <Box
    component="span"
    display="inline"
    fontWeight={500}
    bgcolor={bgColor || '#fbe4f6'}
    sx={{ wordBreak: 'break-all' }}
  >
    {children}
  </Box>
);

export const Header: FC = ({ children }) => (
  <Box component="span" pt={1} pb={1} display="block" fontWeight={600}>
    {children}
  </Box>
);

export const Paragraph: FC = ({ children }) => (
  <Box component="span" pb={1} display="block">
    {children}
  </Box>
);

export const Underline: FC = ({ children }) => (
  <Box
    component="span"
    pt={2}
    pb={1}
    display="block"
    sx={{ textDecoration: 'underline' }}
  >
    {children}
  </Box>
);

const Cell: FC<{ header?: boolean }> = ({ children, header }) => (
  <TableCell
    sx={{
      fontWeight: header ? 600 : undefined,
      border: `1px solid ${COLOR_TRANSPARENT_DARK}`,
    }}
  >
    {children}
  </TableCell>
);

export const Table: FC<{ data: (string | ReactNode)[][] }> = ({ data }) => (
  <MuiTable width="100%">
    <TableRow>
      <Cell header>{data[0][0]}</Cell>
      <Cell header>{data[0][1]}</Cell>
      <Cell header>{data[0][2]}</Cell>
    </TableRow>
    <TableRow>
      <Cell>{data[1][0]}</Cell>
      <Cell>{data[1][1]}</Cell>
      <Cell>{data[1][2]}</Cell>
    </TableRow>
    <TableRow>
      <Cell>{data[2][0]}</Cell>
      <Cell>{data[2][1]}</Cell>
      <Cell>{data[2][2]}</Cell>
    </TableRow>
  </MuiTable>
);
