import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './ProductsTab.module.scss';
import { Props } from './types';

export const ProductsTab: FC<Props> = ({
  promotion: { inventory },
  displayName,
}) => {
  const { t } = useTranslation();

  if (inventory.length) {
    const isGTIN = inventory.some(({ gtin }) => gtin);

    return (
      <table className={classes.table}>
        <thead>
          <tr>
            {isGTIN && <th>{t('promotionDetails.itemCode')}</th>}
            <th>{t('promotionDetails.productName')}</th>
          </tr>
        </thead>
        <tbody>
          {inventory.map(({ gtin, title }) => (
            <tr key={`${gtin}${title}`}>
              {isGTIN && <td className={classes.gtin}>{gtin}</td>}
              <td>{title}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div className={classes.message}>
      {t('promotionDetails.anyProduct', { displayName })}
    </div>
  );
};
