/* eslint max-len: 0 */
import React, { FC, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Props } from './types';
import classes from './RetailersTab.module.scss';

export const RetailersTab: FC<Props> = ({ promotion: { retailerList } }) => {
  const { t } = useTranslation();
  const isMobileDevice = useMediaQuery('(max-width: 992px)');

  const { whitelist, blacklist } = useMemo(
    () => ({
      whitelist: retailerList.filter(
        ({ listType }) => listType === 'WHITELIST'
      ),
      blacklist: retailerList.filter(
        ({ listType }) => listType === 'BLACKLIST'
      ),
    }),
    [retailerList]
  );

  return (
    <div className={classes.message}>
      {whitelist.length ? (
        <div className={classes.grid}>
          {whitelist.map(({ name }) => (
            <div
              className={clsx(
                isMobileDevice ? classes.overflow : classes.singleline,
                classes.gridItem,
                {
                  [classes.grid2Columns]: whitelist.length > 5,
                }
              )}
              key={name}
            >
              <span
                title={name}
                className={clsx({ [classes.multiline]: isMobileDevice })}
              >
                {name}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className={classes.textMessage}>
          {t('promotionDetails.anyRetailer')}
          {Boolean(blacklist.length) &&
            t('promotionDetails.butRetailerException')}
          .
        </div>
      )}

      {Boolean(blacklist.length) && (
        <div className={classes.blacklist}>
          <div className={classes.blacklistLabel}>
            <svg
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 9V14M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19ZM10.0498 6V6.1L9.9502 6.09998V6H10.0498Z"
                stroke="#828282"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Excluded retailers:</span>
          </div>

          <div className={classes.grid}>
            {blacklist.map(({ name }) => (
              <div
                className={clsx(
                  isMobileDevice ? classes.overflow : classes.singleline,
                  classes.gridItem,
                  classes.blacklistItem,
                  {
                    [classes.grid2Columns]: blacklist.length > 5,
                  }
                )}
                key={name}
              >
                <svg
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.18689 2.47978C2.99162 2.28452 2.67504 2.28452 2.47978 2.47978C2.28452 2.67504 2.28452 2.99162 2.47978 3.18689L3.18689 2.47978ZM10.8131 11.5202C11.0084 11.7155 11.325 11.7155 11.5202 11.5202C11.7155 11.325 11.7155 11.0084 11.5202 10.8131L10.8131 11.5202ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5V0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM2.47978 3.18689L10.8131 11.5202L11.5202 10.8131L3.18689 2.47978L2.47978 3.18689Z"
                    fill="#FF553E"
                  />
                </svg>
                <span
                  title={name}
                  className={clsx({ [classes.multiline]: isMobileDevice })}
                >
                  {name}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
