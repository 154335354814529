/* eslint max-len: 0 */
import React, { FC } from 'react';
import { LoggedInLayout } from '@core/LoggedInLayout';
import Box from '@mui/material/Box';
import { Restrict } from '@core/Restrict';
import classes from './Error404Page.module.scss';

export const Error404Page: FC = () => (
  <Restrict isRedirect>
    <LoggedInLayout>
      <Box
        p={2}
        boxSizing="border-box"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        display="flex"
        alignItems="center"
        height="100%"
      >
        <Box className={classes.title}>Page not found</Box>
        <Box className={classes.subtitle}>
          Sorry, the page you were looking for does not exist.
          <br />
          Please check the URL address and try again.
        </Box>
        <svg
          width="318"
          className={classes.image}
          viewBox="0 0 318 402"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1144_22967)">
            <path
              d="M54.9727 14.9104L64.2986 401.407H68.5539C68.4869 398.559 70.6983 396.258 73.4905 396.258C76.2827 396.258 78.6059 398.57 78.684 401.407H83.2744C83.2074 398.559 85.4188 396.258 88.211 396.258C91.0032 396.258 93.3263 398.57 93.4045 401.407H97.9949C97.9279 398.559 100.139 396.258 102.932 396.258C105.724 396.258 108.047 398.57 108.125 401.407H112.715C112.648 398.559 114.86 396.258 117.652 396.258C120.444 396.258 122.767 398.57 122.846 401.407H127.436C127.369 398.559 129.58 396.258 132.372 396.258C135.165 396.258 137.488 398.57 137.566 401.407H142.156C142.089 398.559 144.301 396.258 147.093 396.258C149.885 396.258 152.208 398.57 152.286 401.407H156.877C156.81 398.559 159.021 396.258 161.813 396.258C164.606 396.258 166.929 398.57 167.007 401.407H171.597C171.53 398.559 173.742 396.258 176.534 396.258C179.326 396.258 181.649 398.57 181.727 401.407H186.318C186.251 398.559 188.462 396.258 191.254 396.258C194.047 396.258 196.37 398.57 196.448 401.407H201.038C200.971 398.559 203.183 396.258 205.975 396.258C208.767 396.258 211.09 398.57 211.168 401.407H215.759C215.692 398.559 217.903 396.258 220.695 396.258C223.488 396.258 225.811 398.57 225.889 401.407H230.479C230.412 398.559 232.624 396.258 235.416 396.258C238.208 396.258 240.531 398.57 240.609 401.407H245.2C245.133 398.559 247.344 396.258 250.136 396.258C252.929 396.258 255.252 398.57 255.33 401.407H259.92C259.853 398.559 262.065 396.258 264.857 396.258C267.649 396.258 269.972 398.57 270.05 401.407H274.641C274.574 398.559 276.785 396.258 279.577 396.258C282.37 396.258 284.693 398.57 284.771 401.407H289.361C289.294 398.559 291.506 396.258 294.298 396.258C297.09 396.258 299.413 398.57 299.491 401.407H303.747C303.747 401.407 265.46 227.643 294.745 27.7322L54.9727 14.9104Z"
              fill="#E8E8E8"
            />
            <path
              d="M304.048 -0.000244141H13.9498C6.24556 -0.000244141 0 6.24531 0 13.9496C0 21.6539 6.24556 27.8994 13.9498 27.8994H304.048C311.753 27.8994 317.998 21.6539 317.998 13.9496C317.998 6.24531 311.753 -0.000244141 304.048 -0.000244141Z"
              fill="#A8A8A8"
            />
            <path
              d="M278.74 11.8723L269.905 27.8996H294.778L278.74 11.8723Z"
              fill="#8E8E8E"
            />
            <path
              d="M39.2699 11.8723L48.1044 27.8996H23.2314L39.2699 11.8723Z"
              fill="#8E8E8E"
            />
            <path
              d="M285.041 11.6042H32.9612C31.2747 11.6042 29.9121 12.9668 29.9121 14.6533C29.9121 16.3398 31.2747 17.7024 32.9612 17.7024H285.041C286.728 17.7024 288.09 16.3398 288.09 14.6533C288.09 12.9668 286.728 11.6042 285.041 11.6042Z"
              fill="#58595B"
            />
            <path
              d="M39.2705 14.9102V394.828H43.5258C43.5258 392.036 45.7931 389.769 48.5853 389.769C51.3775 389.769 53.6448 392.036 53.6448 394.828H58.2351C58.2351 392.036 60.5024 389.769 63.2946 389.769C66.0868 389.769 68.3541 392.036 68.3541 394.828H72.9445C72.9445 392.036 75.2117 389.769 78.0039 389.769C80.7961 389.769 83.0634 392.036 83.0634 394.828H87.6538C87.6538 392.036 89.9211 389.769 92.7133 389.769C95.5055 389.769 97.7727 392.036 97.7727 394.828H102.363C102.363 392.036 104.63 389.769 107.423 389.769C110.215 389.769 112.482 392.036 112.482 394.828H117.072C117.072 392.036 119.34 389.769 122.132 389.769C124.924 389.769 127.191 392.036 127.191 394.828H131.782C131.782 392.036 134.049 389.769 136.841 389.769C139.633 389.769 141.901 392.036 141.901 394.828H146.491C146.491 392.036 148.758 389.769 151.551 389.769C154.343 389.769 156.61 392.036 156.61 394.828H161.2C161.2 392.036 163.468 389.769 166.26 389.769C169.052 389.769 171.319 392.036 171.319 394.828H175.91C175.91 392.036 178.177 389.769 180.969 389.769C183.761 389.769 186.029 392.036 186.029 394.828H190.619C190.619 392.036 192.886 389.769 195.678 389.769C198.471 389.769 200.738 392.036 200.738 394.828H205.328C205.328 392.036 207.596 389.769 210.388 389.769C213.18 389.769 215.447 392.036 215.447 394.828H220.038C220.038 392.036 222.305 389.769 225.097 389.769C227.889 389.769 230.157 392.036 230.157 394.828H234.747C234.747 392.036 237.014 389.769 239.806 389.769C242.599 389.769 244.866 392.036 244.866 394.828H249.456C249.456 392.036 251.724 389.769 254.516 389.769C257.308 389.769 259.575 392.036 259.575 394.828H264.166C264.166 392.036 266.433 389.769 269.225 389.769C272.017 389.769 274.285 392.036 274.285 394.828H278.54V14.9102H39.2705Z"
              fill="#F9F9F9"
            />
            <path
              d="M261.73 89.3503H56.2802C53.9794 89.3503 52.1143 91.2155 52.1143 93.5163C52.1143 95.8171 53.9794 97.6823 56.2802 97.6823H261.73C264.031 97.6823 265.896 95.8171 265.896 93.5163C265.896 91.2155 264.031 89.3503 261.73 89.3503Z"
              fill="#DF1C71"
            />
            <path
              d="M261.73 321.907H56.2802C53.9794 321.907 52.1143 323.772 52.1143 326.073C52.1143 328.374 53.9794 330.239 56.2802 330.239H261.73C264.031 330.239 265.896 328.374 265.896 326.073C265.896 323.772 264.031 321.907 261.73 321.907Z"
              fill="#DF1C71"
            />
            <path
              d="M216.275 346.411H101.727C100.443 346.411 99.4043 347.45 99.4043 348.734C99.4043 350.019 100.443 351.058 101.727 351.058H216.275C217.559 351.058 218.598 350.019 218.598 348.734C218.598 347.45 217.559 346.411 216.275 346.411Z"
              fill="#A6A8AB"
            />
            <path
              d="M191.458 357.346H177.028C175.743 357.346 174.705 358.384 174.705 359.669C174.705 360.953 175.743 361.992 177.028 361.992H191.458C192.742 361.992 193.781 360.953 193.781 359.669C193.781 358.384 192.742 357.346 191.458 357.346Z"
              fill="#A6A8AB"
            />
            <path
              d="M165.479 357.346H126.545C125.26 357.346 124.222 358.384 124.222 359.669C124.222 360.953 125.26 361.992 126.545 361.992H165.479C166.764 361.992 167.802 360.953 167.802 359.669C167.802 358.384 166.764 357.346 165.479 357.346Z"
              fill="#A6A8AB"
            />
            <path
              d="M259.698 111.23H255.588V109.141H259.698V111.23ZM251.477 111.23H247.367V109.141H251.477V111.23ZM243.257 111.23H239.147V109.141H243.257V111.23ZM235.037 111.23H230.927V109.141H235.037V111.23ZM226.817 111.23H222.707V109.141H226.817V111.23ZM218.597 111.23H214.486V109.141H218.597V111.23ZM210.376 111.23H206.266V109.141H210.376V111.23ZM202.156 111.23H198.046V109.141H202.156V111.23ZM193.936 111.23H189.826V109.141H193.936V111.23ZM185.716 111.23H181.605V109.141H185.716V111.23ZM177.495 111.23H173.385V109.141H177.495V111.23ZM169.275 111.23H165.165V109.141H169.275V111.23ZM161.055 111.23H156.945V109.141H161.055V111.23ZM152.835 111.23H148.724V109.141H152.835V111.23ZM144.614 111.23H140.504V109.141H144.614V111.23ZM136.394 111.23H132.284V109.141H136.394V111.23ZM128.174 111.23H124.064V109.141H128.174V111.23ZM119.954 111.23H115.843V109.141H119.954V111.23ZM111.733 111.23H107.623V109.141H111.733V111.23ZM103.513 111.23H99.403V109.141H103.513V111.23ZM95.2929 111.23H91.1827V109.141H95.2929V111.23ZM87.0726 111.23H82.9625V109.141H87.0726V111.23ZM78.8524 111.23H74.7422V109.141H78.8524V111.23ZM70.6321 111.23H66.522V109.141H70.6321V111.23ZM62.4119 111.23H58.3018V109.141H62.4119V111.23Z"
              fill="#A6A8AB"
            />
            <path
              d="M259.698 276.651H255.588V274.562H259.698V276.651ZM251.477 276.651H247.367V274.562H251.477V276.651ZM243.257 276.651H239.147V274.562H243.257V276.651ZM235.037 276.651H230.927V274.562H235.037V276.651ZM226.817 276.651H222.707V274.562H226.817V276.651ZM218.597 276.651H214.486V274.562H218.597V276.651ZM210.376 276.651H206.266V274.562H210.376V276.651ZM202.156 276.651H198.046V274.562H202.156V276.651ZM193.936 276.651H189.826V274.562H193.936V276.651ZM185.716 276.651H181.605V274.562H185.716V276.651ZM177.495 276.651H173.385V274.562H177.495V276.651ZM169.275 276.651H165.165V274.562H169.275V276.651ZM161.055 276.651H156.945V274.562H161.055V276.651ZM152.835 276.651H148.724V274.562H152.835V276.651ZM144.614 276.651H140.504V274.562H144.614V276.651ZM136.394 276.651H132.284V274.562H136.394V276.651ZM128.174 276.651H124.064V274.562H128.174V276.651ZM119.954 276.651H115.843V274.562H119.954V276.651ZM111.733 276.651H107.623V274.562H111.733V276.651ZM103.513 276.651H99.403V274.562H103.513V276.651ZM95.2929 276.651H91.1827V274.562H95.2929V276.651ZM87.0726 276.651H82.9625V274.562H87.0726V276.651ZM78.8524 276.651H74.7422V274.562H78.8524V276.651ZM70.6321 276.651H66.522V274.562H70.6321V276.651ZM62.4119 276.651H58.3018V274.562H62.4119V276.651Z"
              fill="#A6A8AB"
            />
            <path
              d="M259.698 303.858H255.588V301.77H259.698V303.858ZM251.477 303.858H247.367V301.77H251.477V303.858ZM243.257 303.858H239.147V301.77H243.257V303.858ZM235.037 303.858H230.927V301.77H235.037V303.858ZM226.817 303.858H222.707V301.77H226.817V303.858ZM218.597 303.858H214.486V301.77H218.597V303.858ZM210.376 303.858H206.266V301.77H210.376V303.858ZM202.156 303.858H198.046V301.77H202.156V303.858ZM193.936 303.858H189.826V301.77H193.936V303.858ZM185.716 303.858H181.605V301.77H185.716V303.858ZM177.495 303.858H173.385V301.77H177.495V303.858ZM169.275 303.858H165.165V301.77H169.275V303.858ZM161.055 303.858H156.945V301.77H161.055V303.858ZM152.835 303.858H148.724V301.77H152.835V303.858ZM144.614 303.858H140.504V301.77H144.614V303.858ZM136.394 303.858H132.284V301.77H136.394V303.858ZM128.174 303.858H124.064V301.77H128.174V303.858ZM119.954 303.858H115.843V301.77H119.954V303.858ZM111.733 303.858H107.623V301.77H111.733V303.858ZM103.513 303.858H99.403V301.77H103.513V303.858ZM95.2929 303.858H91.1827V301.77H95.2929V303.858ZM87.0726 303.858H82.9625V301.77H87.0726V303.858ZM78.8524 303.858H74.7422V301.77H78.8524V303.858ZM70.6321 303.858H66.522V301.77H70.6321V303.858ZM62.4119 303.858H58.3018V301.77H62.4119V303.858Z"
              fill="#A6A8AB"
            />
            <path
              d="M154.017 133.545H129.804C128.363 133.545 127.201 132.383 127.201 130.943C127.201 129.502 128.363 128.34 129.804 128.34H154.017C155.458 128.34 156.62 129.502 156.62 130.943C156.62 132.383 155.458 133.545 154.017 133.545Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 133.545H237.317C235.876 133.545 234.714 132.383 234.714 130.943C234.714 129.502 235.876 128.34 237.317 128.34H261.531C262.971 128.34 264.133 129.502 264.133 130.943C264.133 132.383 262.971 133.545 261.531 133.545Z"
              fill="#A6A8AB"
            />
            <path
              d="M117.039 133.545H56.2244C54.7836 133.545 53.6221 132.383 53.6221 130.943C53.6221 129.502 54.7836 128.34 56.2244 128.34H117.039C118.479 128.34 119.641 129.502 119.641 130.943C119.641 132.383 118.479 133.545 117.039 133.545Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 155.358H237.317C235.876 155.358 234.714 154.196 234.714 152.755C234.714 151.315 235.876 150.153 237.317 150.153H261.531C262.971 150.153 264.133 151.315 264.133 152.755C264.133 154.196 262.971 155.358 261.531 155.358Z"
              fill="#A6A8AB"
            />
            <path
              d="M167.981 155.358H96.9031C95.4623 155.358 94.3008 154.196 94.3008 152.755C94.3008 151.315 95.4623 150.153 96.9031 150.153H167.981C169.422 150.153 170.584 151.315 170.584 152.755C170.584 154.196 169.422 155.358 167.981 155.358Z"
              fill="#A6A8AB"
            />
            <path
              d="M84.7495 155.358H56.2244C54.7836 155.358 53.6221 154.196 53.6221 152.755C53.6221 151.315 54.7836 150.153 56.2244 150.153H84.7495C86.1903 150.153 87.3519 151.315 87.3519 152.755C87.3519 154.196 86.1903 155.358 84.7495 155.358Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 176.187H237.317C235.876 176.187 234.714 175.026 234.714 173.585C234.714 172.144 235.876 170.983 237.317 170.983H261.531C262.971 170.983 264.133 172.144 264.133 173.585C264.133 175.026 262.971 176.187 261.531 176.187Z"
              fill="#A6A8AB"
            />
            <path
              d="M150.78 176.187H56.2244C54.7836 176.187 53.6221 175.026 53.6221 173.585C53.6221 172.144 54.7836 170.983 56.2244 170.983H150.791C152.231 170.983 153.393 172.144 153.393 173.585C153.393 175.026 152.231 176.187 150.791 176.187H150.78Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 197.017H237.317C235.876 197.017 234.714 195.856 234.714 194.415C234.714 192.974 235.876 191.812 237.317 191.812H261.531C262.971 191.812 264.133 192.974 264.133 194.415C264.133 195.856 262.971 197.017 261.531 197.017Z"
              fill="#A6A8AB"
            />
            <path
              d="M101.458 197.017H56.2244C54.7836 197.017 53.6221 195.856 53.6221 194.415C53.6221 192.974 54.7836 191.812 56.2244 191.812H101.469C102.91 191.812 104.072 192.974 104.072 194.415C104.072 195.856 102.91 197.017 101.469 197.017H101.458Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 217.847H237.317C235.876 217.847 234.714 216.685 234.714 215.245C234.714 213.804 235.876 212.642 237.317 212.642H261.531C262.971 212.642 264.133 213.804 264.133 215.245C264.133 216.685 262.971 217.847 261.531 217.847Z"
              fill="#A6A8AB"
            />
            <path
              d="M126.119 217.847H56.2244C54.7836 217.847 53.6221 216.685 53.6221 215.245C53.6221 213.804 54.7836 212.642 56.2244 212.642H126.13C127.571 212.642 128.732 213.804 128.732 215.245C128.732 216.685 127.571 217.847 126.13 217.847H126.119Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 238.677H237.317C235.876 238.677 234.714 237.516 234.714 236.075C234.714 234.634 235.876 233.472 237.317 233.472H261.531C262.971 233.472 264.133 234.634 264.133 236.075C264.133 237.516 262.971 238.677 261.531 238.677Z"
              fill="#A6A8AB"
            />
            <path
              d="M117.038 238.677H81.2312C79.7905 238.677 78.6289 237.516 78.6289 236.075C78.6289 234.634 79.7905 233.472 81.2312 233.472H117.038C118.479 233.472 119.641 234.634 119.641 236.075C119.641 237.516 118.479 238.677 117.038 238.677Z"
              fill="#A6A8AB"
            />
            <path
              d="M68.2197 238.677H56.2244C54.7836 238.677 53.6221 237.516 53.6221 236.075C53.6221 234.634 54.7836 233.472 56.2244 233.472H68.2197C69.6605 233.472 70.822 234.634 70.822 236.075C70.822 237.516 69.6605 238.677 68.2197 238.677Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.531 259.507H237.317C235.876 259.507 234.714 258.345 234.714 256.905C234.714 255.464 235.876 254.302 237.317 254.302H261.531C262.971 254.302 264.133 255.464 264.133 256.905C264.133 258.345 262.971 259.507 261.531 259.507Z"
              fill="#A6A8AB"
            />
            <path
              d="M193.935 259.507H152.834C151.393 259.507 150.231 258.345 150.231 256.905C150.231 255.464 151.393 254.302 152.834 254.302H193.935C195.376 254.302 196.537 255.464 196.537 256.905C196.537 258.345 195.376 259.507 193.935 259.507Z"
              fill="#A6A8AB"
            />
            <path
              d="M134.339 259.507H56.2244C54.7836 259.507 53.6221 258.345 53.6221 256.905C53.6221 255.464 54.7836 254.302 56.2244 254.302H134.339C135.78 254.302 136.941 255.464 136.941 256.905C136.941 258.345 135.78 259.507 134.339 259.507Z"
              fill="#A6A8AB"
            />
            <path
              d="M261.529 292.064H236.009C234.568 292.064 233.406 290.902 233.406 289.461C233.406 288.021 234.568 286.859 236.009 286.859H261.529C262.97 286.859 264.132 288.021 264.132 289.461C264.132 290.902 262.97 292.064 261.529 292.064Z"
              fill="#DF1C71"
            />
            <path
              d="M224.661 292.064H211.672C210.231 292.064 209.07 290.902 209.07 289.461C209.07 288.021 210.231 286.859 211.672 286.859H224.661C226.102 286.859 227.264 288.021 227.264 289.461C227.264 290.902 226.102 292.064 224.661 292.064Z"
              fill="#DF1C71"
            />
            <path
              d="M134.339 292.064H56.2244C54.7836 292.064 53.6221 290.902 53.6221 289.461C53.6221 288.021 54.7836 286.859 56.2244 286.859H134.339C135.78 286.859 136.941 288.021 136.941 289.461C136.941 290.902 135.78 292.064 134.339 292.064Z"
              fill="#DF1C71"
            />
            <path
              d="M108.72 67.1899V60.9884L124.27 36.4912H129.617V45.0752H126.452L116.65 60.5882V60.8792H138.746V67.1899H108.72ZM126.598 73.7371V65.2985L126.743 62.5524V36.4912H134.127V73.7371H126.598ZM158.597 74.5555C155.469 74.5433 152.777 73.7734 150.522 72.2458C148.279 70.7181 146.551 68.5054 145.339 65.6077C144.138 62.71 143.544 59.2243 143.556 55.1505C143.556 51.0889 144.157 47.6274 145.357 44.766C146.569 41.9047 148.297 39.7284 150.54 38.2371C152.795 36.7337 155.481 35.982 158.597 35.982C161.713 35.982 164.392 36.7337 166.635 38.2371C168.89 39.7405 170.624 41.9229 171.836 44.7842C173.049 47.6334 173.649 51.0889 173.637 55.1505C173.637 59.2364 173.031 62.7282 171.818 65.6259C170.618 68.5236 168.896 70.7363 166.653 72.264C164.41 73.7916 161.725 74.5555 158.597 74.5555ZM158.597 68.0265C160.731 68.0265 162.434 66.9535 163.707 64.8075C164.98 62.6615 165.611 59.4425 165.598 55.1505C165.598 52.3255 165.307 49.9734 164.726 48.0941C164.156 46.2149 163.343 44.8024 162.289 43.8567C161.246 42.911 160.015 42.4382 158.597 42.4382C156.475 42.4382 154.778 43.499 153.504 45.6208C152.231 47.7425 151.589 50.9191 151.577 55.1505C151.577 58.0118 151.862 60.4003 152.431 62.316C153.013 64.2195 153.832 65.6501 154.887 66.608C155.941 67.5537 157.178 68.0265 158.597 68.0265ZM178.688 67.1899V60.9884L194.238 36.4912H199.584V45.0752H196.42L186.617 60.5882V60.8792H208.714V67.1899H178.688ZM196.565 73.7371V65.2985L196.711 62.5524V36.4912H204.095V73.7371H196.565Z"
              fill="#333333"
            />
          </g>
          <defs>
            <clipPath id="clip0_1144_22967">
              <rect
                width="317.998"
                height="401.407"
                fill="white"
                transform="translate(0.000976562)"
              />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </LoggedInLayout>
  </Restrict>
);
