/* eslint max-len: 0 */
import Box from '@mui/material/Box';
import React, { FC, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from './OptinCreatorCodeResult.service';
import { OptinCreatorCodeResultProps } from './types';

export const OptinCreatorCodeResult: FC<OptinCreatorCodeResultProps> = ({
  heightDesktop,
  heightMobile,
  isFullHeightMobile,
  isFullWidthMobile,
  widthMobile,
  isFullHeight,
  isFullWidth,
  widthDesktop,
  withUTMS,
  promotionKey,
  clientKey,
}) => {
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const { t } = useTranslation();

  return (
    <Box
      px={3}
      py={1}
      border="1px solid grey"
      overflow="auto"
      bgcolor="#e9e8e8"
      position="relative"
    >
      <Button
        sx={{ position: 'absolute', top: 12, right: 12 }}
        size="small"
        variant="outlined"
        onClick={() => {
          if (copyToClipboard(ref.current?.innerText || '')) {
            setCopyDialogOpen(true);
          }
        }}
      >
        {t('common.copy')}
      </Button>
      <Dialog maxWidth="sm" fullWidth open={copyDialogOpen}>
        <DialogTitle
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span>{t('common.copy')}</span>

          <IconButton
            size="small"
            onClick={() => setCopyDialogOpen(false)}
            data-testid="close-icon"
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body2">{t('optinGen.copyInfo')}</Typography>
        </DialogContent>

        <DialogActions>
          <Box mb={1} mr={3}>
            <Button
              variant="contained"
              onClick={() => setCopyDialogOpen(false)}
            >
              {t('common.ok')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <pre>
        <code ref={ref}>
          {`<style>
  .responsive-iframe {
    width: ${isFullWidth ? '100%' : `${widthDesktop}px`};
    height: ${isFullHeight ? '100%' : `${heightDesktop}px`};
    border: none;
    background: transparent;
  }
  
  @media (max-width:768px) {
    .responsive-iframe {
      min-width: ${isFullWidthMobile ? '100%' : `${widthMobile}px`};
      min-height: ${isFullHeightMobile ? '100%' : `${heightMobile}px`};
      overflow-x: hidden;
    }
  }
</style>
`}

          {withUTMS
            ? `
<iframe id="Ourcart-optin" class="responsive-iframe" allow="web-share" src=""></iframe>

<!-- We recomend to put script into HEAD of the page -->
<script src="https://app.cm.ourcart.com/static/js/campaign-attribution.bundle.js"></script>

<script>
  ourcartOptinInit('Ourcart-optin', '${clientKey}', '${promotionKey}');
</script> `
            : `
<iframe class="responsive-iframe" allow="web-share" src="https://app.cm.ourcart.com/${clientKey}/opt-in?d=${promotionKey}"></iframe>`}
        </code>
      </pre>
    </Box>
  );
};
