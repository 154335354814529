import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExportFormValues } from '@components/MainChart/ExpotModal/types';
import {
  ClientPromotionsFilters,
  ExportFileType,
  ExportFormat,
  ExportUrlReq,
} from '@typings/ExportUrlReq';
import { fetchExportUrl } from '@services/clients.service';
import { Store } from '@typings/Store';
import { downloadFile } from '@services/download.service';
import { setExportFormValues } from '@redux/ui/ui.slice';

let allowUnload = false;
export const setAllowUnload = (input: boolean) => {
  allowUnload = input;
};
export const getAllowUnload = () => allowUnload;

export const exportQSAction = createAsyncThunk(
  'ui/exportQS',
  async (
    initialInput: ExportFormValues | null,
    { getState, rejectWithValue, dispatch }
  ) => {
    if (initialInput) {
      dispatch(setExportFormValues(initialInput));
    }
    const {
      login: { clients, exportPermissions },
      ui: { exportFormValues },
    } = getState() as Store;

    const input = initialInput || (exportFormValues as ExportFormValues);

    const waitForFileToBeGenerated = async (
      url: string,
      startTimestamp: number
    ) => {
      if (Date.now() - startTimestamp > 300000) {
        return 'TIMEOUT';
      }

      let status: number;
      try {
        const response = await fetch(url);
        status = response.status;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        status = e.status;
      }

      switch (status) {
        case 200:
          return 'OK';
        case 404:
          // eslint-disable-next-line no-return-await
          return await new Promise((resolve) => {
            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              resolve(waitForFileToBeGenerated(url, startTimestamp));
            }, 5000);
          });
        default:
          return 'ERROR_DURING_GEN';
      }
    };

    const getClientPromotionsFilter = (
      inputForm: ExportFormValues
    ): ClientPromotionsFilters[] => {
      if (inputForm.promotionKey?.length) {
        return Object.keys(clients).reduce((output, clientKey) => {
          const promotionKeys = clients[clientKey].promotions
            .filter(({ promotionKey }) =>
              inputForm.promotionKey?.some(
                (promo) => promo.promotionKey === promotionKey
              )
            )
            .map(({ promotionKey }) => promotionKey);

          if (promotionKeys.length) {
            output.push({
              clientKey,
              promotionKeys,
            });
          }

          return output;
        }, [] as ClientPromotionsFilters[]);
      }

      if (inputForm.clientKey?.length) {
        return inputForm.clientKey
          .filter(
            (key) =>
              exportPermissions.find(({ clientKey }) => clientKey === key)
                ?.exportsAllowed
          )
          .map((clientKey) => ({ clientKey }));
      }

      return Object.keys(clients)
        .filter(
          (key) =>
            exportPermissions.find(({ clientKey }) => clientKey === key)
              ?.exportsAllowed
        )
        .map((clientKey) => ({ clientKey }));
    };

    const filter: ExportUrlReq = {
      startDate: input.fromDate || null,
      endDate: input.toDate || null,
      clientPromotions: getClientPromotionsFilter(input),
      fileTypes: [
        input.surveyData && ExportFileType.SURVEY,
        input.optinData && ExportFileType.OPT_IN,
        input.registrationRedeemData &&
          ExportFileType.USER_REGISTRATION_REDEMPTION,
        input.receiptsData && ExportFileType.ITEM,
        input.kipData && ExportFileType.KPI,
      ].filter(Boolean) as ExportFileType[],
      piiIncluded: Boolean(input.includePii),
      format: input.fileFormat as ExportFormat,
    };

    const fileUrl = await fetchExportUrl(filter);

    if (!fileUrl) {
      return rejectWithValue('NO_DATA_PRESENT');
    }

    const genStatus = await waitForFileToBeGenerated(fileUrl, Date.now());

    if (genStatus !== 'OK') {
      return rejectWithValue(genStatus);
    }
    setAllowUnload(false);
    downloadFile(
      fileUrl,
      `${new Date()}_dataExport.${input.kipData ? 'csv' : 'zip'}`
    );

    return fileUrl;
  }
);
