import React, { FC, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { Store } from '@typings/Store';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { useClients } from '@components/MainChart/MainChartClientFilters/useClients.hooks';
import { AnalyticsEvents } from '@config/analytics';
import { PromotionDataRow } from '@components/MainChart/PromorionDataRow';
import { PromotionSpecBtn } from '@components/MainChart/PromotionSpecBtn';
import ReactGA4 from 'react-ga4';
import dayjs from 'dayjs';
import { ChartTooltip } from './ChartTooltip/ChartTooltip';
import { ExportBtn } from './ExportBtn';
import { MainChartClientFilters } from './MainChartClientFilters';
import { MainChartDateFilters } from './MainChartDateFilters';
import {
  getDateFilters,
  getQuicksightAnswersString,
  getQuicksightFilter,
  getQuicksightUTMData,
} from './MainChart.service';
import { ChartFiltersDateType, ChartFiltersValue } from './types';
import { MainChartPromotionFilters } from './MainChartPromotionFilters';
import { SelectPromotionField } from './SelectPromotinField/SelectPromotinField';
import { useGraphUrl } from './MainChart.hooks';

const tooltips = [
  'chart.optInValueTooltip',
  'chart.registrationsValueTooltip',
  'chart.validatedPurchasedValueTooltip',
  'chart.redeemedValueTooltip',
  'chart.rebatesValueTooltip',
  'chart.marketingConsentsValueTooltip',
  'chart.optInToMarketingConsentValueTooltip',
  'chart.optInToValidatedConversionValueTooltip',
];

export const MainChart: FC = () => {
  const [openPromotionCounter, setOpenPromotionCounter] = useState(0);
  const { t } = useTranslation();
  const { url, isLoading } = useGraphUrl();
  const { hashedEmail } = useSelector(({ login }: Store) => login);
  const [hideTooltip, setHideTooltip] = useState(false);
  const [printInitiated, setPrintInitiated] = useState(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [iFrameWidth, setIFrameWidth] = useState(0);
  const { options } = useClients(false);

  const { clients } = useSelector(({ login }: Store) => login);

  const [filters, setFilters] = useState<ChartFiltersValue>(
    getDateFilters(ChartFiltersDateType.Last30days, {
      promotionKey: [],
      clientKey: [],
      dateType: ChartFiltersDateType.Last30days,
    })
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      ReactGA4.gtag('event', 'Filter action', {
        user: hashedEmail,
        filterTime: dayjs().format('YYYY-MM-DD HH:mm:00'),
        brands:
          (filters?.clientKey &&
            filters?.clientKey
              .flatMap((key: string) =>
                options
                  .filter((client) => client.clientKey.includes(key))
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  .map((client: any) => client.clientId)
              )
              .join(',')) ||
          'All',
        promotions:
          (filters?.promotionKey &&
            filters?.promotionKey
              .map(({ promotionId }) => promotionId)
              .join(',')) ||
          'All',
        from: filters?.fromDate || 'All',
        to: filters?.toDate || 'All',
      });
    }, 7000);

    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const { sendGoogle } = AnalyticsEvents(
      'User Activity',
      `User logged in`,
      hashedEmail
    );
    sendGoogle();
  }, [hashedEmail]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [embedDashboard, setEmbedDashboard] = useState<any>(null);
  useEffect(() => {
    if (embedDashboard?.iframe.contentWindow) {
      const { sendGoogle } = AnalyticsEvents(
        'User Activity',
        `Filter applied`,
        hashedEmail
      );
      sendGoogle();

      embedDashboard.setParameters({
        ...getQuicksightUTMData(
          clients,
          filters.clientKey,
          filters.promotionKey
        ),
        ...getQuicksightFilter(filters),
        ...getQuicksightAnswersString(filters.promotionKey),
      });
    }
  }, [embedDashboard, filters, clients, hashedEmail]);

  useEffect(() => {
    if (ref && url) {
      // eslint-disable-next-line no-console
      const dashboard = QuickSightEmbedding.embedDashboard({
        url,
        container: ref,
        parameters: {
          ...getQuicksightUTMData(
            clients,
            filters.clientKey,
            filters.promotionKey
          ),
          ...getQuicksightFilter(filters),
          ...getQuicksightAnswersString(filters.promotionKey),
        },
        scrolling: 'no',
        iframeResizeOnSheetChange: false,
        locale: 'en-US',
        footerPaddingEnabled: true,
        sheetTabsDisabled: false,
        printEnabled: true,
        undoRedoDisabled: false,
        resetDisabled: false,
        defaultEmbeddingVisualType: 'TABLE',
        height: 'AutoFit',
        loadingHeight: '700px',
      });

      dashboard.on('SHOW_MODAL_EVENT', () => {
        const { sendGoogle } = AnalyticsEvents(
          'User Activity',
          `Exported CVS data - QuickSight export`,
          hashedEmail
        );
        sendGoogle();

        setHideTooltip(true);
        window.scrollTo({
          top: 0,
        });
      });

      setEmbedDashboard(dashboard);
    }

    const onResize = () => {
      setIFrameWidth(ref?.clientWidth || 0);
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, url]);

  const onBrandChange = useMemo(
    () => (newValues: string[]) => {
      setFilters((filtersTmp) => ({
        ...filtersTmp,
        clientKey: newValues,
        promotionKey: [],
      }));
    },
    [setFilters]
  );

  return (
    <>
      <Box
        mt={4}
        px={4}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          width={{ xs: '100%', lg: 'auto' }}
          display="flex"
          mr={{ xs: 0, sm: 2 }}
          flexDirection={{ xs: 'column', lg: 'row' }}
        >
          <Box pr={2} width={{ xs: '100%', lg: 'auto' }}>
            <MainChartDateFilters
              dateSeparator="."
              dateType={filters.dateType}
              fromDate={filters.fromDate}
              toDate={filters.toDate}
              onChange={(newValues) =>
                setFilters({
                  ...newValues,
                  clientKey: filters.clientKey,
                  promotionKey: filters.promotionKey,
                })
              }
            />
          </Box>
          <Box pr={2} mt={{ xs: 2, lg: 0 }} width={{ xs: '100%', lg: 'auto' }}>
            <MainChartClientFilters
              value={filters.clientKey}
              label={t('common.allBrands')}
              onChange={onBrandChange}
            />
          </Box>
          <Box mt={{ xs: 2, lg: 0 }} width={{ xs: '100%', lg: 'auto' }}>
            <MainChartPromotionFilters
              allLabel="Promotions"
              openCounter={openPromotionCounter}
              formValues={filters}
              value={filters.promotionKey}
              onChange={(newValues) => {
                setFilters({
                  ...filters,
                  promotionKey: newValues,
                });
              }}
            />
          </Box>
        </Box>

        <Box mt={{ xs: 2, md: 0 }}>
          <ExportBtn
            initialData={filters}
            onPrint={() => {
              embedDashboard?.initiatePrint();
              setPrintInitiated(true);
            }}
          />
        </Box>
      </Box>

      <Box
        display="flex"
        mt={3}
        px={4}
        alignItems="center"
        justifyContent="space-between"
      >
        <PromotionDataRow promotions={filters?.promotionKey} />
        <PromotionSpecBtn
          promotions={filters?.promotionKey}
          clientKeys={filters?.clientKey}
        />
      </Box>

      <Box
        flexGrow={1}
        p={4}
        display={isLoading ? 'flex' : undefined}
        alignItems={isLoading ? 'center' : undefined}
        justifyContent={isLoading ? 'center' : undefined}
      >
        {isLoading && <CircularProgress data-testid="chart-loader" />}

        {url && (
          <Box
            mx="auto"
            position="relative"
            width="100%"
            minWidth="768px"
            overflow="hidden"
            style={{
              aspectRatio: printInitiated ? '0.465' : '0.465',
            }}
          >
            <Box
              ref={setRef}
              width="100%"
              marginTop={printInitiated ? undefined : '-41px'}
              marginBottom="-30px"
            />

            {!hideTooltip &&
              !printInitiated &&
              tooltips.map((text, index) => (
                <ChartTooltip
                  key={text}
                  text={t(text)}
                  index={index}
                  iFrameWidth={iFrameWidth}
                />
              ))}

            {!filters.promotionKey?.length && (
              <SelectPromotionField
                text="Select a specific offer in the filter above to view the relevant survey results."
                bottom={0}
                heightRatio="1.4"
                onScrollTop={() =>
                  setOpenPromotionCounter(openPromotionCounter + 1)
                }
              />
            )}
          </Box>
        )}
      </Box>
    </>
  );
};
