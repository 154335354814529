import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { RetoolContainmentTest } from '../RetoolContainmentTest';
import { ViewProps } from '../types';

export const Email: FC<ViewProps> = ({ onDetected }) => {
  const [searchParams] = useSearchParams();

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          Header line 1
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('emailHeaderLine1')}
            width={475}
            height={14}
            lineHeight={14}
            fontWeight={400}
            fontSize={14}
          />
        </Grid>
        <Grid item xs={3}>
          Header line 2
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('emailHeaderLine2')}
            width={475}
            height={50}
            lineHeight={50}
            fontWeight={900}
            fontSize={36}
          />
        </Grid>
        <Grid item xs={3}>
          Intro text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('emailIntroText')}
            width={475}
            height={44}
            lineHeight={22}
            fontWeight={400}
            fontSize={18}
          />
        </Grid>
        <Grid item xs={3}>
          Promise text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('emailPromiseText')}
            width={475}
            height={24}
            lineHeight={24}
            fontWeight={400}
            fontSize={24}
          />
        </Grid>
        <Grid item xs={3}>
          Description text
        </Grid>
        <Grid item xs={9}>
          <RetoolContainmentTest
            onDetected={onDetected}
            text={searchParams.get('emailDescriptionText')}
            width={475}
            height={80}
            lineHeight={20}
            fontWeight={400}
            fontSize={15}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
