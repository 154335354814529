export const URL_REGEXP =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const UTM = [
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
];

export const DEFAULT_VALUES: Record<string, string> = {
  url: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  utm_content: '',
};

export const getUTMUrl = (values: Record<string, string>) => {
  if (
    values.url &&
    (values.utm_source ||
      values.utm_medium ||
      values.utm_campaign ||
      values.utm_term ||
      values.utm_content)
  ) {
    const utmString = UTM.filter((utm) => values[utm])
      .map((utm) => `${utm}=${values[utm]}`)
      .join('&');

    const urlParts = values.url.split('?');
    if (urlParts.length === 1) {
      return `${values.url}?${utmString}`;
    }
    return `${urlParts[0]}?${utmString}&${urlParts[1]}`;
  }
  return '-';
};
